import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { currencyFormat } from '@helper/Tools';
import { getUrl } from '@helper/ApiAction';
import { storeWalletBalance } from '@actions';
import useNotificationLoading from '../../helper/useNotificationLoading';

import 'react-awesome-slider/dist/styles.css';

import Slider from "react-slick";

//MUI
// import { makeStyles } from '@material-ui/core/styles';
import { Link, Drawer, Container } from '@material-ui/core';

//ICON
import { FiArrowDown, FiArrowUp, FiChevronRight } from "react-icons/fi";
import { BiTransfer } from "react-icons/bi";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import TransactionItem from '@components/TransactionItem';
import TransactionDialog from '@components/TransactionDialog';

export default function Wallet() {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));

    // get transaction detail base on currentPage
    const [transactionList, setTransactionList] = useState({});
    // const [commissionSummary, setCommissionSummary] = useState([]);
    const [currentPage, setCurrentPages] = useState(0);
    const [state, setState] = useState({bottom: false});
    const [type, setType] = useState(wallet);
    const [currentWalletItem, setCurrentWalletItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);

    let sliderRef = useRef(null);

    // --------- APIs ----------
    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            // getUrl('commissions/summary').then(response => {
            //     if (response.status && isMountedRef.current) {
            //         setCommissionSummary(response.data);
            //     }
            // }).catch(error => {
            //     addAlert(JSON.stringify(error.message));
            // })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    useEffect(() => {
        let selectedWallet = _.findKey(walletBalance, { code: wallet });
        if(selectedWallet){
            setCurrentPages(selectedWallet);
            setCurrentWalletItem(walletBalance[selectedWallet]);
            sliderRef.slickGoTo(selectedWallet);
        }
    }, [walletBalance, wallet])
    
    useEffect(() => {
        if (currentWalletItem) {
            setLoading(true);
            getUrl('transaction/list', { 'code': currentWalletItem.code }).then(response => {
                setTransactionList(response.data);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [currentWalletItem]);

    // ------------- Function -------------
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleAfterChange = (current) => {
        setCurrentPages(current);
        let detail = walletBalance[current];
        if(detail){
            history.replace(`/wallet/${detail.code}`);
            setCurrentWalletItem(detail);
        }
    };

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }
    
    return (

        <div>
            <TitleBar backgroundColor="bg-theme" back displayUsername currencyButton />

            <div className='bg-theme p-b-200 header-radius'></div>

            <Container>
                <div style={{ marginTop: -200 }}>
                    <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        initialSlide={currentPage}
                        responsive={[
                            { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                            { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                            { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                        ]}
                        afterChange={handleAfterChange}
                    >
                        {_.map(walletBalance, (walletItem, idx) => {
                            let walletName = _.split(walletItem.name, '|');
                            return (
                                <div key={idx} className='p-lr-15 p-tb-20'>
                                    <div>
                                        <p className='txt-center clwhite txt-upper fs-13 p-b-20'>{i18n.language === 'cn' ? walletName[1] : walletName[1]} {t('title.wallet')}</p>
                                    </div>
                                    <div className='glass3 p-t-20 p-lr-20 p-b-80' style={{ borderRadius: 21, width: 350, maxWidth: '85%', margin: '0 auto' }}>
                                        <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} />
                                    </div>
                                    <div className='gradient-blue bor15 pos-relative shadow-menu' style={{ marginTop: -50 }}>
                                        <WalletComponentMenu key={walletItem.id} data={walletItem} walletList={walletList} />
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </Container>
        
            <Container>
                <div className='p-b-10 p-t-30'>
                    <div className='flex-sb-m'>
                        <p className='fs-title cl-theme'>{t('title.transaction')}</p>
                        <div className='fs-content cl-theme flex-m pointer translateX' onClick={toggleDrawer('bottom', true)}>
                            <p className='p-lr-5'>{t('item.viewAll')}</p>
                            <FiChevronRight />
                        </div>
                    </div>

                    {
                        _.size(transactionList) > 0
                            ? _.map(transactionList, transactionItem => (
                                <TransactionItem key={transactionItem.transaction.id} data={transactionItem.transaction} openDialog={openDialog} />
                            ))
                            : 
                            <div className='p-t-50'>
                                <div className='empty-img'>
                                    <img src="/images/empty/data.png" className='w-full' alt="empty" />
                                </div>
                                <p className='txt-center fs-content'>{t('wallet.noWallet')}</p>
                            </div>
                    }
                </div>
            </Container>

            <TransactionDialog dialogOpen={dialogOpen} closeDialog={closeDialog} dialogInfo={dialogInfo} />
            
            <Drawer 
                anchor="bottom" 
                open={state.bottom} 
                onClose={toggleDrawer('bottom', false)}
                PaperProps={{
                    style: {
                        borderRadius: '30px 30px 0px 0px',
                        boxShadow: 'none',
                    },
                }}
            >
                <div
                    role="presentation"
                    onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 cl-label'>{t('wallet.type')}</p>
                    
                    <Link underline='none' to={`/transaction/${type}?list=all`} component={RouterLink}>
                        <div className='pointer p-tb-15 w-full flex-sb-m'>
                            <p>{t('title.transactionHistory')}</p>
                            <FiChevronRight />
                        </div>
                    </Link>
                    <div className='divider-white p-tb-2' />

                    {
                        walletList?.[currentPage]?.allow_withdraw &&
                        <>
                            <Link underline='none' to={`/transaction/${type}?list=withdraw`} component={RouterLink}>
                                <div className='pointer p-tb-15 w-full flex-sb-m'>
                                    <p>{t('title.withdrawalHistory')}</p>
                                    <FiChevronRight />
                                </div>
                            </Link>
                            <div className='divider-white p-tb-2' />
                        </>
                    }

                    {
                        walletList?.[currentPage]?.allow_convert &&
                        <>
                            <Link underline='none' to={`/transaction/${type}?list=convert`} component={RouterLink}>
                                <div className='pointer p-tb-15 w-full flex-sb-m'>
                                    <p>{t('title.convertHistory')}</p>
                                    <FiChevronRight />
                                </div>
                            </Link>
                            <div className='divider-white p-tb-2' />
                        </>
                    }

                    {
                        walletList?.[currentPage]?.allow_transfer &&
                        <Link underline='none' to={`/transaction/${type}?list=transfer`} component={RouterLink}>
                            <div className='pointer p-tb-15 w-full flex-sb-m'>
                                <p>{t('title.transferHistory')}</p>
                                <FiChevronRight />
                            </div>
                        </Link>
                    }
                </div>
            </Drawer>
        </div>
    );
}

const WalletComponent = (props) => {
    const { data, walletList } = props;

    const { t } = useTranslation();

    const balance = currencyFormat((data.balance * 100) / 100);

    return (
        <>
            <div className='flex-sb-m w-full'>
                <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                    <p className='fs-remark txt-upper'>{t('wallet.totalBalance')}</p>
                    <p className='fs-digit'>{balance}</p>
                </div>
                <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
                    <p className='fs-30 txt-upper'><b>{data.wallet_name}</b></p>
                </div>
            </div>
        </>
    )
}

const WalletComponentMenu = (props) => {
    const { data, walletList } = props;
    const { t } = useTranslation();

    const existWallet = _.find(walletList, { id: data.id });
    let allowTransfer = false;
    let allowConvert = false;
    let allowWithdraw = false;
    if (existWallet) {
        allowTransfer = existWallet.allow_transfer;
        allowConvert = existWallet.allow_convert;
        allowWithdraw = existWallet.allow_withdraw;
    }

    return (
        <>
            <div className='w-full flex-sb-m p-lr-20 p-tb-10 clwhite'>
                <div style={{width:'50%'}}>
                    {allowTransfer ?
                        <Link underline='none' to={`/transfer/${data.code}`} component={RouterLink} className='flex-c-m flex-col'>
                            <FiArrowUp className='fs-icon clwhite'/>
                            <p className='txt-upper clwhite fs-remark txt-center p-t-5'>{t('wallet.transfer')}</p>
                        </Link>
                        :
                        <div className='flex-c-m flex-col' style={{opacity:0.3}}>
                            <FiArrowUp className='fs-icon clwhite'/>
                            <p className='txt-upper fs-remark txt-center p-t-5'>{t('wallet.transfer')}</p>
                        </div>
                    }
                </div>
                {/* <div style={{width:'25%'}}>
               {allowConvert ?
                    <Link underline='none' to={`/convert/${data.code}`} component={RouterLink} className='flex-c-m flex-col'>
                        <BiTransfer className='fs-icon clwhite'/>
                        <p className='txt-upper fs-remark clwhite txt-center p-t-5'>{t('wallet.convert')}</p>
                    </Link>
                    :
                    <div className='flex-c-m flex-col' style={{opacity:0.3}}>
                        <BiTransfer className='fs-icon'/>
                        <p className='txt-upper fs-remark txt-center p-t-5'>{t('wallet.convert')}</p>
                    </div>
                }
               </div> */}
                <div style={{width:'50%'}}>
                {allowWithdraw ?
                    <Link underline='none' to={`/withdrawal/${data.code}`} component={RouterLink} className='flex-c-m flex-col'>
                        <FiArrowDown className='fs-icon clwhite'/>
                        <p className='txt-upper fs-remark clwhite txt-center p-t-5'>{t('wallet.withdrawal')}</p>
                    </Link>
                    :
                    <div className='flex-c-m flex-col' style={{opacity:0.3}}>
                        <FiArrowDown className='fs-icon'/>
                        <p className='txt-upper fs-remark txt-center p-t-5'>{t('wallet.withdrawal')}</p>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}
