import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Box, Typography, Container, Grid, TextField, InputAdornment, IconButton, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Pagination, PaginationItem } from '@material-ui/lab';
import { getUrl } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

import TitleBar from '@layouts/TitleBar';
import ProductItemCard from '@components/ProductItemCard';

import { BiSolidCategory } from "react-icons/bi";
import { RiGalleryView2, RiHeartAddFill } from "react-icons/ri";
import { Search } from '@material-ui/icons';

export default function Categories() {
    const styles = useStyles();
    const theme = useTheme();

    const categoryRefs = useRef([]);
    const isMountedRef = useRef(null);
    let query = useQuery();
    let key = useQuery();
    const pageNumber = query.get("page");
    const keyword = key.get("s");
    const history = useHistory();
    
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { currency, currencyDisplay } = useSelector(state => ({
        ...state.general,
        currency: state?.general?.currency ? state.general.currency : process.env.REACT_APP_DEFAULT_CURRENCY,
    }));

    const [categoryProducts, setCategoryProducts] = useState([]);
    const [categories, setCategories] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState({ keyword: keyword || '' });
    const [currKeyword, setCurrKeyword] = useState(keyword || null);
    const [page, setPage] = useState(1);
    const [isSearch, setIsSearch] = useState(keyword !== '' ? true : false);
    const [total, setTotal] = useState(1);

    // ----------------- APIs ------------------------
    useEffect(() => {
        getUrl('categories?level=1').then(categoryList => {
            setCategories(categoryList.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (id === 'all' || Number(id)) {
            if (keyword) {
                getUrl(`search/${keyword}`, { cat: id }).then(response => {
                    if (response) {
                        let { current_page, last_page } = response.data.meta;
                        setCategoryProducts(response.data.data);
                        setTotal(last_page);
                        setPage(current_page ? current_page : 1);
                        setLoading(false);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                    setLoading(false);
                });
            } else {
                getUrl(`products?cat=${id}&page=${pageNumber ? pageNumber : 1}`).then(response => {
                    if (response) {
                        if (isMountedRef.current) {
                            let { current_page, last_page } = response.data.meta;
                            setCategoryProducts(_.concat(categoryProducts, response.data.data));
                            setTotal(last_page);
                            setPage(current_page ? current_page : 1);
                            setLoading(false);
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                    setLoading(false);
                });
            }
        } else {
            setLoading(false);
            history.replace({ pathname: "/category/all" });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, page, keyword]);

    // ------------- Functions ----------------
    // const handlePaginationChange = (event, value) => {
    //     // setPage(value);
    //     setLoading(true);
    //     getUrl(`products?cat=${id}&page=${value}`).then(response => {
    //         if (response) {
    //             let { current_page, last_page } = response.data.meta;
    //             setCategoryProducts(_.concat(categoryProducts, response.data.data));
    //             setTotal(last_page);
    //             setPage(current_page ? current_page : 1);
    //             setLoading(false);
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     });
    // };

    const handleCategoryChange = (value) => {
        if (categoryRefs.current[value]) {
            categoryRefs.current[value].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
        setCategoryProducts([]);
        setSearchKeyword({ ...searchKeyword, keyword: '' });
        setCurrKeyword(null);
        setIsSearch(false);
        history.replace(`/category/${value}`);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword({ ...searchKeyword, keyword: event.target.value });
    }

    const handleSearch = (event) => {
        setLoading(true);
        let apiData = {
            cat: id,
        };
        if (searchKeyword?.keyword) {
            getUrl(`search/${searchKeyword?.keyword}`, apiData).then(response => {
                if (response) {
                    let { last_page } = response.data.meta;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
                setIsSearch(true);
            });

            setCurrKeyword(event.target.value);
            history.replace(`/category/${id}?s=${event.target.value}`);
        } else {
            getUrl(`products?cat=${id}&page=1`).then(response => {
                if (response) {
                    let { current_page, last_page } = response.data.meta;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                    setPage(current_page ? current_page : 1);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
                setIsSearch(false);
            });

            setCurrKeyword(event.target.value);
            history.replace(`/category/${id}?s=${event.target.value}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event);
        }
    };

    const loadProducts = () => {
        setPage(page + 1);
    }

    // ---------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay,(currency_detail)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
    });

    if (categoryRefs && categoryRefs.current[id]) {
        categoryRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }

    return (
        <div>
            <TitleBar backgroundColor="bg-header" back displayCart currencyButton />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                <div className='cl-text txt-center p-b-24'>
                    <p className='fs-header'>{t('title.categories')}</p>
                    <p className='fs-subheader'>{t('item.categorySubtitle')}</p>
                </div>
                    <div className='p-b-15'>
                       <div className='w-full box-textfield'>
                       <TextField 
                            value={searchKeyword?.keyword || ''}
                            fullWidth
                            placeholder={t('home.search')}
                            className={styles.textFieldRoot}
                            onChange={handleSearchChange}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <IconButton style={{ padding: 0, width: 30, height: 30, aspectRatio: 1/1 }} onClick={handleSearch}>
                                        <InputAdornment position="end" className='w-full'>
                                            <Search />
                                        </InputAdornment>
                                    </IconButton>
                                ),
                            }}
                        />
                       </div>
                    </div>

                    <div className='flex-l-m p-tb-24 scroll-container' style={{ overflowX: 'scroll' }}>
                        <div ref={(e) => (categoryRefs.current['all'] = e)} onClick={() => handleCategoryChange('all')} className={`${id === 'all' ? styles.categorySelectedButtonDesign : styles.categoryButtonDesign} flex-c-m translateY p-tb-4 p-lr-10 m-r-10`}>
                            {/* <BiSolidCategory className='fs-16' /> */}
                            <p className='p-tb-4 p-lr-6 fs-14 txt-upper' style={{ fontWeight: 'bold' }}>{t('item.all')}</p>
                        </div>

                        {
                            _.size(categories) > 0 &&
                            _.map(categories, (detail, idx) => (
                                <div ref={(e) => (categoryRefs.current[detail?.id] = e)} key={idx} onClick={() => handleCategoryChange(detail?.id)} className={`${detail?.id === parseInt(id) ? styles.categorySelectedButtonDesign : styles.categoryButtonDesign} flex-c-m p-tb-4 p-lr-10 m-r-10 translateY`}>
                                    {/* <RiHeartAddFill className='fs-16' /> */}
                                    <p className='p-tb-4 p-lr-6 fs-14 txt-upper' style={{ fontWeight: 'bold' }}>{i18n.language === 'cn' ? detail?.name_cn : detail?.name_en}</p>
                                </div>
                            ))
                        }
                    </div>

                    {
                        isSearch &&
                        <div>
                            <p className='fs-14'>{t('home.searchResult')}{currKeyword}</p>
                        </div>
                    }
                    {
                        categoryProducts.length > 0
                            ? <div>
                                <Grid container spacing={1}>
                                    {
                                        _.map(categoryProducts, (product) => {
                                            return (
                                                <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                                    <ProductItemCard product={product} currency={currency} currencyCode={currency_code} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                                {/* <Grid container spacing={1} justify="center" style={{ marginTop: 15 }} >
                                    <Pagination
                                        count={total}
                                        page={page}
                                        onChange={handlePaginationChange}
                                        color="primary"
                                        renderItem={(item) => (
                                            <PaginationItem
                                                component={RouterLink}
                                                to={`/category/${id}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Grid> */}

                                <div className='p-t-30'>
                                    {
                                        page < total ?
                                            <Button onClick={loadProducts} fullWidth>{t('transaction.loadMore')}</Button>
                                            : <Typography variant='body2' className='txt-center'>{t('transaction.endOfList')}</Typography>
                                    }
                                </div>
                            </div>
                            : <div className='txt-center'>
                                <img src='/images/empty/product.png' alt="noimage" style={{ width: 120 }} />
                                <p className='txt-center fs-title cl-text'>{t('general.noProduct')}</p>
                            </div>
                    }
                </Container>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
        display: 'flex',
        alignItems: 'flex-end',
    },
    Tooltip:{
        fontSize:21
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
    categoryButtonDesign: {
        cursor: 'pointer',
        padding:'4px 15px',
        background: '#E9E9E9', 
        // boxShadow: '3px 3px 4px 0px #00000012', 
        borderRadius: 38, 
        width: '100%',
        color: '#a9a9a9',
    },
    categorySelectedButtonDesign: {
        color: '#F6F7F9',
        padding:'4px 15px',
        background: '#0D0D0D',
        // boxShadow: '3px 3px 4px 0px #00000012', 
        borderRadius: 38, 
        width: '100%',
    },
    textFieldRoot: {
        '& .MuiFilledInput-root': {
            padding: '6px 12px',
            background: '#F6F7F9',
        },
        '& .MuiFilledInput-input': {
            padding: '6px 12px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px',
        }
    },
}));
