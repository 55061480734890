import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { getUrl } from '@helper/ApiAction';
// import { currencyFormat } from '@helper/Tools';
// import { storeWalletBalance } from '@actions';
import useNotificationLoading from '../../helper/useNotificationLoading';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import Slider from "react-slick";
import NumberFormat from 'react-number-format';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

//ICON


//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


export default function Commission() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const { addAlert } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));
    const styles = useStyles();
    const { commission } = useParams();
    let history = useHistory();
   
    const isMountedRef = useRef(null);

    const [commissionSummary, setCommissionSummary] = useState([]);
    const [currentpage, setCurrentPages] = useState(-1);

    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('commissions/summary').then(response => {
                if (response.status && isMountedRef.current) {
                    setCommissionSummary(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    //get transaction detail base on currentpage
    // const[type , setType]= useState();
    const[transactionList , setTransactionList]= useState({});
    const[initialSlide , setInitialSlide]= useState(0);

    const setUrl = (commissionID) =>{
        history.replace(`/commission/${commissionID}`)
    }

    const getTransaction = (commissionID) =>{
        getUrl(`commissions/details/${commissionID}`).then(response => {
            if (response.status) {
                setTransactionList(response.data)
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    useEffect(() => {
        let commissionID = atob(commission);
        getTransaction(commissionID);
        let index = _.findIndex(commissionSummary.bonus,{id:parseInt(commissionID)});
        setInitialSlide(index);
    }, [commission, commissionSummary, i18n.language])
    
    useEffect(() => {
        if(currentpage != -1){
            let selectedBonus = commissionSummary.bonus[currentpage];
            setUrl(btoa(selectedBonus.id))
            getTransaction(selectedBonus.id)
        }
    }, [currentpage])
    
    return (

        <div>{/* className={styles.root} style={{ padding: '0 0 80px', }} */}
            <TitleBar backgroundColor="bg-theme" back displayUsername currencyButton />{/* hexBkgColor="#222" hexTextColor="#fff" */}
            <div className='bg-theme p-b-20 header-radius'>
                {
                    initialSlide != -1 &&
                    <Container>
                        <Slider
                            infinite={true}
                            speed={500}
                            //How many slides to show in one frame
                            slidesToShow={1}
                            //How many slides to scroll at once
                            slidesToScroll={1}
                            autoplay={false}
                            pauseOnHover={true}
                            //Index of first slide
                            initialSlide={initialSlide}
                            bullets={true}
                            afterChange={(current) => setCurrentPages(current)}
                        >
                            {_.map(commissionSummary.bonus, bonusItem => {
                                let bonusName = _.split(bonusItem.name, '|');
                                bonusName = i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0];
                                let summary = _.find(commissionSummary.summary, { bonus_id: bonusItem.id });
                                let amount = "0.00";
                                if (summary) amount = Number(summary.amount).toFixed(2);
                                return (
                                    <div className='p-lr-15 p-tb-20' key={bonusItem.id}>
                                        <div>
                                            <p className='txt-center clwhite txt-upper fs-13 p-b-20'>{bonusName}</p>
                                        </div>
                                        <div className='glass3 p-all-20 flex-c-m' style={{ borderRadius: 21, width: 350, height: 130, maxWidth: '85%', margin: '0 auto' }}>
                                            <div className='flex-sb-m w-full'>
                                                <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                                                    <p className='fs-12 txt-upper'>{bonusName}</p>
                                                    <p className='fs-24'><b>{amount}</b></p>
                                                </div>
                                                <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003', width: 50 }}>
                                                    <img src='/images/bonus/matching.png' alt='bonus' />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '80%', margin: '20px auto 0', height: 4, borderRadius: '100%', filter: 'blur(4px)', background: '#ffffff2b' }}></div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </Container>
                }
            </div>
       

            <Container>
                <div className='p-lr-20 p-t-40'>

                    {_.size(transactionList) > 0 ?
                        <>
                            <div className='flex-sb-m w-full'>
                                <p className='fs-title cl-theme'>
                                    {t('title.transaction')}
                                </p>
                            </div>
                            {_.map(transactionList, (transactionList, index) => {
                                let operator = "+";
                                return (
                                    <div key={index}>
                                        <div className='flex-sb-m w-full p-tb-15'>
                                            <div className='fs-content' style={{ width: 'calc(100% - 130px)' }}>
                                                <p className='txt_truncate'><b>{transactionList.bonus_info.name_display}</b></p>
                                                <p className='txt_truncate'>{transactionList.user_info.username}</p>
                                            </div>
                                            <div className='txt-right' style={{ whiteSpace: 'nowrap' }}>
                                                <p className='fs-title' style={{ color: operator != "+" ? theme.palette.primary.main : "green", whiteSpace: 'nowrap' }}><b>{operator}{<NumberFormat value={parseFloat(transactionList.amount).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} />}</b></p>
                                                <p className='fs-remark'>{transactionList.updated_at ? transactionList.updated_at : '-'}</p>
                                            </div>
                                        </div>
                                        <div className='divider-white p-tb-2' />
                                    </div>
                                )
                            })}
                        </>
                        :
                        <div>
                            <div className='empty-img'>
                                <img src="/images/empty/data.png" className='w-full' alt="empty" />
                            </div>
                            <p className='txt-center fs-content'>{t('emptyStatus.bonusHistory')}</p>
                        </div>
                    }
                </div>
            </Container>
        </div>
    );
}




const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    bonusCard:{
        position: 'absolute',
        top: 0,
        padding: 25, 
        height:'100%',
        [theme.breakpoints.up('sm')]: {
            top:'-1vw'
          },
          [theme.breakpoints.down('sm')]: {
            top:'-1vw'
          },
          [theme.breakpoints.down('xs')]: {
            top:'-1vw'
          },
      
    },
    walletButtonStyle: {
        border: '1px solid #696969',
        borderRadius: 48,
        padding: '5px 10px',
        color: theme.palette.silver.text,
        fontSize: 14,
        minWidth: 75,
        textAlign: 'center'
    }
}));
