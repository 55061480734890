import axios from 'axios';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { authFail, authSuccess, changeLanguage as reduxChangeLanguage } from '@actions';
// import { getUrl, logoutUser } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Button, Drawer, IconButton, InputAdornment , TextField, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// ICON
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FiX } from 'react-icons/fi';

// COMPONENTS & LAYOUTS
// import WebpImg from '@layouts/WebpImg';
// import TitleBar from '@layouts/TitleBar';

export default function Login({drawerOpen, setDrawerOpen}) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const styles = useStyles();
    const query = useQuery();
    const source = query.get('src');
    // const { name } = useSelector(state => state.user);

    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [userLogin, setUserLogin] = useState(false);

    useEffect(() => {
        if (accessToken) {
            // if (source) {
            //     if (userLogin) {
            //         // user login, redirect to third party page
            //         setLoading(true);
            //         getUrl(`/login-to/${source}`).then(response => {
            //             setLoading(false);
            //             if (response.status === 1 && response.url) {
            //                 window.location.href = response.url;
            //             } else {
            //                 addAlert(t('general.loginRedirectError'));
            //             }
            //         }).catch(error => {
            //             setLoading(false);
            //             addAlert(JSON.stringify(error.message));
            //         });
            //     } else {
            //         // user already login, ask continue as XXX
            //         setContinueDialogOpen(true);
            //     }
            // } else {
                // let previousUrl = "";
                // if (document.referrer) previousUrl = new URL(document.referrer);
                // if (shareLinkProductId) {
                //     history.replace({ pathname: `product/${shareLinkProductId}` });
                // } else if (!previousUrl || (previousUrl && window.location.host != previousUrl['host'])) {
                //     history.replace({ pathname: "/" });
                // } else {
                //     history.goBack();
                // }
            // }
        }
    }, [accessToken, history, shareLinkProductId, source]);

    const loginUser = () => {
        setLoading(true);
        setUserLogin(true);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        if (state?.username && state?.password){
            axios.defaults.headers.common['Accept'] = 'application/json';
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/oauth/token`, loginState)
                .then((response) => {
                    // setLoading(false);
                    setLoading(false);
                    if (response.status === 200) {
                        // dispatch(authSuccess(response.data));
                        // history.goBack();
                        const decoded_jwt = jwt_decode(response.data.access_token);
                        const userRole = decoded_jwt ? decoded_jwt.role : [];
                        if (_.size(_.intersection(userRole, ['member']))) {
                            dispatch(authSuccess(response.data));
                        } else {
                            addAlert(t('general.incorrectUsernamePassword'));
                        }
                        setDrawerOpen(false);
                    }
                }).catch((error) => {
                    console.log("error", error);
                    setLoading(false);
                    if (error.response && error.response.status === 400) {
                        addAlert(t('general.incorrectUsernamePassword'));
                        // console.log('The username or password is incorrect');
                    } else if (error.response && error.response.status === 401) {
                        let errorResponse = JSON.parse(error.request.response);
                        addAlert(errorResponse.message);
                    } else {
                        addAlert(JSON.stringify(error.message));
                        // console.log('Please try again later or contact Technical Support if problem persist.');
                    }
                    dispatch(authFail());
                    // setLoading(false);            
                });
        }
        else
        {
            setLoading(false);
            addAlert(t('general.incorrectUsernamePassword'));
        }
        
    }

    // const [continueDialogOpen, setContinueDialogOpen] = useState(false);
    // const dontContinueAs = () => {
    //     setContinueDialogOpen(false);
    //     logoutUser();
    // }
    // const continueAs = () => {
    //     setContinueDialogOpen(false);
    //     setLoading(true);
    //     getUrl(`/login-to/${source}`).then(response => {
    //         setLoading(false);
    //         if (response.status === 1 && response.url) {
    //             window.location.href = response.url;
    //         } else {
    //             addAlert(t('general.loginRedirectError'));
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert(JSON.stringify(error.message));
    //     });
    // }



    return (
        <Drawer
            anchor="bottom"
            open={drawerOpen}
            id="login"
            onClose={() => setDrawerOpen(false)}
        >
            <div className='pos-relative p-all-20' style={{ width: 'auto', height: '100vh', overflowY: 'auto', background:'linear-gradient(169deg, #F26868 1.24%, #CA2120 100%)' }}>
                <div className='flex-r-m w-full clwhite'>
                    <FiX className='fs-icon pointer' onClick={() => setDrawerOpen(false)} />
                </div>
                <div style={{width:'50%', margin:'0 auto'}}>
                    <img src='/images/auth/login.png' alt={t('title.login')} className='w-full' />
                </div>
                <div className='txt-center clwhite lh-12 p-b-40'>
                    <p className='fs-content txt-title'>{t('title.welcome')},</p>
                    <p className='fs-header txt-title'>{t('general.accessAccount')}</p>
                </div>
                <div>
                    <div className='flex-col w-full p-b-20' style={{ gap: 24 }}>
                        <div>
                            <TextField
                                label={t('register.username')}
                                value={state.username}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
                                }}
                                style={{boxShadow: '0 6px 4px 0px rgb(83 24 24 / 24%)', borderRadius:11}}
                                variant="filled"
                                autoComplete="email"
                                color='primary'
                                fullWidth={true}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                            />
                            <TextField
                                style={{ marginTop: 10, boxShadow: '0 6px 4px 0px rgb(83 24 24 / 24%)', borderRadius:11 }}
                                label={t('register.password')}
                                type={state.showPassword ? 'text' : 'password'}
                                value={state.password}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                variant="filled"
                                color='primary'
                                fullWidth={true}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                            />
                            {/* <div className='p-tb-10'>
                                <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                                    <p className='fs-content txt-right clwhite'>
                                        {t('title.forgotPassword')}
                                    </p>
                                </Link>
                            </div> */}
                            <div className='p-tb-10'>
                                <Button variant="contained" fullWidth color="secondary" onClick={() => { loginUser(); }}>
                                    {t('button.login')}
                                </Button>
                            </div>
                            <div className='w-full flex-c-m p-tb-10' style={{opacity:0.5}}>
                                <div className='bgwhite' style={{ width: 50, height: 2 }} />
                                <p className='p-lr-20 txt-upper fs-16 clwhite'>{t('product.or')}</p>
                                <div className='bgwhite' style={{ width: 50, height: 2 }} />
                            </div>
                            <div className='p-tb-10'>
                                <a href={`${process.env.REACT_APP_BC_URL}/login-redirect?C=${process.env.REACT_APP_LOCAL_URL}/login-callback`} style={{color:`#787878`, textDecoration:'none'}}>
                                    <div className='w-full btn-white flex-c-m pointer translateY' >
                                        <div style={{ width: 35, lineHeight: 0 }}>
                                            <img src="/images/logo/logo.png" alt="logo" />
                                        </div>
                                        <p className='p-l-10 fs-button'>{t('Sign in As BonusChains')}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

const useStyles = makeStyles(theme => ({
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
}));