import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { ArtTrackOutlined, AspectRatioOutlined } from '@material-ui/icons';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { TextField, Button, Typography, Box, Container, Drawer, Dialog, DialogActions, DialogContentText, Snackbar, Card, CardContent, DialogContent, DialogTitle, Select, FormHelperText, MenuItem } from '@material-ui/core';

//ICON
import { CgTrash } from "react-icons/cg";
import { FiPlus, FiX } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import AddressDetails from "@components/AddressDetails";

const INITIAL_ADDRESS_STATE = { name: '', mobile_code: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "" };

export default function ProfileAddress() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [drawerAddress, setDrawerAddress] = useState(false);
    const [addresses, setAddress] = useState([]);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [mobileCodeList, setMobileCodeList] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();

    useEffect(() => {

        if (_.size(countries) > 0) {
            if (addressFormState?.country !== '') {
                let codeList = {};
                let countryCode = addressFormState?.mobile_code;
                let code = _.find(countries, { 'code': addressFormState?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (addressFormState?.mobile_code !== '' ? addressFormState?.mobile_code : '');
                    }
                }

                setMobileCodeList(codeList);
                setAddressFormState(prevState => ({ ...prevState, mobile_code: countryCode }));
            } else if (addressFormState?.country === '' && addressFormState?.mobile_code !== '') {
                let tempArr = _.clone(countries);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, addressFormState?.mobile_code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(tempArr[0]['tel_code']);
                    setAddressFormState(prevState => ({ ...prevState, mobile_code: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [countries, addressFormState?.country, addressFormState?.mobile_code]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    const getUserAddress = () => {
        getUrl('user/address').then(addressList => {
            if (isMountedRef.current) {
                setAddress(addressList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;

        getUserAddress();

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if (isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        if (addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });

            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'icFront') {
            if (icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if (name === 'icBack') {
            if (icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        setAddressFormState({ ...addressFormState, [name]: value });
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };


    const deleteUserAddress = () => {
        if (state.deleteAddressId) {
            deleteUrl(`user/address/${state.deleteAddressId}`).then(result => {
                getUserAddress();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            setState({ ...state, deleteAddressId: null, deleteAddressShow: false });
        }
    }

    const openAddAddress = () => {
        setDrawerAddress(true);
        setErrorAddressState({});
        setState({ ...state, mode: 'add', modalShow: true });
        setAddressFormState(INITIAL_ADDRESS_STATE);
    };

    const closeModal = () => {
        setState({ ...state, modalShow: false, mode: null, editAddressId: null, });
        setAddressFormState(INITIAL_ADDRESS_STATE);
        setMobileCodeList({});
    };

    const openEditAddress = addressId => {
        setDrawerAddress(true);
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': addressId });
        if (editedAddress) {
            const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack , mobile_country_code } = editedAddress;
            let newAddressFormState = {
                name: name || "",
                mobile_code: mobile_country_code || "",
                mobile: mobile || "",
                email: email || "",
                identity_no: identity_no || "",
                address: address || "",
                address2: address2 || "",
                city,
                state,
                zip,
                country,
                icFront,
                icBack,
                default: editedAddress?.default,
            };
            if (_.size(icFront) > 0) {
                newAddressFormState['icFront'] = icFront.file_name;
            }
            if (_.size(icBack) > 0) {
                newAddressFormState['icBack'] = icBack.file_name;
            }
            setAddressFormState(newAddressFormState);
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: addressId }));
        }
    }

    const setDefaultAddress = addressId => {
        const editedAddress = _.find(addresses, { 'id': addressId });

        const { name, mobile, address, address2, city, state, zip, country, email, identity_no , mobile_country_code } = editedAddress;
        const defaultAddress = {
            name, mobile, address, address2, city, state, zip, country, default: 1, email, identity_no , mobile_code: mobile_country_code
        }
        putUrl(`user/address/${addressId}`, defaultAddress).then(result => {
            if (result.error) {
                let errMsg = "";
                _.map(result.error, (errorItem) => {
                    errMsg += errorItem + " ";
                })
                addAlert(errMsg);
            } else {
                addAlert(result.message, 'success');
                getUserAddress();
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const submitAddress = () => {
        // const { country, city, address2, zip, address } = addressFormState;
        // if(!country || !addressFormState.state || !city) {
        //     if(cities) {
        //         if(!address2 || !zip || !address) {
        //             setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        //         }
        //     }
        //     setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        // } else {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setMobileCodeList({});
                    setState(state => ({ ...state, modalShow: false, snackbarShow: true, error: false, message: result.message }));
                    getUserAddress();
                    setDrawerAddress(false);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState).then(result => {
                if (result.error) {
                    let errorFieldState = {};
                    _.map(result.error, (errorItem, errorIndex) => {
                        errorFieldState[errorIndex] = errorItem;
                    })
                    setErrorAddressState(errorAddressState => (errorFieldState));
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }));
                } else {
                    setAddressFormState(INITIAL_ADDRESS_STATE);
                    setMobileCodeList({});
                    setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null, snackbarShow: true, error: false, message: result.message }));
                    getUserAddress();
                    setDrawerAddress(false);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        // }
    }


    return (
        <div >
            <TitleBar back btnRegister displayUsername backgroundColor="bg-header" />
            <div className='bg-header p-b-50 clwhite'>
                {/* <div className='flex-c-m flex-col w-full'>
                    <div className='p-b-30' style={{ background: '#fff3', width:'calc(100% - 60px)', borderRadius: '30px 30px 0 0', backdropFilter: 'blur(12px)' }}></div>
                    <div className='p-b-35' style={{ background: '#fff3', width:'calc(100% - 30px)', borderRadius: '30px 30px 0 0', backdropFilter: 'blur(12px)', marginTop: -20 }}></div>
                </div> */}
            </div>
           
            <Container className='bg-base subheader-radius'>
                <div className='p-b-24'>
                <div className='subtitle-box flex-sb-m'>
                    <div className='txt-left clblack'>
                        <p className='fs-header'>
                            {t('profile.addresses')}
                        </p>
                        <p className='fs-subheader'>
                            {t('profile.addressSubtitle')}
                        </p>
                    </div>

                    <div className='bgblack translateX flex-c-m pointer' onClick={openAddAddress}  style={{ width: '32px', height: '32px', minWidth: '32px', borderRadius: 5 }}>
                        <FiPlus className='clwhite fs-icon' />
                    </div>
                </div>
                </div>
                <div>
                    {
                        _.size(addresses) > 0 ?
                        <>
                        {addresses && _.map(addresses, addressItem => (
                            <div className='glass2 shadow-glass2 bor15 p-all-20 m-b-20' key={addressItem.id}>
                                <div>
                                    <div className='flex-sb-m w-full shadow-txt'>
                                        <div className='flex-m clblack' style={{ width: '85%' }}>
                                            <p className='txt_truncate fs-title' style={{ maxWidth: '62%', }}>{addressItem.name || '-'}</p>
                                            <p className='fs-content p-l-15'>{addressItem.mobile || '-'}</p>
                                        </div>
                                        <div className='cl-theme pointer fs-title'
                                        onClick={() => openEditAddress(addressItem.id)}
                                        // onClick={() => setDrawerAddress(true)}
                                        >{t('button.edit')}</div>
                                    </div>
                                    <div className='cl-text fs-content shadow-txt'>
                                        <p>{addressItem.email || '-'}</p>
                                        <p>{`${addressItem.address},`} {`${addressItem.address2_display ? addressItem.address2_display + ', ' : ''} ${addressItem.city_display}, ${addressItem.state_display},`} {`${addressItem.zip}, ${addressItem.country_display}`}</p>
                                    </div>
                                </div>
                                <div className='flex-sb-m p-t-15'>
                                    {
                                        addressItem.default ?
                                            <div style={{ borderRadius: 48, padding: '5px 9px', height: 'unset', width: 100, boxShadow: 'inset 3px 4px 3px 0 #00000029', backgroundColor: '#dcdcdc' }}
                                                className="fs-content" >
                                                <p className='fs-content cl-text txt-center'>{t('button.default')}</p>
                                            </div> :
                                            <div className='btn-theme' onClick={() => { setDefaultAddress(addressItem.id) }}>{t('button.setDefault')}</div>
                                    }
                                    <div className='pointer' onClick={() => setState({ ...state, deleteAddressId: addressItem.id, deleteAddressShow: true })} ><CgTrash className='fs-icon cl-theme' /></div>
                                </div>
                        </div>
                    ))}
                        </>
                    :
                            <div>
                                {/* <div className='w-full flex-r-m p-r-50' style={{ transform: 'rotate(352deg)', transformOrigin: 'bottom' }}>
                                    <div style={{ width: 50 }}>
                                        <img src='/images/empty/arrow.png' alt='arrow' className='w-full' />
                                    </div>
                                </div> */}
                                <div className='empty-img'>
                                    <img src="/images/empty/address.png" className='w-full' alt="empty" />
                                </div>
                                <div style={{ width: 350, margin: '0 auto', maxWidth: '85%' }}>
                                    <p className='fs-content cl-label txt-center'>
                                        {t('profile.addressEmpty')}
                                    </p>
                                </div>
                            </div>
                    }
               
                </div>
                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
                <Dialog
                    open={state.deleteAddressShow}
                    fullWidth
                    onClose={() => setState({ ...state, deleteAddressShow: false })}
                >
                    <div className='p-all-20'>
                        <div className='txt-center p-b-24'>
                            <p className='fs-header clblack'>{t('address.removeTitle')}</p>
                            <p>{t('address.removeContent')}</p>
                        </div>
                        <DialogActions>
                            <div className='w-full flex-sb-m'>
                                <div className='btn-outlined-black bor10 translateY' style={{ width: '48%' }} onClick={() => setState({ ...state, deleteAddressShow: false, deleteAddressId: null })} color="default">
                                    <p>{t('button.cancel')}</p>
                                </div>
                                <div className='btn-theme bor10 translateY' style={{ width: '48%' }} onClick={deleteUserAddress}>
                                    <p>{t('button.confirm')}</p>
                                </div>
                            </div>
                        </DialogActions>
                    </div>
                </Dialog>
                <AddressDetails addressOpen={drawerAddress} setAddressOpen={setDrawerAddress} handleAddressChange={handleAddressChange} addressMobileCode={mobileCodeList} addressFormState={addressFormState} errorAddressState={errorAddressState} state={state} countries={countries} cities={cities} district={district} addressGroup={addressGroup} addressState={addressState} shouldUploadIC={shouldUploadIC} icFrontRef={icFrontRef} icBackRef={icBackRef} submitAddress={submitAddress} />
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 15
    },
    formControl: {
        padding: 5
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none',
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    textFieldTitle: {
        color: theme.palette.gray.dtext,
        fontSize: 14,
        textTransform: 'uppercase',
        paddingBottom: 5,
        paddingLeft: 10
    }
}));
