import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';
import { postUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';
import { useQuery } from '@helper/Tools';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, Divider } from '@material-ui/core';

import WebpImg from '@layouts/WebpImg';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { process.env.REACT_APP_APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordReset() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let { token } = useParams();
    const query = useQuery();
    const email = query.get("email");

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({ password: '', passwordConfirm: '' });
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    /* useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]); */

    const resetPassword = () => {
        setLoading(true);
        const apiData = {
            password: state.password,
            password_confirmation: state.passwordConfirm,
            token,
            email
        }
        // console.log("apiData", apiData);
        postUrl(`reset_password`, apiData).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 1) {
                    addAlert(message, 'success', '', '');
                    setCompleted(true);
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n" + value[0];
                        })
                        setInputErrors(error);
                    }
                    addAlert(message, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
                <div className={classes.paper}>
                    <Link color="inherit" href="/login">
                        <center><WebpImg src="/images/logo/logo.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center>
                    </Link>
                    <Typography component="h1" variant="h5" style={{ marginTop: '20px' }}>
                        {t('general.resetYourPassword')}
                    </Typography>
                    <Divider variant="middle" style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }} />
                    {!completed ?
                        <>
                            <Typography variant="body2">{t('general.enterNewPassword')} - {email}.</Typography>
                            <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); resetPassword() }}>
                                <TextField
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="New Password"
                                    name="password"
                                    value={state.password}
                                    onChange={({ target }) => setState({ ...state, password: target.value })}
                                    autoFocus
                                    type="password"
                                    error={inputErrors.password ? true : false}
                                    helperText={inputErrors.password ? inputErrors.password : ''}
                                />
                                <TextField
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="passwordConfirm"
                                    label="Re-enter New Password"
                                    name="passwordConfirm"
                                    value={state.passwordConfirm}
                                    onChange={({ target }) => setState({ ...state, passwordConfirm: target.value })}                                    
                                    type="password"
                                    error={inputErrors.password_confirmation ? true : false}
                                    helperText={inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                />
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    style={{width: 194, padding:'8px 9px', maxWidth: '80%', borderRadius: 48, }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >{t('button.changePassword')}</Button>
                                </div>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        </>
                        :
                        <>
                        <Typography variant="body2">{t('general.resetPasswordSuccess')}</Typography>
                        <Link underline='none' to={`/login`} component={RouterLink}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('button.proceedToLogin')}
                            </Button>
                        </Link>
                        </>
                    }
                </div>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));