import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Link, Box} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TitleBar from '@layouts/TitleBar';


export default function TermOfUse() {
    const { t } = useTranslation();

    return (
        <Box>
            <TitleBar backgroundColor="bg-header" back displayUsername />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
            <p className='txt-center fs-header clblack p-b-24'>{t('support.termOfUse')}</p>
                <div className='lh-17'>
                    <Container>
                        <p>These terms of use ("Bonus Store Terms of Use") shall govern the access and usage by any user of any website, mobile site and/or applications ("Website") by us as wells as all products and/or services made available by us on or through the Website. The Website is owned and operated by Mose Net System Solution Sdn Bhd and its affiliates (individually and collectively, "Bonus Store " "we", "us" or "our"). References to the Bonus Store Terms of Use shall include all subsequent variation and amendments made thereto.</p>
                        <p>To the extent permitted by law and unless provided otherwise herein, Bonus Store specifically excludes any and all liability for any loss, damage, claims, costs and expenses no matter how arising from the use of any the Website.</p>
                        <p>By you accessing and using the Website, you acknowledge and agree that you have read and understood:</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>a.	the following Terms of Use;</li>
                            <li>b.	the <Link underline='none' to="/privacyAgreement" component={RouterLink}> Bonus Store Privacy Policy </Link> ;</li>
                            <li>c.	the <Link underline='none' to="/refundAndReturnAgreement" component={RouterLink}> Refund and Return Policy </Link>; and</li>
                            <li>d.	the <Link underline='none' to="/merchantServiceAgreement" component={RouterLink}> Merchant Services Agreement </Link>;</li>
                        </ul>
                        <p>(collectively, "Terms"). <br />
                            By accessing and using the Website, you agree irrevocably to be bound by all of those terms and conditions governing the use of the Website.<br />
                            You shall not access or use the Website if you do not accept the Terms.
                        </p>

                        <p style={{ color: '#333' }}><b>Definitions</b></p>
                        <ul style={{ listStyle: 'none' }}>
                            <li>A.	<b style={{ color: '#333' }}>Parties</b><br />
                                "You" and "your" refer to you, as a user of the Website. A "user" is someone who accesses, browses, crawls, scrapes, or in any way uses the Website. "Bonus Store ", "we," "us," and "our" refer to Bonus Store.shopping.</li>

                            <br /><li>B.	<b style={{ color: '#333' }}>Content</b><br />
                                "Content" means text, images, photos, audio, video, location data, and all other forms of data or communication. "Your Content" means Content that you submit or transmit to, through, or in connection with the Website, such as ratings, reviews, compliments, invitations, check-ins, messages, and information that you publicly display or displayed in your account profile. "User Content" means Content that users submit or transmit to, through, or in connection with the Website. "Bonus Store.shopping Content" means Content that we create and make available in connection with the Website. "Third Party Content" means Content that originates from parties' other than Bonus Store.shopping or its users, which is made available in connection with the Website. "Website Content" means all of the Content that is made available in connection with the Website, including Your Content, User Content, Third Party Content, and Bonus Store.shopping Content.</li>
                        </ul>

                        <p><b style={{ color: '#333' }}>Changes to the Terms of Service</b><br />
                            Bonus Store reserves the right to modify and change any of the Terms including this Terms of Use at any time. The most current version of these Terms will be published on the Website. Any such modification will be effective upon our posting of the revised Terms or Terms of Use on the Website. You understand and agree that your continued access to or use of the Website after the effective date of modifications to the Terms or Terms of Use will constitute your agreement and acceptance of the modified and changed Terms or Terms of Use, and your use of the Website will be bound by such modified and changed Term or Terms of Use.</p>
                        <p style={{ color: '#333', marginBottom: '0' }}><b>Using the Website</b></p>
                        <ul style={{ listStyle: 'none', marginTop: '0' }}>
                            <li>A.	<b style={{ color: '#333' }}>Permission to Use the Website</b><br />
                                We grant you permission to use the Website subject to your acceptance and agreement to the Terms. For the avoidance of doubt, any usage or reliance by you of the Website and any products and services made available on the Website shall strictly be at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, or otherwise inappropriate.</li>

                            <br /><li>B.	<b style={{ color: '#333' }}>Website Availability</b><br />
                                The Website may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.
                                <ul style={{ listStyle: 'none' }}>
                                    <li style={{ marginTop: '20px' }}>I.	<b style={{ color: '#333' }}>User Account</b><br />
                                        <p style={{ marginTop: '0' }}>You must create an account ("Bonus Store Account") and provide certain information about yourself in order to use some of the features that are offered through the Website. You are responsible for maintaining the confidentiality of your accounts and its password. You are also responsible for all activities that occur in connection with your Bonus Store account. You agree to notify us immediately of any unauthorized use of your account. Bonus Store will not be liable for any loss or damage that you may incur as a result of someone else using your account, either with or without your knowledge. In addition, you may be held liable for any losses or damages incurred by Bonus Store or other third party due to someone else using your account. You may not use anyone else's account at any time.</p>
                                        <p>We reserve the right to close your account at any time for any or no reason. In creating it, we ask that you provide complete and accurate information about yourself to bolster your credibility as a contributor to the Website. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any submissions of information to the Website. We may, but shall not be obligated to, remove or edit any submissions of information by you. If you use a pseudonym, please take note that others may still be able to identify you if, for example, you include identifying information in your reviews, use the same account information on other websites, or allow other websites to share information about you with Bonus Store . Please read our <Link>Privacy Policy</Link> for more information.</p>
                                    </li>

                                    <li>II.	<b style={{ color: '#333' }}>Communications from Bonus Store and other Users</b><br />
                                        <p style={{ marginTop: '0' }}>By creating an account, you agree to receive certain communications in connection with the Website. You may receive our weekly email newsletter. You should also refer to Bonus Store Privacy Policy on the collection, transfer, manipulation, storage, disclosure and other uses of your personal information. The Bonus Store Privacy Policy is made available on the Website at all times. The Bonus Store Terms of Use incorporate the entirety of the Bonus Store Privacy Policy. You consent to any personal information we may obtain about you (either via the Website, by email, telephone, or any other means) being collected, stored and otherwise processed in accordance with the terms of the Bonus Store Privacy Policy.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <p style={{ color: '#333', marginBottom: '0' }}><b>Website Content</b></p>
                        <ul style={{ listStyle: 'none', marginTop: '0' }}>
                            <li>A.	<b style={{ color: '#333' }}>Responsibility for Contents</b><br />
                                <p style={{ marginTop: '0' }}>Contents provided on the Website are solely for informational purposes. Product or services representations expressed on the Website are those of the vendor and are not made by us. Submissions or opinions expressed on the Website are those of the individual posting such content and may not reflect our opinions.</p>
                                <p>You alone are responsible for Your Content, and once published, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone's reliance on its quality, accuracy, or reliability, or any disclosure by you of information in Your Content that makes you personally identifiable. You represent that you own, or have the necessary permissions to use and authorize the use of Your Content as described herein. Bonus Store is not under any circumstances responsible for Your Content. You may not imply that Your Content is in any way sponsored or endorsed by Bonus Store .</p>
                                <p>You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation.</p>
                                <p>We reserve the right, but shall not be obliged to:</p>
                                <ul>
                                    <li>monitor, screen or otherwise control any activity, content or material on the Website or the Website Contents. We may in our sole and absolute discretion, investigate any violation of these Terms of Use contained herein and may take any action it deems appropriate;</li>
                                    <li>prevent or restrict access of any an authorised user to the Website;</li>
                                    <li>report any activity it suspects to be in violation of any applicable law, statute or regulation to the appropriate authorities and to co-operate with such authorities; and/or</li>
                                    <li>to request any information and data from you in connection with your use of the Website at any time and to exercise our right under this paragraph if you refuse to divulge such information and/or data or if you provide or if we have reasonable grounds to suspect that you have provided inaccurate, misleading or fraudulent information and/or data.</li>
                                </ul>
                            </li>

                            <li style={{ marginTop: '10px' }}>B.	<b style={{ color: '#333' }}>Our Right to Use Your Content</b><br />
                                <p style={{ marginTop: '0' }}>We may use Your Content in a number of different ways, including publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms ("Other Media"). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose.</p>
                                <p>Please note that you also irrevocably grant the users of the Website and any Other Media the right to access Your Content in connection with their use of the Website and any Other Media. Finally, you irrevocably waive, and cause to be waived, against Bonus Store and its users any claims and assertions of any rights, moral rights or attribution with respect to Your Content. By "use" we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.</p>
                            </li>

                            <li>C.	<b style={{ color: '#333' }}>Ownership</b><br />
                                <p style={{ marginTop: '0' }}>As between you and Bonus Store , you own Your Content. We own the Bonus Store.shopping Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation, including, but not limited to, our compilation of User Content and other Website Content, computer code, products, software, aggregate user review ratings, and all other elements and components of the Website excluding Your Content, User Content and Third Party Content. We or where applicable, our affiliates or third party intellectual property owners also own the copyrights, trademarks, service marks, trade names, and other intellectual and proprietary rights throughout the world ("IP Rights") associated with the Bonus Store.shopping Content and the Website, which are protected by copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights and laws. As such, you may not modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way exploit any of the Bonus Store.shopping Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Website and the Bonus Store.shopping Content are retained by us.</p>
                            </li>

                            <li>D.	<b style={{ color: '#333' }}>Advertising</b><br />
                                <p style={{ marginTop: '0' }}>Bonus Store and its licensees may publicly display advertisements and other information adjacent to or included with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.</p>
                            </li>

                            <li>E.	<b style={{ color: '#333' }}>Other</b><br />
                                <p style={{ marginTop: '0' }}>User Content (including any that may have been created by users employed or contracted by Bonus Store.shopping) does not necessarily reflect the opinion of Bonus Store . We reserve the right to remove, screen, edit, or reinstate User Content from time to time at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our Content Guidelines. We have no obligation to retain or provide you with copies of Your Content, nor do we guarantee any confidentiality with respect to Your Content.</p>
                            </li>
                        </ul>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Permitted Uses</b><br />
                            You agree not to use the Website in any manner that could damage, disable, overburden, or impair any server, or the network(s) connected to any Bonus Store server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any portion of the Website, other accounts, computer systems, or networks connected to any Bonus Store server, through hacking, password or data mining, or any other means. You may not obtain or attempt to obtain any materials or information, through any means, which are not intentionally made available to you on the Website. You will not link to any part of the Website if such activity is illegal, will cause damage to, or will otherwise harm Bonus Store or any other party. Moreover, Bonus Store reserves the right in its sole discretion to disable or otherwise terminate your use of the Website, or any links you make to the Website, or request you to do the same. Bonus Store reserves all of its rights in the Website not expressly granted to you by these Terms of Service.
                        </p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Your Conduct on the Website</b><br />
                            The Website is a private property and fully owned by Mose Net System Solution Sdn Bhd. All interactions on this Website must be lawful and must comply with the Terms and these Terms of Use. To the extent should your conduct (as judged by us in our sole discretion) restricts or inhibits any other user from using or enjoying any part of this Website, we may limit your privileges on the Website and seek other remedies.</p>

                        <p><b style={{ color: '#333' }}>PLEASE DO NOT ENGAGE IN ANY OF THE FOLLOWING ACTIVITIES,</b> as they are prohibited on the Website and constitute express violations of the Terms of Use:</p>
                        <ul>
                            <li>Submitting any inaccurate information, committing fraud or falsifying information in connection with your Bonus Store Account or to create multiple Bonus Store Accounts;</li>
                            <li>Attempting to access, or actually accessing, data not intended for you, such as logging into a server or an account which you are not authorized to access;</li>
                            <li>Attempting to scan, or test the security or configuration of the Website or to breach security or authentication measures;</li>
                            <li>Tampering or interfering with the proper functioning of any part, page or area of the Website or any functions or services provided by Bonus Store ;</li>
                            <li>Attempting to interfere with service to any user in any manner, including, without limitation, by means of submitting a virus to our Website, or attempts at overloading, "flooding", "spamming", "mail bombing" or "crashing" the Website;</li>
                            <li>Using frames, framing techniques or framing technology to enclose any content included on the Website without our express written permission;</li>
                            <li>Using any Website content in any meta tags or any other "hidden text" techniques or technologies without our express written permission;</li>
                            <li>Using the Website or any of its contents to advertise or solicit, for any other commercial, political or religious purpose, or to compete, directly or indirectly with Bonus Store ;</li>
                            <li>Reselling or repurposing your access to the Website or any purchases made through the Website;</li>
                            <li>Using the Website or any of its resources to solicit the website end users ("Website End Users"), retailer(s), restaurant(s) or other business partners of Bonus Store to become users or partners of other online or offline services directly or indirectly competitive or potentially competitive with Bonus Store , including without limitation, aggregating current or previously offered deals;</li>
                            <li>Collecting content from the Website, including but not limited to current or previously offered deals, and featuring such content to consumers in any manner that diverts traffic from the Website without our express written permission;</li>
                            <li>Collecting the personal information, statements, data or content of any Website End Users, retailers, or restaurants;</li>
                            <li>Using any information from any Website End User(s), retailer(s), restaurant(s) or office(s) from the Website for any commercial purpose, including, but not limited to, marketing;</li>
                            <li>Accessing, monitoring or copying any content or information from this Website using any "robot," "spider," "scraper" or other automated means or any manual process for any purpose without our express written permission;</li>
                            <li>Violating the restrictions in any robot exclusion headers on this Website or bypassing or circumventing other measures employed to prevent or limit access to this Website;</li>
                            <li>Taking any action that places excessive demand on our services, or imposes, or may impose an unreasonable or disproportionately large load on our servers or other portion of our infrastructure (as determined in our sole discretion);</li>
                            <li>Aggregating any live or post-feature content or other information from the Website (whether using links or other technical means or physical records associated with purchases made through this Website) with material from other Website or on a secondary Website without our express written permission;</li>
                            <li>Deep-linking to any portion of this Website (including, without limitation, the purchase path for any voucher) without our express written permission;</li>
                            <li>Acting illegally or maliciously against the business interests or reputation of Bonus Store , our Restaurants or our services; or</li>
                            <li>Hyperlinking to the Website from any other website without our initial and ongoing consent.</li>
                        </ul>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Refunds and Replacements Policy</b><br />
                            Please refer to our detailed guide on Refund and Return of Items Policy</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Links to Other Websites/Mobile Websites/Apps</b><br />
                            The Website may contain advertisements and/or links to other websites ("Third Party Websites"). These links are provided solely for the convenience of the users. Bonus Store does not endorse, sanction or verify the accuracy or ownership of the information contained in the advertisements or the content of any Third Party Websites or any products or services advertised on Third Party Websites.</p>

                        <p>If you decide to leave the Website and navigate to Third Party Websites, or install any applications, software or download content from any such websites or mobile websites, you do so at your own risk. Once you access a Third Party Website through a link on the Website, in addition to these Terms of Use and you may be subject to the terms of service and other conditions of such Third Party Websites. You should review the applicable terms and policies, including privacy and data gathering practices, of any such website to which you navigate to from the Website, or relating to any applications you use or install from such Third Party Websites. Concerns regarding a Third Party Website should be directed to the Third Party Website itself. Bonus Store bears no responsibility for any action associated with any Third Party Website. Moreover, Bonus Store does not imply an affiliation with any Third Party Website.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Claim of Copyright Infringement</b><br />
                            Bonus Store respects the intellectual property of others. If you believe, in good faith, that any Website Content, or other matter posted on the Website infringes the copyright in a work you own, please contact us with correspondence containing the following:</p>
                        <ul>
                            <li>A physical or electronic signature of the owner, or a person authorized to act on behalf of the owner, of the copyright that is allegedly infringed;</li>
                            <li>Identification of the copyrighted work allegedly infringed;</li>
                            <li>Identifying information reasonably sufficient to allow determination by Bonus Store of the location of the material that is allegedly infringing;</li>
                            <li>Information reasonably sufficient to permit Bonus Store to contact you;</li>
                            <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                            <li>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                        </ul>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Bonus Store Seller's Subscription Fees</b><br />
                            Bonus Store provides a personalized subscription service that allows sellers ("Sellers") to access services and features that are made available on the Website which may require registration and payment of subscription fees ("Subscription Fee").</p>

                        <p>Seller must be 18 years of age, or the age of majority to subscribe for the services and features that are made available to Sellers.</p>
                        <p>We may change our subscription plans and the price for our services from time to time following notice to you.</p>
                        <p>The Seller's Subscription Fee is non-refundable.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Bonus Store Transaction Fees</b><br />
                            We charge a fee for all successful transactions completed by Seller on the Website ("Bonus Store Transaction Fee"). The Bonus Store Transaction Fee is borne by the You, and is calculated as two percent (2%) of the Gross Settlement Price, rounded up to the nearest cent. The Gross Settlement Price for an item is the Buyer's Purchase Monies for the transactions and any voucher applied to such item by the Buyer.</p>

                        <p>The Gross Settlement Price for an item is the Buyer's Purchase Monies for the transactions and any voucher applied to such item by the Buyer.</p>
                        <p>Following the successful completion of a transaction, we shall deduct the Bonus Store Transaction Fee from the Buyer's Purchase Monies, and remit the balance to Seller. We shall issue receipts or tax invoices for the Bonus Store Transaction Fee paid by you on request.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Disclaimers and Limitations of Liability</b><br />
                            Please read this section carefully since it limits the liability of Bonus Store to you. Each of the subsections below only applies up to the maximum extent permitted under applicable law. Nothing herein is intended to limit any rights you may have which may not be lawfully limited. If you are unsure about this or any other section of these terms, please consult with a legal professional prior to accessing or using the Website. By accessing or using the Website, you represent that you have read, understood, and agree to these terms, including this section.</p>

                        <p>The Website is made available to you on an "as is where is", "with all faults" and "as available" basis, with the express understanding that the Bonus Store entities may not monitor, control, or vet user content. As such, your use of the Website is at your own discretion and risk. Bonus Store make no claims or promises about the quality, accuracy, or reliability of the Website, its safety or security, or the Website content. Bonus Store is not liable to you for any loss or damage that might arise, for example, from the Website's/App's inoperability, unavailability or security vulnerabilities or from your reliance on the quality, accuracy, or reliability of the business listings, ratings, reviews, metrics or review filter found on, used on, or made available through the Website.</p>

                        <p>Bonus Store provides a platform through which service or products providers ("Sellers") and customers ("Buyers") may undertake commercial / sale and purchase transactions. Bonus Store is not the agent of any Seller or Buyer and takes no responsibility for the services or products for which any Seller provides. Bonus Store makes no claims or promises with respect to any third party, such as Sellers, businesses or advertisers listed on the Website or the Website's/Apps' Users. Accordingly, Bonus Store are not liable to you for any loss or damage that might arise from their actions or omissions, including, for example, if another user or business misuses your content, identity or personal information, or if you have a negative experience with any Seller, businesses or advertisers listed or featured on the Website. Your purchase and use of products or services offered by third parties including the Seller through the Website is at your own discretion and risk.</p>

                        <p>Bonus Store expressly disclaim all warranties, whether express or implied, including warranties as to the products or services offered by businesses listed on the Website, and implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

                        <p>Your sole and exclusive right and remedy in case of dissatisfaction with the Website, related services, or any other grievance shall be your termination and discontinuation of access to, or use of the Website.</p>

                        <p>Bonus Store 's maximum aggregate liability to you for losses or damages that you suffer in connection with the Website or these terms is limited to the lower of (i) the amount paid, if any, by you to Bonus Store in connection with the Website, or (ii) RM 100.00 ("Capped Amount"). For the avoidance of doubt, Bonus Store shall not be liable to you in any manner for any amount in excess of the Capped Amount. Bonus Store 's liability for any claim hereunder shall be limited to those that you claim within a period of 2 months from the date of the event giving rise to such claim. Bonus Store will not be responsible for any claim that is submitted by you to us after the said 2 months cut-off period.</p>

                        <p>Bonus Store disclaim liability for any (i) indirect, special, incidental, punitive, exemplary, reliance, or consequential damages, (ii) loss of profits, (iii) business interruption, (iv) reputational harm, or (v) loss of information or data.</p>

                        <p>Bonus Store has the right at any time to change, modify, correct, add to, discontinue, or retire any aspect or feature of the Website, including, but not limited to, hours of availability, equipment needed for access or use, or the availability of the Website (or any part thereof) on any particular device or communications service. Bonus Store has no obligation to provide you with notice of any such changes, and Bonus Store is under no obligation to provide you with any support, error corrections, updates, upgrades, bug fixes, and/or enhancements of the Website.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Indemnification</b><br />
                            You agree to defend, indemnify, and hold harmless Bonus Store from all liabilities, claims, demands, damages, losses, proceedings, penalties, fines, costs and expenses, including legal attorneys' fees that arise whatsoever that may be made against and/or sustained suffered or otherwise incurred whether directly or indirectly or however arising by any party whatsoever by reason of or arising out of or in connection with your usage of the Website and/or any product and/or services made available on the Website. Bonus Store reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with Bonus Store in asserting any available defenses.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Governing Law and Jurisdiction</b><br />
                            Malaysian law shall govern all transactions and be applied to construe for all purposes to which these terms apply and you shall submit to the jurisdiction of the court in Malaysia.</p>

                        <p>Any action arising out of these terms or in relation to the usage of the Website or any products and services made available on the Website shall be litigated in, and only in, courts located in Malaysia, and you agree to submit to the exclusive jurisdiction of those courts and further agree that they are the convenient forum for you.</p>

                        <p>In the event you have any dispute, controversy or claim against us arising out of the provision of our services, you shall notify us in writing and the parties shall attempt to negotiate in good faith to resolve the matter. The provision of the said notice and subsequent negotiations shall be a condition precedent to any right of action against us in Court or otherwise.</p>

                        <p>In the event that any of these terms is held to be unenforceable, illegal or invalid, the remaining terms shall remain in effect and shall not be affected or impaired thereby.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>Termination</b><br />
                            You may terminate the Terms and Terms of Use at any time by closing your account and discontinuing your use of the Website.. Please review our Privacy Policy for information about what we do with your account when terminated. We may close your account, suspend your ability to use certain portions of the Website, and/or ban you altogether from the Website for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your account, the Website, Your Content, Website Content, or any other related information.</p>

                        <p style={{ marginBottom: '0' }}><b style={{ color: '#333', }}>General Terms</b><br />
                            We reserve the right to modify, update, or discontinue the Website at our sole discretion, at any time, for any or no reason, and without notice or liability. We may provide you with notices, including those regarding changes to the Terms by email, regular mail or communications through the Website.</p>
                        <p>The Terms contain the entire agreement between you and us regarding the use of the Website, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in the Terms. Any failure on Bonus Store 's part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</p>
                        <p>If any provision of the Terms is found to be unenforceable or invalid, then only that provision shall be modified to reflect the parties' intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable. The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with Bonus Store 's prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.</p>

                    </Container>
                </div>
            </Container>
        </Box>
    );
}
