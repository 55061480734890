import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { getUrl, logoutUser } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Container, Link } from '@material-ui/core';

// ICON
import { FiChevronRight, FiHeadphones, FiHeart, FiArchive, FiTruck } from 'react-icons/fi';
import { IoWalletOutline } from "react-icons/io5";
import { BsChatSquareText } from "react-icons/bs";
import { LuMap } from "react-icons/lu";


// import { TbBinaryTree } from "react-icons/tb";

// COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import Login from '../Auth/Login';

export default function Settings() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { t, i18n } = useTranslation();
    const { accessToken , role } = useSelector(state => state.general);
    const { username, email, mobile, max_rank: rank, max_mrank, mobile_country_code } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();

    const [sales, setSales] = useState({
        personal: 0,
        sponsorGroup: 0,
    });
    const [type, setType] = useState('/images/rank_logo/rank-0s.png');
    const [mrankColor, setMrankColor] = useState('bronze');

    const [drawerLogin, setDrawerLogin] = useState(false);

    // ------------ APIs --------------
    useEffect(() => {
        if(accessToken){
            getUrl(`/user/total-sales`).then(response => {
                if (response.status) {
                    setSales({
                        personal: response.data?.personal_sales ? response.data?.personal_sales : '0.00',
                        sponsorGroup: response.data?.sponsor_group_sales ? response.data?.sponsor_group_sales : '0.00',
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }, []);

    useEffect(() => {
        if (max_mrank) {
            switch (max_mrank.name_display) {
                case '1 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-1s.png')
                    break;
                case '2 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-2s.png')
                    break;
                case '3 Star Manager':
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-3s.png')
                    break;
                case '4 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-4s.png')
                    break;
                case '5 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-5s.png')
                    break;
                case '6 Star Manager':
                    setMrankColor('silver')
                    setType('/images/rank_logo/rank-6s.png')
                    break;
                case '7 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-7s.png')
                    break;
                case '8 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-8s.png')
                    break;
                case '9 Star Manager':
                    setMrankColor('gold')
                    setType('/images/rank_logo/rank-9s.png')
                    break;
                case '10 Star Manager':
                    setMrankColor('platinum')
                    setType('/images/rank_logo/rank-10s.png')
                    break;
                default:
                    setMrankColor('bronze')
                    setType('/images/rank_logo/rank-0s.png')
                    break;
            }
        }
    }, []);

    return (
        <div>
            {accessToken ?
                <TitleBar  btnRegister displayCart currencyButton backgroundColor="bg-header" />
                :
                <TitleBar  btnRegister btnlogin={false}  currencyButton backgroundColor="bg-header"/>
            }
            <div className='bg-header p-b-50'>
                {/* <p className='clwhite fs-header txt-center'>{t('title.account')}</p> */}
            </div>
            <div className='bg-base p-t-24 subheader-radius' style={{ marginTop: -30,}}>
                <Container>
                    <div className='flex-m w-full'>
                        <div style={{ lineHeight: 0, width: 80 }}>
                            <img src='/images/example/avatar.png' alt="avatar" className='bor15' />
                        </div>
                        <div className='p-l-15 clblack lh-11'>
                            {accessToken ?
                                <p className='fs-header w-full txt_truncate p-b-5'> {username}</p>
                                :
                                <p className='fs-header w-full txt_truncate p-b-5'> {t('general.guest')}</p>
                            }
                            {accessToken &&
                                <>
                                    <p className='fs-content w-full txt_truncate'>{`${mobile_country_code !== null ? `${mobile_country_code} - ` : ''} ${mobile}`}</p>
                                    <p className='fs-content w-full txt_truncate'>{email}</p>
                                </>
                            }
                        </div>
                    </div>
                </Container>
            </div>

           <Container>
                <div className='p-t-24'>
                    <div className='flex-sb-m w-full'>
                        <div className='flex-m'>
                        <div className='bg-theme p-lr-20 p-t-5' style={{ borderRadius: '11px 0 0 0', height:25 }}>
                            <p className='fs-title clwhite'>{t('title.order')}</p>
                        </div>
                        <div style={{height:25}}>
                            <img src='/images/general/bkg/rectangle.png' alt="shape" className='w-full' />
                        </div>
                        </div>
                        {accessToken ?
                            <Link underline='none' component={RouterLink} to="/order">
                                <div className='flex-m cl-text pointer translateX'>
                                    <p className='fs-title'>{t('button.all')}</p>
                                    <FiChevronRight className='fs-icon-small' />
                                </div>
                            </Link>
                            :
                            <div className='flex-m cl-text pointer translateX' onClick={() => setDrawerLogin(true)}>
                                <p className='fs-title'>{t('button.all')}</p>
                                <FiChevronRight className='fs-icon-small' />
                            </div>
                        }
                    </div>
                    <div className='bg-theme p-lr-15 p-t-20 p-b-25 flex-sb-m clwhite' style={{ boxShadow: 'inset rgba(0, 0, 0, 0.25) -2px -2px 3px 0px, rgba(160, 52, 52, 0.25) 0px 4px 12px 0px', borderRadius: '0 11px 11px 11px', lineHeight: 0 }}>
                        <Link underline='none' component={RouterLink} to="/order?type=new" className={!accessToken && "btn-disabled"} style={{background:'transparent'}}>
                            <div className='flex-c-m flex-col pointer translateY clwhite'>
                                <IoWalletOutline className='fs-icon' />
                                <p className='p-t-15 fs-remark txt-center'>{t('order.toPay')}</p>
                            </div>
                        </Link>
                        <Link underline='none' component={RouterLink} to="/order?type=paid" className={!accessToken && "btn-disabled"} style={{background:'transparent'}}>
                        <div className='flex-c-m flex-col pointer translateY clwhite'>
                            <FiArchive className='fs-icon' />
                            <p className='p-t-15 fs-remark txt-center'>{t('order.paid')}</p>
                        </div>
                        </Link>
                        <Link underline='none' component={RouterLink} to="/order?type=delivered" className={!accessToken && "btn-disabled"} style={{background:'transparent'}}>
                        <div className='flex-c-m flex-col pointer translateY clwhite'>
                            <FiTruck className='fs-icon' />
                            <p className='p-t-15 fs-remark txt-center'>{t('order.delivered')}</p>
                        </div>
                        </Link>
                        <Link underline='none' component={RouterLink} to="/order?type=completed" className={!accessToken && "btn-disabled"} style={{background:'transparent'}}>
                        <div className='flex-c-m flex-col pointer translateY clwhite'>
                            <BsChatSquareText className='fs-icon' />
                            <p className='p-t-15 fs-remark txt-center'>{t('order.rate')}</p>
                        </div>
                        </Link>
                    </div>
                </div>
           </Container>
            <Container className='p-t-10'>
                {accessToken &&
                    <>
                        <Link underline='none' component={RouterLink} to="/profile-address" className='pointer'>
                            <div className='flex-sb-m w-full translateX'>
                                <div className='flex-m p-tb-15'>
                                    <LuMap className='fs-icon clblack' />
                                    <div className='p-l-15'>
                                        <p className='fs-title clblack'><b>{t('profile.address')}</b></p>
                                        <p className='fs-subtitle cl-label'>{t('settings.subTitle.account')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon clblack' />
                            </div>
                        </Link>

                        <Link underline='none' component={RouterLink} to="/my-wishlists" className='pointer'>
                            <div className='flex-sb-m w-full translateX'>
                                <div className='flex-m p-tb-15'>
                                    <FiHeart className='fs-icon clblack' />
                                    <div className='p-l-15'>
                                        <p className='fs-title clblack'><b>{t('title.myWishlists')}</b></p>
                                        <p className='fs-subtitle cl-label txt-title'>{t('settings.subTitle.wishlist')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon clblack' />
                            </div>
                        </Link>
                        {/* {
                            _.size(_.intersection(role, ['merchant', 'super-merchant'])) == 0 &&
                                <Link underline='none' onClick={() => window.open(`${process.env.REACT_APP_SELLER_URL}/seller/register?username=${username}`)}  className='pointer'>
                                    <div className='flex-sb-m w-full pointer translateX'>
                                        <div className='flex-m p-tb-15'>
                                            <BsShop className='fs-25 cl-theme' />
                                            <div className='p-l-15'>
                                                <p className='fs-16 cl-theme'><b>{t('profile.becomeSeller')}</b></p>
                                                <p className='cl-label'>{t('settings.subTitle.seller')}</p>
                                            </div>
                                        </div>
                                        <FiChevronRight className='fs-25 cl-theme' />
                                    </div>
                                </Link>
                        }
                        {
                            _.size(_.intersection(role, ['merchant', 'super-merchant'])) > 0 &&
                                <Link underline='none' onClick={() => window.open(`${process.env.REACT_APP_SELLER_URL}/login`)} >
                                    <div className='flex-sb-m w-full pointer translateX'>
                                        <div className='flex-m p-tb-15'>
                                            <BsShop className='fs-25 cl-theme' />
                                            <div className='p-l-15'>
                                                <p className='fs-16 cl-theme'><b>{t('profile.sellerSite')}</b></p>
                                                <p className='cl-label'>{t('profile.sellerSite')}</p>
                                            </div>
                                        </div>
                                        <FiChevronRight className='fs-25 cl-theme' />
                                    </div>
                                </Link>
                        } */}
                        {/* <Link underline='none' component={RouterLink} to="/community-setting">
                            <div className='flex-sb-m w-full pointer translateX'>
                                <div className='flex-m p-tb-15'>
                                    <TbBinaryTree className='fs-icon cl-theme' />
                                    <div className='p-l-15'>
                                        <p className='fs-title cl-theme'><b>{t('profile.genealogy')}</b></p>
                                        <p className='fs-subtitle cl-label'>{t('settings.subTitle.genealogy')}</p>
                                    </div>
                                </div>
                                <FiChevronRight className='fs-icon cl-theme' />
                            </div>
                        </Link> */}
                    </>
                }
                <Link underline='none' component={RouterLink} to="/help-center">
                    <div className='flex-sb-m w-full pointer translateX'>
                        <div className='flex-m p-tb-15'>
                            <FiHeadphones className='fs-icon clblack' />
                            <div className='p-l-15'>
                                <p className='fs-title clblack'><b>{t('support.support')}</b></p>
                                <p className='fs-subtitle cl-label'>{t('settings.subTitle.support')}</p>
                            </div>
                        </div>
                        <FiChevronRight className='fs-icon clblack' />
                    </div>
                </Link>

                {/* <Link underline='none' component={RouterLink} to="/setting">
                    <div className='flex-sb-m w-full translateX'>
                        <div className='flex-m p-tb-15'>
                            <FiSettings className='fs-icon clblack' />
                            <div className='p-l-15'>
                                <p className='fs-title clblack'><b>{t('profile.settings')}</b></p>
                                <p className='fs-subtitle cl-label'>{t('settings.subTitle.settings')}</p>
                            </div>
                        </div>
                        <FiChevronRight className='fs-icon clblack' />
                    </div>
                </Link> */}


                <div className='p-t-30'>
                    {
                        accessToken ?
                            <Link underline='none' className='w-full' component="button" onClick={() => {
                                logoutUser();
                                history.replace('/');
                            }}>
                                <div className='btn-outlined-black w-full translateY'>
                                    <p className='fs-button'>{t('button.logout')}</p>
                                </div>
                            </Link>
                            :
                                <div className='btn-outlined-black w-full translateY' onClick={() => setDrawerLogin(true)}>
                                    <p className='fs-button'>{t('button.login')}</p>
                                </div>
                    }
                </div>
            </Container>
            <Login drawerOpen={drawerLogin} setDrawerOpen={setDrawerLogin}/>
        </div>
    );
}