import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// MUI
import { Button, Drawer, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { FiX } from "react-icons/fi";
import { Edit } from 'react-feather';

export default function CheckoutBuyerCard(props) {
    const { buyerInfo, setBuyerInfo, downlineInfo, setDownlineInfo, cartErrors } = props;
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const [userDialog, setUserDialog] = useState({
        type: '',
        open: false,
        name: '',
        email: '',
        mobile: '',
        mobile_country_code: '',
    });

    const openUserDialog = (type, info) => {
        setUserDialog({
            type: type,
            open: true,
            name: info.name,
            email: info.email,
            mobile_country_code: info.mobile_country_code,
            mobile: info.mobile,
        });
    }
    const closeUserModal = () => {
        setUserDialog({ ...userDialog, open: false });
    }
    const saveUserModal = () => {
        if (userDialog.type === 'buyer') {
            setBuyerInfo({
                name: userDialog.name,
                email: userDialog.email,
                mobile_country_code: userDialog.mobile_country_code,
                mobile: userDialog.mobile,
            })
        }
        setUserDialog({ ...userDialog, open: false });
    }

    return (
        <div>
            <div className='glass2 shadow-glass2 bor15 p-all-15'>
                <div>
                    <div className='flex-sb-m w-full'>
                        <p className='fs-18 cl-theme-dark'><b>{t('checkout.buyer')}</b></p>
                        <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog('buyer', buyerInfo)}>
                            <Edit size={18} style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </div>
                    <div>
                        <p className='fs-14 cl-theme-dark'>{t('profile.name')}: {buyerInfo.name}</p>
                        {_.size(cartErrors[`buyer_email`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_email`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        <p className='fs-14 cl-theme-dark'>{t('profile.mobile')}: {`${buyerInfo.mobile_country_code ? `${buyerInfo.mobile_country_code} - ` : ' '}  ${buyerInfo.mobile}`}</p>
                        {_.size(cartErrors[`buyer_mobile_code`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_mobile_code`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        {_.size(cartErrors[`buyer_mobile`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_mobile`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                        <p className='fs-14 cl-theme-dark'>{t('profile.email')}: {buyerInfo.email}</p>
                        {_.size(cartErrors[`buyer_email`]) ?
                            <div className='p-t-5'>
                                {
                                    _.map(cartErrors[`buyer_email`], (errMsg, key) => {
                                        return (
                                            <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className='p-t-24'>
                    <p className='fs-textfield'>{t('checkout.recipientUsername')}</p>
                    <div className='w-full box-textfield'>
                    <TextField
                        type="text"
                        fullWidth
                        value={downlineInfo.username}
                        onChange={(event) => setDownlineInfo({ ...downlineInfo, username: event.target.value })}
                    />
                    </div>
                    {_.size(cartErrors[`recipient_username`]) ?
                        <div className='p-t-5'>
                            {
                                _.map(cartErrors[`recipient_username`], (errMsg, key) => {
                                    return (
                                        <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                    )
                                })
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <Drawer
            anchor='bottom'
            open={userDialog.open}
            onClose={closeUserModal}
            >
                <div className='p-all-20' open={userDialog.open} onClose={closeUserModal}>
                    <div className='flex-sb-m w-full p-b-24'>
                        <p className='fs-header clblack'>{t('checkout.editBuyerInfo')}</p>
                        <div className='pointer' onClick={closeUserModal}>
                            <FiX className="fs-icon" />
                        </div>
                    </div>
                    <div className='flex-col w-full' style={{gap:24}}>
                        <div>
                            <p className='fs-textfield'>{t(`checkout.${userDialog.type}`) + ' ' + t('profile.name')}</p>
                        <div className='w-full box-textfield'>
                        <TextField
                            onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value })}
                            type="text"
                            value={userDialog.name}
                            fullWidth
                        />
                        </div>
                        </div>
                        <div>
                            {/* <p className='fs-textfield'>{t(`checkout.${userDialog.type}`) + ' ' + t('address.mobileCode')}</p> */}
                            <p className='fs-textfield'>{t(`checkout.${userDialog.type}`) + ' ' + t('profile.mobile')}</p>
                            <div className='w-full flex-sb-m'>
                                <div className="box-textfield" style={{ width: 'calc(20% - 10px)', borderRadius: 12 }}>
                                    <TextField
                                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile_country_code: target.value })}
                                        type="number"
                                        style={{textAlign:'center'}}
                                        value={userDialog.mobile_country_code}
                                        fullWidth
                                    />
                                </div>
                                <div className='txt-center' style={{ width: 20 }}>
                                    <p className='fs-21'>-</p>
                                </div>
                                <div className="box-textfield" style={{ width: 'calc(80% - 10px)' }}>
                                    <TextField
                                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value })}
                                        type="number"
                                        value={userDialog.mobile}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className='fs-textfield'>{t(`checkout.${userDialog.type}`) + ' ' + t('profile.email')}</p>
                        <div className='w-full box-textfield'>
                        <TextField
                            onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value })}
                            type="text"
                            value={userDialog.email}
                            fullWidth
                        />
                        </div>
                        </div>
                    </div>
                    <div className='p-t-24'>
                        <Button fullWidth autoFocus onClick={saveUserModal} color="primary" variant="contained">
                            {t('button.confirm')}
                        </Button>
                    </div>
                </div>
            </Drawer>
            {/* <Dialog open={userDialog.open} fullWidth onClose={closeUserModal}>
                <DialogTitle>
                    <Typography variant="h6">{t('checkout.editBuyerInfo')}</Typography>
                    <IconButton aria-label="close" className={styles.closeButton} onClick={closeUserModal}>
                        <CloseOutlined style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.name')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value })}
                        type="text"
                        value={userDialog.name}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('address.mobileCode')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile_country_code: target.value })}
                        type="text"
                        value={userDialog.mobile_country_code}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.mobile')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value })}
                        type="text"
                        value={userDialog.mobile}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                    <TextField
                        variant="filled"
                        label={t(`checkout.${userDialog.type}`) + ' ' + t('profile.email')}
                        onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value })}
                        type="text"
                        value={userDialog.email}
                        fullWidth
                        style={{ paddingBottom: 15 }}
                    />
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button fullWidth autoFocus onClick={saveUserModal} color="primary" variant="contained">
                        {t('button.confirm')}
                    </Button>
                </DialogActions>
            </Dialog> */}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    dialogActionRoot: {
        margin: 0,
        padding: theme.spacing(1),
        border:'none'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
