import _ from 'lodash';
import React, { Fragment, Suspense } from "react";
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { CircularProgress } from '@material-ui/core';

import ScrollToTop from '@helper/ScrollToTop';
import NotificationLoadingProvider from '@providers/NotificationLoadingProvider';

import Footer from '@layouts/Footer';
import Header from '@layouts/Header';
import NotificationLoading from '@layouts/NotificationLoading';
import Home from '@pages/Home';
// import ComingSoon from '@pages/ComingSoon/ComingSoon';

// AUTH
import Login from '@pages/Auth/Login';
import PasswordForgot from '@pages/Auth/PasswordForgot';
import PasswordReset from '@pages/Auth/PasswordReset';
import Register from '@pages/Auth/Register';
import LoginCallBack from '@pages/Auth/ThirdPartyCallback';

// SELLERS
import Sellers from '@pages/Seller/Sellers';

// PRODUCTS
import AccountLinkCallback from '@pages/Auth/AccountLinkCallback';
import Categories from '@pages/Category/Categories';
import Cart from '@pages/Checkout/Cart';
import Checkout from '@pages/Checkout/Checkout';
import Payment from '@pages/Checkout/Payment';
import PaymentCallback from '@pages/Checkout/PaymentCallback';
import Collections from '@pages/Collection/Collections';
import Product from '@pages/Products';
// import Search from '@pages/Products/Search';
import Shop from '@pages/Products/Shop';
// import CategoryList from '@pages/Category/CategoryList';
// import Item from '@pages/Products/Item';
import Review from '@pages/Products/Review';

// SETTINGS
import MyProfile from '@pages/Settings/MyProfile';
import MyOrder from '@pages/Settings/MyOrder';
import Community from '@pages/Settings/Community';
import HelpCenter from '@pages/Settings/HelpCenter';
import Setting from '@pages/Settings/Setting';

import Settings from '@pages/Settings';
import Genealogy from '@pages/Genealogy';


// SETTINGS - MY ORDER
import OrderHistory from "@pages/MyOrder/OrderHistory";
import MyWishlists from '@pages/MyOrder/MyWishlists';
import MyPackage from '@pages/MyOrder/MyPackage';

// SETTINGS - PROFILE
import Profile from '@pages/MyProfile/Profile';
import ProfileAddress from "@pages/MyProfile/ProfileAddress";
import ProfileBankInfo from '@pages/MyProfile/ProfileBankInfo';
import ProfilePassword from '@pages/MyProfile/ProfilePassword';
import ProfileSecurityPassword from '@pages/MyProfile/ProfileSecurityPassword';

// SETTINGS - HELP CENTER
import MerchantServiceAgreement from '@pages/HelpCenter/MerchantServiceAgreement';
import PrivacyAgreement from '@pages/HelpCenter/PrivacyAgreement';
import RefundAndReturnAgreement from '@pages/HelpCenter/RefundAndReturnAgreement';
import TermOfUse from '@pages/HelpCenter/TermOfUse';
import ContactUs from '@pages/HelpCenter/ContactUs';
// import Faq from '@pages/HelpCenter/Faq';

// RECRUIT
import ReferralQr from '@pages/Auth/ReferralQr';
import Recruit from '@pages/Recruit/Recruit';

// WALLET
import Commission from '@pages/Commission/Commission';
import Convert from "@pages/MyWallet/Convert";
import Transaction from "@pages/MyWallet/Transaction";
import Transfer from "@pages/MyWallet/Transfer";
import Wallet from '@pages/MyWallet/Wallet';
import WalletDashboard from '@pages/MyWallet/WalletDashboard';
import Withdrawal from "@pages/MyWallet/Withdrawal";
// import WithdrawalHistory from "@pages/MyWallet/WithdrawalHistory";
// import Bonus from "@pages/MyWallet/Bonus";

// NOTIFICATION
import NotificationCategory from "@pages/Notifications/";
import Announcement from '@pages/Notifications/Announcement';
import AnnouncementDetails from "@pages/Notifications/AnnouncementDetails";
import Notification from "@pages/Notifications/Notification";
import NotificationDetails from "@pages/Notifications/NotificationDetails";

// CHAT
// import Chat from '@pages/Chat/Chat';
// import ChatHome from '@pages/Chat/ChatHome';
// import Chatting from '@pages/Chat/Chatting';
// import AdminChat from '@pages/Chat/AdminChat';
// import ChatButton from '@layouts/ChatButton';

import BackToTop from '@components/BackToTop';

import '@css/styles.css';
import '@css/util.css';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const NewPayment = () => {
    return (
        <Elements stripe={promise}>
            <Payment />
        </Elements>
    )
}

const Loading = () => {
    return (
        <div style={{ position: "fixed", top: '50%', left: '50%' }}>
            <CircularProgress disableShrink />
        </div>
    );
};

const privateRoutes = [
    {
        path: "/home",
        component: <Home />,
        exact: true
    },
    // CHECKOUT & PAYMENT
    {
        path: "/checkout",
        component: <Checkout />,
        exact: true
    },
    {
        path: "/payment",
        component: <NewPayment />,
        exact: true
    },
    {
        path: "/payment/:oid",
        component: <NewPayment />,
        exact: true
    },
    // SETTINGS
    // SETTINGS - PROFILE
    {
        path: "/my-profile",
        component: <MyProfile />,
        exact: true
    },
    {
        path: "/profile",
        component: <Profile />,
        exact: true
    },
    {
        path: "/profile-bank-info",
        component: <ProfileBankInfo />,
        exact: true
    },
    {
        path: "/profile-password",
        component: <ProfilePassword />,
        exact: true
    },
    {
        path: "/profile-security-password",
        component: <ProfileSecurityPassword />,
        exact: true
    },
    {
        path: "/profile-address",
        component: <ProfileAddress />,
        exact: true
    },
    // SETTINGS - ORDER
    {
        path: "/my-order",
        component: <MyOrder />,
        exact: true
    },
    {
        path: "/my-wishlists",
        component: <MyWishlists />,
        exact: true
    },
    {
        path: "/order",
        component: <OrderHistory />,
        exact: true
    },
    {
        path: "/order/:oid",
        component: <OrderHistory />,
        exact: true
    },
    {
        path: "/my-package",
        component: <MyPackage />,
        exact: true
    },
    // SETTINGS - COMMUNITY
    {
        path: "/community-setting",
        component: <Community />,
        exact: true
    },
    {
        path: "/community/:page",
        component: <Genealogy />,
        exact: true
    },
    // WALLETS
    {
        path: "/wallet-home",
        component: <WalletDashboard />,
        exact: true
    },
    {
        path: "/wallet/:wallet",
        component: <Wallet />,
        exact: true
    },
    {
        path: "/bonus-home",
        component: <WalletDashboard />,
        exact: true
    },
    {
        path: "/transfer/:wallet",
        component: <Transfer />,
        exact: true
    },
    {
        path: "/convert/:wallet",
        component: <Convert />,
        exact: true
    },
    {
        path: "/transaction/:wallet",
        component: <Transaction />,
        exact: true
    },
    {
        path: "/withdrawal/:wallet",
        component: <Withdrawal />,
        exact: true
    },
    // {
    //     path: "/withdrawal-history/:wallet",
    //     component: <WithdrawalHistory />,
    //     exact: true
    // },
    // COMMISSION
    {
        path: "/commission/:commission",
        component: <Commission />,
        exact: true
    },
    // {
    //     path:"/bonus/:bonus",
    //     component: <Bonus />,
    //     exact: true
    // },
    // {
    //     path:"/bonus",
    //     component: <Bonus />,
    //     exact: true
    // },
    // ANNOUNCEMENT
    {
        path: "/notification-category",
        component: <NotificationCategory />,
        exact: true
    },
    {
        path: "/notification",
        component: <Notification />,
        exact: true
    },
    {
        path: "/notification-detail/:id",
        component: <NotificationDetails />,
        exact: true
    },
    {
        path: "/announcement",
        component: <Announcement />,
        exact: true
    },
    {
        path: "/announcement-detail/:id",
        component: <AnnouncementDetails />,
        exact: true
    },
    // RECRUIT
    {
        path: "/recruit",
        component: <Recruit />,
        exact: true
    },
    {
        path: "/referral-qr",
        component: <ReferralQr />,
        exact: true
    },
    // CHAT
    // {
    //     path: "/chat",
    //     component: <Chat />,
    //     exact: true
    // },
    // {
    //     path: "/chatHome",
    //     component: <ChatHome />,
    //     exact: true
    // },
    // {
    //     path: "/chatting",
    //     component: <Chatting />,
    //     exact: true
    // },
    // {
    //     path:"/customer-service",
    //     component:<AdminChat />,
    //     exact:true,
    // },
];

function PrivateRoute({ children, ...rest }) {
    const { accessToken } = useSelector(state => state.general);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken
                    ? (children)
                    : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default function App() {
    const { accessToken } = useSelector(state => state.general);
    return (
        <NotificationLoadingProvider>
            <Suspense fallback={<Loading />}>
                <Router basename={process.env.REACT_APP_BASE_URL}>
                    <LastLocationProvider>
                        <Fragment>
                            <ScrollToTop />
                            <Header />
                            <div id='chatStyle' className="bg-base pos-relative min-h-100vh p-b-85" style={{width: 420, margin: '0 auto'}}>
                                <BackToTop />
                                <Switch>
                                    <Route path="/" exact component={Home}></Route>
                                    {/* <Route path="/coming-soon" component={ComingSoon} /> */}

                                    {/* SETTINGS */}
                                    <Route path="/settings" component={Settings} />
                                    <Route path="/setting" component={Setting} />
                                    <Route path="/help-center" component={HelpCenter} />
                                    <Route path="/merchantServiceAgreement" component={MerchantServiceAgreement} />
                                    <Route path="/privacyAgreement" component={PrivacyAgreement} />
                                    <Route path="/refundAndReturnAgreement" component={RefundAndReturnAgreement} />
                                    <Route path="/termOfUse" component={TermOfUse} />
                                    <Route path="/contactUs" component={ContactUs} />
                                    {/* <Route path="/faq" exact component={Faq} /> */}

                                    {/* AUTH */}
                                    {/* <Route path="/login" component={Login} /> */}
                                    {/* <Route path="/register" component={Register} /> */}
                                    <Route path="/forgot-password" component={PasswordForgot} />
                                    <Route path="/reset-password/:token" component={PasswordReset}>{accessToken ? <Redirect to={{ pathname: "/" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider>}</Route>
                                    <Route path="/login-callback" component={LoginCallBack} />

                                    {/* SELLER */}
                                    <Route path="/seller/:id" component={Sellers} />

                                    {/* PRODUCT */}
                                    <Route path="/cart" component={Cart} />
                                    <Route path="/category/:id" component={Categories} />
                                    <Route path="/collection/:id" component={Collections} />
                                    <Route path="/product/:id" component={Product} />
                                    {/* <Route path="/search/:keyword" component={Search} /> */}
                                    {/* <Route path="/search" component={Search} /> */}
                                    {/* <Route path="/item/:id" component={Item} /> */}
                                    {/* <Route path="/categorylist" component={CategoryList} /> */}
                                    <Route path="/shop/:shopSlug" component={Shop} />
                                    <Route path="/review/:id" component={Review} />

                                    {/* PAYMENT */}
                                    <Route path="/callback" component={AccountLinkCallback} />
                                    <Route path="/revenuemonster/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=revenuemonster' }} />)}></Route>
                                    <Route path="/bonuschains/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=bonuschains' }} />)}></Route>
                                    <Route path="/paymentresult/:oid" component={PaymentCallback} />
                                    <Route path="/paymentresult/" component={PaymentCallback} />

                                    {/* <Route path="/affiliant-partner" component={AffiliantPartner} /> */}

                                    {
                                        _.map(privateRoutes, route => (
                                            <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}</PrivateRoute>
                                        ))
                                    }

                                    <Route path="*"><Redirect to="/" /></Route>
                                </Switch>
                            </div>
                            {/* <ChatButton /> */}
                            <Footer />
                        </Fragment>
                    </LastLocationProvider>
                </Router>
            </Suspense>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );
};