import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import SwipeableViews from 'react-swipeable-views';

import _ from 'lodash';

import { getUrl, putUrl, postUrl } from '@helper/ApiAction'; //, deleteUrl
import useNotificationLoading from '@helper/useNotificationLoading';
import { useQuery } from '@helper/Tools';
import { Rating } from '@material-ui/lab';
import TabPanel from '@layouts/TabPanel';
import { Helmet } from "react-helmet";

//MUI
import { Avatar, Grid, Button, Typography, Link, Box, ListItem, Container, Chip, AppBar, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField, Switch } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//ICON
import { PiListStarFill } from "react-icons/pi";
import { BsHourglassSplit, BsBagCheckFill } from "react-icons/bs";
import { FaTruck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { TiStarFullOutline } from "react-icons/ti";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { LuAlertTriangle } from "react-icons/lu";


//COMPONENTS & LAYOUTS
import WebpImg from '@layouts/WebpImg';


const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
    },
    2: {
        icon: <SentimentSatisfiedIcon />,
    },
    3: {
        icon: <SentimentVerySatisfiedIcon />,
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const trackButtonEn =
    `        
        <input type="text" id="TrackNo" maxLength="50" class="tracking-box"  placeholder="Enter tracking no..." >
        <input type="button" value="TRACK" onClick="inputTrack()" class="btn-tracking translateXleft">
    `
const trackButtonCn =
    `        
        <input type="text" id="TrackNo" maxLength="50" class="tracking-box"  placeholder="输入追踪号码..." >
        <input type="button" value="追踪" onClick="inputTrack()" class="btn-tracking translateXleft">
    `

export default function Order(props) {
    const { t, i18n } = props;
    const styles = useStyles();
    const { walletList:wallets } = useSelector(state => state.wallet);
    const { username } = useSelector(state => state.user);
    const query = useQuery();
    const tabType = query.get('type');

    const [toPay, setToPay] = useState([]);
    const [paid, setPaid] = useState([]);
    const [delivered, setDelivered] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [cancelled, setCancelled] = useState([]);
    const [refunded, setRefunded] = useState([]);

    const tabTypeToIndex = {
        new : 0,
        paid: 1,
        delivered: 2,
        completed: 3, 
        cancelled: 4,
        refunded: 5,
    };

    const [state, setState] = useState({
        tabIndex: tabTypeToIndex[tabType] ? tabTypeToIndex[tabType] : 0,
        rateDialog: false,
        rateOrderId: null,
        page: 1,
        totalPage: 0,
        total: 0
    });
    const [orderStatus, setOrderStatus] = useState([]);

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [currencyData, setCurrencyData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);

    const [rateOrder, setRateOrder] = useState([]);
    const [shopRate, setShopRate] = useState(0);
    const [shopReview, setShopReview] = useState();
    const [count, setCount] = useState({
        product:0,
        shop:0
    });
    const [shopInfo, setShopInfo] = useState({});
    const [anonymous, setAnonymous] = useState(false);
    const [rate, setRate] = useState();
    const [review, setReview] = useState();
    const [rateError, setRateError] = useState();
    const [reviewError, setReviewError] = useState();
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order_no: '',
        remark: ''
    });
    const [shippingWalletId, setShippingWalletId] = useState([]);

    const location = useLocation();
    let history = useHistory();
    
    const theme = useTheme();

    // console.log("rate", rate);
    // console.log("review", review);

    useEffect(() => {
        if (location.state && location.state.error_msg) {
            addAlert(location.state.error_msg);
            history.replace({ ...history, state: [] });
        }

        isMountedRef.current = true;
        let type = '';

        if (state.tabIndex === 0 || tabType === 'new') {
            type = 'new';
        } else if (state.tabIndex === 1 || tabType === 'paid') {
            type = 'paid';
        } else if (state.tabIndex === 2 || tabType === 'delivered') {
            type = 'delivered';
        } else if (state.tabIndex === 3 || tabType === 'completed') {
            type = 'completed';
        } else if (state.tabIndex === 4 || tabType === 'cancelled') {
            type = 'cancelled';
        } else if (state.tabIndex === 5 || tabType === 'refunded') {
            type = 'refunded';
        }

        getUrl(`orders?type=${type}`)
            .then(orderList => {
                if (isMountedRef.current) {
                    setCurrencyData(orderList.currency_data);
                    // console.log("orderList", orderList.data)
                    if (orderList.data) {
                        if (state.tabIndex === 0) {
                            setToPay(orderList.data.data);
                        } else if (state.tabIndex === 1) {
                            setPaid(orderList.data.data);
                        } else if (state.tabIndex === 2) {
                            setDelivered(orderList.data.data);
                        } else if (state.tabIndex === 3) {
                            setCompleted(orderList.data.data);
                        } else if (state.tabIndex === 4) {
                            setCancelled(orderList.data.data);
                        } else if (state.tabIndex === 5) {
                            setRefunded(orderList.data.data);
                        }
                    }
                    setState(state => ({ ...state, totalPage: orderList.data.last_page, total: orderList.data.total }));

                    setOrderStatus(orderList.order_status);
                    
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [state.tabIndex, addAlert, refreshData]);

    useEffect(() => {
        getUrl('shipping_fee/wallet').then(response => {
            if(response.status){
                if(_.size(response.data) > 0){
                    setShippingWalletId(response.data.id);
                }else{
                    setShippingWalletId('cash');
                }
            }else{
                addAlert(JSON.stringify(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, [])

    useEffect(() => {
        if (tabType) {
            let newTabIndex = tabTypeToIndex[tabType] ? tabTypeToIndex[tabType] : 0;
            setState(state => ({ ...state, tabIndex: newTabIndex, page: 1, totalPage: 0, total: 0 }));
        }
    }, [tabType])

    const handleCountsCharacters = (event, field) => {
        setCount({
        ...count,
        [field]: event.target.value.length,
        });
        };

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });

        let type = "new";
        if (newValue === 1) {
            type = 'paid';
        } else if (newValue === 2) {
            type = 'delivered';
        } else if (newValue === 3) {
            type = 'completed';
        } else if (newValue === 4) {
            type = 'cancelled';
        } else if (newValue === 5) {
            type = 'refunded';
        }
        history.replace(`/order?type=${type}`);
    };

    const handleChangeIndex = index => {
        setState({ ...state, tabIndex: parseInt(index) });

        let type = "new";
        if (parseInt(index) === 1) {
            type = 'paid';
        } else if (parseInt(index) === 2) {
            type = 'delivered';
        } else if (parseInt(index) === 3) {
            type = 'completed';
        } else if (parseInt(index) === 4) {
            type = 'cancelled';
        } else if (parseInt(index) === 5) {
            type = 'refunded';
        }
        history.replace(`/order?type=${type}`);
    };

    const handlePaginationChange = (event, value) => {
        setLoading(true);
        getUrl(`orders?type=${tabType}&page=${value}`).then(orderList => {
            if (isMountedRef.current) {
                setCurrencyData(orderList.currency_data);
                // console.log("orderList", orderList.data)
                if (orderList.data) {
                    if (state.tabIndex === 0) {
                        setToPay(orderList.data.data);
                    } else if (state.tabIndex === 1) {
                        setPaid(orderList.data.data);
                    } else if (state.tabIndex === 2) {
                        setDelivered(orderList.data.data);
                    } else if (state.tabIndex === 3) {
                        setCompleted(orderList.data.data);
                    } else if (state.tabIndex === 4) {
                        setCancelled(orderList.data.data);
                    } else if (state.tabIndex === 5) {
                        setRefunded(orderList.data.data);
                    }
                }
                setLoading(false);
                setOrderStatus(orderList.order_status);
                setState(state => ({ ...state, page: value, totalPage: orderList.data.last_page, total: orderList.data.total }));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    };

    const confirmCompleted = (orderNo) => {
        setLoading(true);
        putUrl(`orders/${orderNo}`)
            .then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert(t('order.confirmCompletedSuccess'), 'success');
                    setRefreshData(!refreshData);
                    history.replace(`/order?type=completed`);
                } else {
                    addAlert(response.data);
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const handleCancelOrderOpen = (orderNo) => {
        setCancelOrderDialog({
            open: true,
            order_no: orderNo
        });
    }

    const handleCancelOrderClose = () => {
        setCancelOrderDialog({
            open: false,
            order_no: '',
            remark: ''
        });
    }

    const cancelOrder = () => {
        setLoading(true);
        setCancelOrderDialog({ ...cancelOrderDialog, open: false });
        const updateData = {
            remark: cancelOrderDialog.remark,
            order_no_array: cancelOrderDialog.order_no
        };
        postUrl(`orders/cancel_order`, updateData)
        .then(response => {
            setLoading(false);
            if(response.status){
                addAlert(t('order.cancelSuccess'), 'success');
                setRefreshData(!refreshData);
                handleCancelOrderClose();
            }else{
                setCancelOrderDialog({ ...cancelOrderDialog, open: true });
                addAlert(response.data);
            }
        }).catch(error => {
            setLoading(false);
            setCancelOrderDialog({ ...cancelOrderDialog, open: true });
            addAlert(JSON.stringify(error.message));
        });
    }
    
    const generateStatusIcon = status => {
        if (status === 10 || status === 60) {
            //return
            return <MdCancel className='fs-22' style={{color:'#c86767'}} />
        } else if (status === 20) {
            // new
            return <PiListStarFill className='fs-22 cl-theme' />
        } else if (status === 30) {
            // processing
            return <BsHourglassSplit className='fs-22' style={{color:'#dda816'}} />
        } else if (status === 35 || status === 40) {
            // delivered
            return <FaTruck className='fs-22 cl-theme' />
        } else if (status === 50) {
            // completed
            return <BsBagCheckFill className='fs-22 cl-theme' />
        }
    }

    const rateProduct = order_no => {
        const toRateOrder = _.find(completed, { 'order_no': order_no });
        if (_.size(toRateOrder) > 0) {
            setShopInfo(toRateOrder.merchant_company);
        }
        _.map(toRateOrder.order_details, orderItem => {
            // console.log("orderItem", orderItem)
            setRate(rate => ({ ...rate, [orderItem.id]: 0 }))
            setReview(review => ({ ...review, [orderItem.id]: '' }))
            setReviewError(reviewError => ({ ...reviewError, [orderItem.id]: '' }))
        })
        setRateOrder(toRateOrder);
        setState({ ...state, rateDialog: true, rateOrderId: order_no })
    }

    const submitRating = () => {
        const postParam = {
            shop_rate: shopRate,
            shop_review: shopReview,
            anonymous,
            rate,
            review
        };
        // console.log("postParam", postParam)
        putUrl(`orders/review/${rateOrder.order_no}`, postParam).then(response => {
            if (response.status) {
                // console.log("response", response);
                // addAlert(t('order.confirmCompletedSuccess'), 'success');
                // setRefreshData(!refreshData);
                // history.replace(`/order?type=completed`);
                addAlert(t('order.ratingComplete'), 'success');
                getUrl(`orders?type=completed`)
                    .then(orderList => {
                        if (orderList.data) {
                            setCompleted(orderList.data.data);
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                setState({ ...state, rateDialog: false, rateOrderId: null });
                setRateOrder();
                setRate();
                setReview();
                setRateError();
                setReviewError();
            } else {
                if (_.size(response.error) > 0) {
                    _.map(response.error, (errorItem, errorIndex) => {
                        if (_.includes(['shop_rate', 'shop_review'], errorIndex)) {
                            setRateError(rateError => ({ ...rateError, [errorIndex]: errorItem }));
                        } else {
                            const errorKey = _.split(errorIndex, ".");
                            if (_.size(errorKey) > 0) {
                                if (errorKey[0] === 'rate') {
                                    setRateError(rateError => ({ ...rateError, [errorKey[1]]: errorItem }))
                                } else if (errorKey[0] === 'review') {
                                    setReviewError(reviewError => ({ ...reviewError, [errorKey[1]]: errorItem }));
                                }
                            }
                        }
                    })
                }
                // addAlert(123);
            }
        })
            .catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }

    const {currency,currencyRate,currencyDisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencyDisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
        
    });

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;
        if(paymentIndex === 'multi_wallet'){
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if(multiPricing){
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
            }else{
                prefix = "Other wallets";
            }
        }else
        if(paymentIndex !== 'cash' && !isNaN(paymentIndex)){
            _.map(wallets, wallet => {
                if(parseInt(wallet.id) === parseInt(paymentIndex)){
                    let name = _.split(wallet.name, '|');
                    prefix = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                    // decimal = wallet.decimal;
                }
            })
        }else if(paymentIndex === 'cash'){
            paymentItem = paymentItem*currencyRate[`${currency}`]
            prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
        }else{
            prefix = paymentIndex;
        }
        return (
            <NumberFormat value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix+' ' : ''} />
        )
    }

    const generateListing = passOrder => {
        return (
            <div>
                {
                    _.size(passOrder) > 0
                    ?
                    _.map(passOrder, (orderItem, orderIndex) => {
                        let totalPrice = {};

                        if (_.size(orderItem.order_shippings) > 0) {
                            _.map(orderItem.order_shippings, shippingValue => {
                                let countShipping = false;
                                if(orderItem.status === 60){
                                    countShipping = true;
                                }else{
                                    _.map(shippingValue.order_shipping_details, shipingDetail => {
                                        _.map(orderItem.order_details, orderDetails => {
                                            if(orderDetails.id === shipingDetail.order_detail_id){
                                                if(orderDetails.status){
                                                    countShipping = true; 
                                                }
                                            }
                                        })
                                    })
                                }
                                if(countShipping){
                                    if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                        let wid = shippingWalletId;
                                        if(shippingValue.wallet_id > 0){
                                            wid = shippingValue.wallet_id;
                                        }
                                        if (_.isUndefined(totalPrice[wid])) {
                                            totalPrice[wid] = 0;
                                        }
                                        totalPrice[wid] += parseFloat(shippingValue.fee);
                                    }
                                }
                            })
                        }
                        let rateDone = false;

                        _.map(orderItem.order_details, (orderDetailsItem) => {
                            if (_.size(orderDetailsItem.product_review) > 0) {
                                rateDone = true;
                            }
                        });

                        return (
                            <div key={orderIndex} className='shadow-out bor15 glass2 p-lr-15 p-b-15' style={{marginBottom:20}}>
                                <div>
                                    {/* <Grid item xs={12} sm={6}>
                                            <div className='flex-m w-full'>
                                                <Avatar variant="rounded" className={styles.small}>
                                                    <StorefrontIcon fontSize="default" className={styles.user} />
                                                </Avatar>
                                                <Link underline='none' color="inherit" to={`/shop/${orderItem.merchant_company.slug}`} component={RouterLink}>
                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                                        {orderItem.merchant_company[`shop_name_${i18n.language}`]}
                                                    </Typography>
                                                </Link>
                                            </div>
                                        </Grid> */}
                                        
                                    {/* RATING */}
                                    <div className='w-full flex-c-m p-b-10'>
                                        <div className='p-tb-8 p-lr-20 flex-c-m txt-center' style={{ borderRadius: '0 0 15px 15px', minWidth: '60%', width: 'fit-content', background: '#efefef2b', boxShadow: 'inset 0 -2px 3px 0 #0000000f, 0 4px 3px 0 #fff' }}>
                                            {
                                                rateDone ?
                                                    <TiStarFullOutline className='fs-22 cl-label' />
                                                    : generateStatusIcon(orderItem.status)
                                            }
                                            <p className='p-l-5 fs-14'>
                                                <b>
                                                    {
                                                        rateDone ?
                                                            t('order.rated')
                                                            : orderItem.status_display
                                                    }
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}>
                                    {
                                        _.map(orderItem.order_details, (orderDetailsItem) => {
                                            if ((orderItem.status === 60 || orderDetailsItem.status) && _.size(orderDetailsItem.payment) > 0) {
                                                _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                    if(paymentIndex ==="multi_wallet"){
                                                        let multiP = JSON.parse(orderDetailsItem.multi_pricing);
                                                        let name = _.split(multiP.multi_wallet_detail.pricing_name, '|');
                                                        paymentIndex = i18n.language === 'cn' ? (name[1]?name[1]:name[0]) : name[0];
                                                    }
                                                    if (_.isUndefined(totalPrice[paymentIndex])) {
                                                        totalPrice[paymentIndex] = 0;
                                                    }
                                                    totalPrice[paymentIndex] += parseFloat(paymentItem);
                                                })
                                            }

                                            return (
                                                <div key={orderDetailsItem.id} className='p-b-20'>
                                                    <div className='p-all-10 flex-sb-m w-full'>
                                                        <div className='bg-label flex-c-m bor10' style={{lineHeight:0}}>
                                                            <WebpImg src={orderDetailsItem.image} alt="cart product" className={styles.productImg} containerClass={styles.productImg} />
                                                        </div>
                                                        <div className='p-l-10 w-full'>
                                                            <div className='w-full flex-sb-m p-b-10 lh-12'>
                                                                {/* <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}> */}
                                                                <p className='fs-title txt_truncate2 p-r-15' style={{ height: 'unset' }}>{orderDetailsItem.title_display}</p>
                                                                  {/* </Link> */}
                                                                <p className='fs-16'>x{orderDetailsItem.quantity}</p>
                                                              
                                                            </div>
                                                            {
                                                                (_.has(orderDetailsItem.product_attribute_display, 'color') || _.has(orderDetailsItem.product_attribute_display, 'size'))
                                                                &&
                                                                <div className='box-variant'>
                                                                    {
                                                                        _.map(orderDetailsItem.product_attribute_display, (variantItem, variantIndex) => {
                                                                            if (_.includes(['color', 'size'], variantIndex)) {
                                                                                return (
                                                                                    <p key={variantItem} style={{ width: 'fit-content' }} className='p-lr-5 fs-13'>{variantItem > 0 ? ", " : ""}{variantItem}</p>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            }


                                                            <div>
                                                                {/* {
                                                                        _.size(orderDetailsItem.payment) > 0
                                                                        ?
                                                                        _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                            if(parseFloat(paymentItem) > 0){
                                                                                return (
                                                                                    <Box key={paymentIndex}>
                                                                                        <Typography variant="caption" className={styles.priceStyle} style={{ fontSize: 14 }}>
                                                                                            {priceDisplay(paymentItem, paymentIndex, orderDetailsItem)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                );
                                                                            }
                                                                        })
                                                                        :
                                                                        <Typography variant="caption">
                                                                            <NumberFormat className={styles.priceStyle} style={{ fontSize: 14 }} value={orderDetailsItem.price*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                        </Typography>
                                                                    } */}
                                                                {
                                                                    orderDetailsItem.status
                                                                        ?
                                                                        null
                                                                        :
                                                                        <p className='fs-remark bor10 p-all-5 cl-text' style={{ border: "1px solid grey", width:'fit-content' }}>
                                                                            {t('order.productCancelled')}
                                                                        </p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Link>

                                <div className='p-lr-15'>
                                        <div className='flex-sb w-full'>
                                            <p className='txt-upper fs-remark cl-label'><b>{t('order.orderTotal')}:</b></p>
                                            <div className='txt-right'>
                                                {
                                                    _.size(totalPrice)
                                                        ?
                                                        _.map(totalPrice, (priceItem, priceIndex) => {
                                                            if (parseFloat(priceItem) > 0) {
                                                                return (
                                                                    <div key={priceIndex} className="fs-content cl-theme">
                                                                        <b>{priceDisplay(priceItem, priceIndex)}</b>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        :
                                                    <div className="fs-content cl-theme">
                                                        <b>0.00</b>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    <div>
                                        {
                                            orderItem.status === parseInt(orderStatus['new']) ?
                                                <div className='p-t-10'>
                                                    <Button size="large" variant="outlined" fullWidth color="primary" onClick={() => history.push(`/payment/${encodeURIComponent(orderItem.purchase_no)}`)}>
                                                        {/* <Link underline='none' color="inherit" to={`/payment/${encodeURIComponent(orderItem.purchase_no)}`} component={RouterLink}> </Link> */}
                                                        <Typography variant="caption">{t('button.payNow')}</Typography>
                                                    </Button>
                                                </div>
                                                : null
                                        }
                                        <div className='p-t-10'>
                                            <div className='btn-theme w-full translateY' onClick={() => history.push(`/order/${orderItem.order_no}`)}>
                                                {/* <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}> </Link> */}
                                                <p>
                                                    {t('order.viewOrder')}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        {
                                            (orderItem.status === parseInt(orderStatus['shipping']) || orderItem.status === parseInt(orderStatus['delivered'])) ?
                                                <div className='p-t-15'>
                                                    <div className='btn-outlined-theme w-full translateY' onClick={() => confirmCompleted(orderItem.order_no)}>
                                                        <p>
                                                            {t('order.confirmCompleted')}
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            orderItem.status === parseInt(orderStatus['completed']) && !rateDone ?
                                                <div className='p-t-15'>
                                                    <div className='btn-outlined-theme w-full translateY' onClick={() => rateProduct(orderItem.order_no)}>
                                                        <p>
                                                            {t('order.rateProduct')}
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    </div>
                            </div>
                        )
                    })
                    :
                        <div className='p-t-80'>
                            <div className='empty-img'>
                                <img src="/images/empty/order.png" className='w-full' alt="empty" />
                            </div>
                            <p className='txt-center fs-content'>{t('order.noOrder')}</p>
                        </div>
                }

                
                {state.total > 0 &&
                    <Grid container spacing={1} justify="center" >
                        <Pagination count={state.totalPage} shape="rounded" page={state.page}
                            onChange={handlePaginationChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                />
                            )}
                        />
                    </Grid>
                }
            </div>
        )
    }

    return (
        <div>
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                <p className='txt-center fs-header clblack p-b-24'>{t('title.order')}</p>
                <AppBar position="static" color="inherit" elevation={0} style={{ backgroundColor: "transparent", marginBottom: 30 }}>
                    <Tabs
                        value={state.tabIndex}
                        onChange={handleChange}
                        // variant="fullWidth"
                        scrollButtons="on"
                        style={{ color: '#000' }}
                        variant="scrollable"
                        fullwidth="true"
                    >
                        <Tab className='fs-content' label={t('order.toPay')} />
                        <Tab className='fs-content' label={t('order.paid')} />
                        <Tab className='fs-content' label={t('order.delivered')} />
                        <Tab className='fs-content' label={t('order.completed')} />
                        <Tab className='fs-content' label={t('order.cancelled')} />
                        <Tab className='fs-content' label={t('order.refunded')} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis='x'
                    index={state.tabIndex}
                    onChangeIndex={handleChangeIndex}
                    className='pos-relative'
                >
                    {/* //////////////  TO PAY  ////////////// */}
                    <TabPanel value={state.tabIndex} index={0} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div style={{ minHeight: 450 }}>
                            <div >
                                {
                                    _.size(toPay) > 0 ? _.map(toPay, (purchaseItem, purchaseIndex) => {
                                        // console.log("purchaseItem", purchaseItem);
                                        let totalPrice = {};
                                        let showOrder = true;
                                        let orderNoArray = [];
                                        let ableCancel = false;

                                        // hide payment failed order
                                        if (_.size(purchaseItem) > 0) {
                                            _.map(purchaseItem, (orderItem) => {
                                                if (_.size(orderItem.payments) > 0 && orderItem.status) {
                                                    _.map(orderItem.payments, paymentItem => {
                                                        if (paymentItem.status === parseInt(orderStatus['returned']) || paymentItem.status === parseInt(orderStatus['new'])) {
                                                            showOrder = false
                                                        }
                                                    })
                                                }
                                            })
                                        }

                                        if (showOrder) {
                                            return (
                                                <div key={purchaseIndex} className='glass2 shadow-glass2 bor15 p-all-10 m-b-20'>
                                                    {
                                                        _.size(purchaseItem) > 0 ?
                                                            _.map(purchaseItem, (orderItem) => {
                                                                if(orderItem.status === 20){
                                                                    orderNoArray.push(orderItem.order_no);
                                                                    ableCancel = true;
                                                                    if (_.size(orderItem.order_shippings) > 0) {
                                                                        _.map(orderItem.order_shippings, shippingValue => {
                                                                            let countShipping = false;
                                                                            _.map(shippingValue.order_shipping_details, shipingDetail => {
                                                                                _.map(orderItem.order_details, orderDetails => {
                                                                                    if (orderDetails.id === shipingDetail.order_detail_id) {
                                                                                        if (orderDetails.status) {
                                                                                            countShipping = true;
                                                                                        }
                                                                                    }
                                                                                })
                                                                            })
                                                                            if (countShipping) {
                                                                                if (shippingValue.channel === 'self' || shippingValue.channel === 'mall') {
                                                                                    let wid = shippingWalletId;
                                                                                    if (shippingValue.wallet_id > 0) {
                                                                                        wid = shippingValue.wallet_id;
                                                                                    }
                                                                                    if (_.isUndefined(totalPrice[wid])) {
                                                                                        totalPrice[wid] = 0;
                                                                                    }
                                                                                    totalPrice[wid] += parseFloat(shippingValue.fee);
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    return (
                                                                        <div key={orderItem.id}>
                                                                            {/* Merchant Company */}
                                                                            <div className='p-tb-8 p-lr-15'>
                                                                                <Grid container>
                                                                                    <Grid item xs={8} sm={8}>
                                                                                        <div className='flex-m'>
                                                                                            <Avatar variant="rounded" className={styles.small}>
                                                                                                <StorefrontIcon fontSize="default" className={styles.user} />
                                                                                            </Avatar>
                                                                                            <p className='fs-title clblack'>
                                                                                                {orderItem.merchant_company[`shop_name_${i18n.language}`]}
                                                                                            </p>
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} sm={4}>
                                                                                        <div className='w-full flex-r-m'>
                                                                                            <div className='fs-icon cl-theme'>
                                                                                                {generateStatusIcon(orderItem.status)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                            {/* Product Item */}
                                                                            {
                                                                                _.map(orderItem.order_details, (orderDetailsItem) => {
                                                                                    if (_.size(orderDetailsItem.payment) > 0 && orderDetailsItem.status) {
                                                                                        _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                                            if (paymentIndex === "multi_wallet") {
                                                                                                let multiP = JSON.parse(orderDetailsItem.multi_pricing);
                                                                                                paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                                                            }
                                                                                            if (_.isUndefined(totalPrice[paymentIndex])) {
                                                                                                totalPrice[paymentIndex] = 0;
                                                                                            }
                                                                                            totalPrice[paymentIndex] += parseFloat(paymentItem);
                                                                                        })
                                                                                    }
                                                                                    return (
                                                                                        <Box key={orderDetailsItem.id}>
                                                                                            <ListItem>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={3} sm={3}>
                                                                                                        <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}>
                                                                                                            <div className='bg-label flex-c-m bor10' style={{ lineHeight: 0, width: 65 }}>
                                                                                                                <WebpImg src={orderDetailsItem.image} alt="cart product" className={styles.productImg} containerClass={styles.productImg} />
                                                                                                            </div>
                                                                                                        </Link>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={9} sm={9} style={{ paddingLeft: 15 }}>
                                                                                                        <div>
                                                                                                            <Link underline='none' color="inherit" to={`/order/${orderItem.order_no}`} component={RouterLink}><p className='fs-content'><b>{orderDetailsItem.title_display}</b></p></Link>
                                                                                                        </div>
                                                                                                        <div className='w-full flex-sb-m'>
                                                                                                            {
                                                                                                                (_.has(orderDetailsItem.product_attribute_display, 'color') || _.has(orderDetailsItem.product_attribute_display, 'size'))
                                                                                                                    ?
                                                                                                                    <div className='box-variant'>
                                                                                                                        {
                                                                                                                            _.map(orderDetailsItem.product_attribute_display, (variantItem, variantIndex) => {
                                                                                                                                if (_.includes(['color', 'size'], variantIndex)) {
                                                                                                                                    return (
                                                                                                                                        <p key={variantItem} style={{ width: 'fit-content' }} className='p-lr-5 fs-13'>{variantItem > 0 ? ", " : ""}{variantItem}</p>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div />
                                                                                                            }
                                                                                                            <p className='p-l-8 fs-remark txt-right'>x{orderDetailsItem.quantity}</p>
                                                                                                        </div>
    
                                                                                                        {/* <div className='txt-right'>
                                                                                                            {
                                                                                                                _.size(orderDetailsItem.payment) > 0
                                                                                                                    ?
                                                                                                                    _.map(orderDetailsItem.payment, (paymentItem, paymentIndex) => {
                                                                                                                        if (parseFloat(paymentItem) > 0) {
                                                                                                                            return (
                                                                                                                                <div key={paymentIndex}>
                                                                                                                                    <p className='fs-content cl-theme'>
                                                                                                                                        {priceDisplay(paymentItem, paymentIndex, orderDetailsItem)}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    })
                                                                                                                    :
                                                                                                                    <div>
                                                                                                                        <Typography variant="caption">
                                                                                                                            <NumberFormat className={styles.priceStyle} value={parseFloat(orderDetailsItem.price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                                        </Typography>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div> */}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </ListItem>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            }) : null
                                                    }
                                                    <div>
                                                        <div className='p-tb-8 p-lr-15'>
                                                            <div className='divider-white m-b-15' style={{ height: 3 }} />
                                                            <div className='flex-sb w-full'>
                                                                <p className='fs-title'>{t('order.totalAmount')}: </p>
                                                                <div className='txt-right'>
                                                                    {
                                                                        _.size(totalPrice) ?
                                                                            _.map(totalPrice, (priceItem, priceIndex) => {
                                                                                if (parseFloat(priceItem) > 0) {
                                                                                    return (
                                                                                        <div key={priceIndex} className={styles.priceStyle}>
                                                                                            {priceDisplay(priceItem, priceIndex)}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })
                                                                            :
                                                                            <div className={styles.priceStyle}>
                                                                                0.00
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='flex-sb-m' style={{ padding: '16px 15px 18px' }}>
                                                            {
                                                                ableCancel
                                                                    ?
                                                                    <div className="btn-outlined-black w-full" style={{ width: '47%' }} onClick={() => handleCancelOrderOpen(orderNoArray)}>
                                                                        {t('order.cancelOrder')}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <Link underline='none' color="inherit" to={`/payment/${encodeURIComponent(purchaseIndex)}`} component={RouterLink} style={{ width: '47%' }}>
                                                                <div className="btn-theme w-full">
                                                                    {t('button.payNow')}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return null
                                    })
                                        :
                                        <div className='p-t-80'>
                                            <div className='empty-img'>
                                                <img src="/images/empty/order.png" className='w-full' alt="empty" />
                                            </div>
                                            <p className='txt-center fs-content'>{t('order.noOrder')}</p>
                                        </div>
                                }
                                {state.total > 0 &&
                                    <Grid container spacing={1} justify="center" >
                                        <Pagination count={state.totalPage} shape="rounded" page={state.page}
                                            onChange={handlePaginationChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                            </div>
                        </div>
                    </TabPanel>

                    {/* //////////////  TO SHIP  ////////////// */}
                    <TabPanel value={state.tabIndex} index={1} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div>
                            <div className='w-full p-b-30' >
                                {
                                    i18n.language === 'cn' ?
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} className='glass2 bor15 flex-sb-m w-full p-r-6' style={{boxShadow: '0 8px 7px 0 #721c1c0d, inset -1px 2px 6px 0 #0002', borderBottom: '2px solid #ffffff8c', borderLeft: '2px solid #ffffff8c'}} />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} className='glass2 bor15  flex-sb-m w-full p-r-6' style={{boxShadow: '0 8px 7px 0 #721c1c0d, inset -1px 2px 6px 0 #0002', borderBottom: '2px solid #ffffff8c', borderLeft: '2px solid #ffffff8c'}} />
                                }
                            </div>
                            <div>
                                {generateListing(paid)}
                            </div>
                        </div>
                    </TabPanel>

                    {/* //////////////  TO RECEIVED  ////////////// */}
                    <TabPanel value={state.tabIndex} index={2} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div>
                            <div className='w-full p-b-30' >
                                {
                                    i18n.language === 'cn' ?
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonCn }} className='glass2 bor15 flex-sb-m w-full p-r-6' style={{boxShadow: '0 8px 7px 0 #721c1c0d, inset -1px 2px 6px 0 #0002', borderBottom: '2px solid #ffffff8c', borderLeft: '2px solid #ffffff8c'}} />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: trackButtonEn }} className='glass2 bor15  flex-sb-m w-full p-r-6' style={{boxShadow: '0 8px 7px 0 #721c1c0d, inset -1px 2px 6px 0 #0002', borderBottom: '2px solid #ffffff8c', borderLeft: '2px solid #ffffff8c'}} />
                                }
                            </div>
                            <div>
                                {generateListing(delivered)}
                            </div>
                        </div>
                    </TabPanel>

                    {/* //////////////  COMPLETE  ////////////// */}
                    <TabPanel value={state.tabIndex} index={3} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div>
                            {generateListing(completed)}
                        </div>
                    </TabPanel>

                    {/* //////////////  CANCELLED  ////////////// */}
                    <TabPanel value={state.tabIndex} index={4} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div>
                            {generateListing(cancelled)}
                        </div>
                    </TabPanel>

                    {/* //////////////  REFUND  ////////////// */}
                    <TabPanel value={state.tabIndex} index={5} style={{ backgroundColor: 'transparent', marginTop:-20 }}>
                        <div>
                            {generateListing(refunded)}
                        </div>
                    </TabPanel>
                </SwipeableViews>
            </Container>

            <Dialog
                open={state.rateDialog}
                onClose={() => {
                    setState({ ...state, rateDialog: false, rateOrderId: null });
                    setRateOrder();
                    setRate();
                    setShopRate();
                    setAnonymous(false);
                    setShopReview();
                    setReview();
                    setRateError();
                    setReviewError();
                }}
            >
                <DialogTitle id="draggable-dialog-title">
                    <div className='cl-text'>
                        <p className='fs-title'>{t('order.rateProduct')}</p>
                        <p className='fs-subtitle lh-11'>{`${t('order.rateProductDesc')}`}</p>
                    </div>
                    <div className='flex-sb-m w-full p-t-15'>
                        <div>
                            <p className='lh-11 fs-remark cl-label'>{t('order.stayAnonymous')}</p>
                            <p className='lh-11 fs-title clblack'>{anonymous ? `${username[0]}*****` : username}</p>
                        </div>
                        <Switch checked={anonymous} onChange={() => setAnonymous(!anonymous)} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    {
                        _.size(rateOrder) > 0 &&
                            <div>
                                {
                                    _.map(rateOrder.order_details, (orderDetailsItem) => {
                                        return (
                                            <div key={orderDetailsItem.id}>
                                                <div className='p-b-25'>
                                                    <div className='w-full flex-m'>
                                                        <div className='bg-label bor10 p-all-2' style={{lineHeight:0}}>
                                                            <img src={orderDetailsItem.image?orderDetailsItem.image:'/images/empty/no-image.png'} alt="product" style={{ width: 50, height: 50, minWidth:50, objectFit: 'cover' }} className='bor10' />
                                                        </div>
                                                        <div className='p-l-15 txt_truncate'>
                                                            <p className='fs-title  p-r-10'>{orderDetailsItem.title_display}</p>
                                                            <div>
                                                                <Rating
                                                                    name={`rating_${orderDetailsItem.id}`}
                                                                    defaultValue={rate[orderDetailsItem.id]}
                                                                    precision={1}
                                                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                                    onChange={(event, newValue) => {
                                                                        setRate(rate => ({ ...rate, [orderDetailsItem.id]: newValue }))
                                                                        setRateError({...rateError, [orderDetailsItem.id]: false});
                                                                    }}
                                                                />
                                                                {
                                                                    _.has(rateError, orderDetailsItem.id) && rateError[orderDetailsItem.id] ?
                                                                        <p className='fs-remark txt-title cl-error'>{t('order.invalidRate')}</p>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <FormControl fullWidth variant="filled">
                                                            <TextField
                                                                margin="dense"
                                                                variant="filled"
                                                                fullWidth
                                                                // label={t('order.review')}
                                                                placeholder={t('order.leaveReview')}
                                                                type="text"
                                                                inputProps={{ name: 'review', style: { fontSize: 12, borderRadius: 10 } }}
                                                                value={review[orderDetailsItem.id]}
                                                                error={reviewError[orderDetailsItem.id] ? true : false}
                                                                // helperText={reviewError[orderDetailsItem.id] ? reviewError[orderDetailsItem.id] : ''}
                                                                onChange={({ target }) => {
                                                                    // console.log("orderDetailsItem.id", orderDetailsItem.id)
                                                                    setReview({ ...review, [orderDetailsItem.id]: target.value });
                                                                    setCount({...count,'product': target.value.length})
                                                                    setReviewError({...reviewError, [orderDetailsItem.id]: false})
                                                                }}
                                                                multiline
                                                                rows={3}
                                                            />
                                                        </FormControl>
                                                        {count.product > 255 && reviewError[orderDetailsItem.id] ?
                                                            <p className='fs-remark cl-error'>
                                                                {reviewError[orderDetailsItem.id]}
                                                            </p>
                                                            :
                                                            <p className={`txt-right fs-remark p-t-5 ${count.product > 255 ? 'cl-error' : ''} `}>{t('general.maxCharacter')}: <b>{count.product}</b> / 255</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='divider-white' style={{ height: 4 }} />
                                            </div>
                                        )
                                    })
                                }
                                <div className='p-t-25'>
                                    {/* {console.log("shopInfo", shopInfo)} */}
                                    {/* <WebpImg src={rateOrder.order_details[0].merchant_company.} style={{ height: 56, width: 56 }} alt={`${shopInfo[`shop_name_${i18n.language}`]}`}/> */}
                                    <p className='fs-content cl-text'><b>{t('order.rateMerchant')} - {shopInfo[`shop_name_${i18n.language}`]} ?</b></p>
                                    <Rating
                                        name="shop_rating"
                                        defaultValue={shopRate}
                                        value={shopRate}
                                        max={3}
                                        precision={1}
                                        IconContainerComponent={IconContainer}
                                        onChange={(event, newValue) => {
                                            setShopRate(newValue);
                                            setRateError({...rateError, ['shop_rate']: false});
                                        }}
                                    />
                                    {
                                        _.has(rateError, 'shop_rate') && rateError['shop_rate'] &&
                                        <p className='fs-remark txt-title cl-error'>{t('order.invalidRate')}</p>
                                    }
                                    
                                    <FormControl fullWidth>
                                        <TextField
                                            margin="dense"
                                            variant="filled"
                                            fullWidth
                                            // label={t('order.review')}
                                            placeholder={t('order.leaveReview')}
                                            type="text"
                                            inputProps={{ name: 'review', style: { fontSize: 12 } }}
                                            value={shopReview}
                                            error={reviewError['shop_review'] ? true : false}
                                            helperText={reviewError['shop_review'] ? reviewError['shop_review'] : ''}
                                            onChange={({ target }) => {
                                                setShopReview(target.value);
                                                setCount({...count,'shop': target.value.length})
                                                setRateError({...rateError, ['shop_review']: false})
                                            }}
                                            multiline
                                            rows={3}
                                        />
                                    </FormControl>
                                    {count.shop > 255 && _.has(rateError, 'shop_review') && rateError['shop_review'] ?
                                        <p className='fs-remark cl-error'>
                                            {rateError['shop_review']}
                                        </p>
                                        :
                                        <p className={`txt-right fs-remark p-t-5 ${count.shop > 255 ? 'cl-error' : ''} `}>{t('general.maxCharacter')}: <b>{count.shop}</b> / 255</p>}
                                </div>
                            </div>
                    }
                </DialogContent>
                <DialogActions className='w-full flex-m' style={{padding:'10px 20px 20px', justifyContent:'space-between'}}>
                    <div className='btn-outlined-black bor50' style={{minWidth:'48%'}}
                        onClick={() => {
                            setState({ ...state, rateDialog: false, rateOrderId: null });
                            setRateOrder();
                            setRate();
                            setShopRate();
                            setShopReview();
                            setAnonymous(false);
                            setReview();
                            setRateError();
                            setReviewError();
                        }}
                    >
                        <p>{t('button.cancel')}</p>
                    </div>
                    <div className='btn-theme' style={{minWidth:'48%'}} onClick={submitRating}>
                        <p>{t('button.confirm')}</p>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={ cancelOrderDialog.open } onClose={ handleCancelOrderClose } >
                <DialogContent>
                    <p className='fs-header'>{ t('order.cancelOrder') }</p>
                    <p className='fs-content p-b-8'>
                        {t('order.cancelOrderReminder')}
                    </p>
                    <TextField
                        fullWidth={true}
                        variant="outlined"
                        placeholder={t('order.cancelOrderRemark')}
                        multiline
                        value={cancelOrderDialog.remark}
                        onChange={(event) => { setCancelOrderDialog({ ...cancelOrderDialog, remark: event.target.value }) }}
                    />
                </DialogContent>
                <DialogActions>
                    <div className='p-lr-15 p-b-20 p-t-5 w-full'>
                    <div className="btn-theme w-full" onClick={ () => cancelOrder()} >
                        <p className='fs-button'>{ t('order.confirmCancelOrder') }</p>
                    </div>
                    </div>
                </DialogActions>
            </Dialog>
            <Helmet>
                <script src="//www.tracking.my/track-button.js" async></script>
                <script type="text/javascript">
                    {`  function inputTrack() {
                            var num = document.getElementById("TrackNo").value;
                            if(num===""){
                            alert("Please enter tracking number");
                            return;
                            }
                            TrackButton.track({
                                tracking_no: num
                             });
                         }
                    `}
                </script>
            </Helmet>
        </div>
    )
}

const useStyles = makeStyles(theme => ({

    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    cardStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
        borderRadius: 0
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 10
    },
    small: {
        borderRadius: 30,
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginLeft: 5,
        marginRight: 5,
    },
    user: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
    },
    priceStyle: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    totalPriceStyle: {
        fontSize: 30,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        paddingLeft: 5
    },
    buttonStyle: {
        margin: 5,
        borderRadius: 0
    },
    productImg: {
        width: 65,
        height: 65,
        objectFit: 'cover',
        maxHeight: '15vw',
        maxWidth: '15vw !important',
        borderRadius:10,
        minWidth: 65,
        aspectRatio: 1 / 1
    }
}));