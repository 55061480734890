import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { storeWalletBalance } from '@actions';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, InputAdornment, IconButton, Tabs, Tab, AppBar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Slider from "react-slick";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

//ICON
import { Search } from '@material-ui/icons';
import { FiChevronRight } from "react-icons/fi";
import { BiSolidCategory } from "react-icons/bi";

//COMPONENTS & LAYOUTS
import CollectionItemCard from '@components/CollectionItemCard';
import ProductItemCard from '@components/ProductItemCard';
import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';

import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// function NextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <Hidden only='xs'>
//             <div
//                 className={className}
//                 style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
//                 onClick={onClick}
//             />
//         </Hidden>
//     );
// }
// function PrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <Hidden only='xs'>
//             <div
//                 className={className}
//                 style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
//                 onClick={onClick}
//             />
//         </Hidden>
//     );
// }

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    <p>{children}</p>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}
  

export default function Home() {
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const descriptionElementRef = useRef(null);
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { accessToken } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletBalance: state.user.walletBalance,
    }));
    const { currency, currencyDisplay } = useSelector(state => ({
        ...state.general,
        currency: state?.general?.currency ? state.general.currency : process.env.REACT_APP_DEFAULT_CURRENCY,
    }));

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const [recommend, setRecommend] = useState(null);
    const [sellers, setSellers] = useState(null);
    const [categories, setCategories] = useState(null);
    const [collections, setCollections] = useState(null);
    const [slideBanner, setSlideBanner] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState({});
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState({ keyword: '' });

    // ------------- Tabs ---------------
    const [value, setValue] = useState('all');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ------------- APIs ---------------
    useEffect(() => {
        isMountedRef.current = true;
        
        getUrl('featured/sellers')
            .then(response => {
                if (isMountedRef.current) {
                    setSellers(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        
        getUrl('categories', { level: 1 })
            .then(response => {
                if (isMountedRef.current) {
                    setCategories(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        getUrl('collections')
            .then(collectionList => {
                if (isMountedRef.current) {
                    setCollections(collectionList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        getUrl('sliderbanners')
            .then(slideBannerList => {
                if (isMountedRef.current) {
                    setSlideBanner(slideBannerList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('member_announcements').then(res => {
                if (isMountedRef.current && res.status) {
                    setAnnouncements(res.data);
                    setUnreadAnnouncementCount(res.new_count);
                    let show = { 0: false };
                    let today = new Date();

                    _.map(res.data, (row, index) => {
                        if (row.popup && _.size(row['users']) === 0 && today >= new Date(row.popup_sdate) && today <= new Date(row.popup_edate)) show[index] = true;
                    });
                    setViewAnnouncement({ ...viewAnnouncement, ...show });

                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        getUrl('products', { exclude: 'collections', cat: value === 0 ? 'all' : value })
            .then(productList => {
                if (isMountedRef.current) {
                    setRecommend(productList.data.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }, [value]);

    useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [viewAnnouncement]);

    // ------------- Functions ---------------
    const closeAnnouncement = (index) => {
        setViewAnnouncement({ ...viewAnnouncement, [index]: false });
        getUrl(`member_announcements/${announcements[index]['id']}`).then(result => {
            if (result.status === 1) {
                setUnreadAnnouncementCount(unreadCount - 1);
                window.location.reload();
            }
        }).catch((error) => {
        });
    }

    const handleSearch = (event) => {
        history.push(`/category/all?s=${searchKeyword?.keyword}`);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword({ ...searchKeyword, keyword: event.target.value });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            history.push(`/category/all?s=${searchKeyword?.keyword}`);
        }
    };

    // ------------- Components ---------------
    const bannerSlider = () => {
        return (
            <div className='bor15 m-b-20' style={{ boxShadow: '0px 6px 11px 0 #00000026' }}>
                {
                    _.size(slideBanner) > 0 && slideBanner !== null
                        ?
                        <Grid item xs={12}>
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                autoplay={true}
                                bullets={false}
                                className='home'
                                organicArrows={false}
                            >
                                {
                                    _.map(slideBanner, (slideBannerLists) => {
                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'cover', display: 'block', width: '100%' }} />
                                            </div>
                                        )
                                    })
                                }
                            </AutoplaySlider>
                        </Grid>
                        :
                        null
                }
            </div>
        )
    }

    // const starterProduct = () => {
    //     let starter = _.find(collections, function (c) { return c.id === 1; });
    //     if (starter && (_.size(starter.product_ids_array) > 0)) {
    //         return (
    //             <div>
    //                 <p className='fs-title txt-upper p-b-5'>{t('title.membership')}</p>
    //                 <Link underline='none' component={RouterLink} to="/collection/1">
    //                     <div className='w-full pointer translateY'>
    //                         <img src={`/images/banner/membership-${i18n.language}.png`} alt="membership" className='w-full bor15 shadow-glass2' />
    //                     </div>
    //                 </Link>
    //             </div>
    //         )
    //     }
    //     return null;
    // }

    const collectionList = () => {
        if (_.size(collections) > 0) {
            return (
                <div>
                    {
                        _.map(collections, collectionItem => {
                            if (_.size(collectionItem.product_ids_array) > 0) {
                                // if (collectionItem.id !== 1) {
                                    return (
                                        <div key={collectionItem.id} className='p-b-35'>
                                            <div className='w-full flex-sb-m'>
                                                <p className='fs-title'>{collectionItem[`title_${i18n.language}`]}</p>
                                                <Link underline='none' to={`/collection/${collectionItem.id}`} component={RouterLink} className='translateX flex-m'>
                                                    <p className='fs-content p-r-5'>{t('shop.seeAll')}</p>
                                                    <FiChevronRight className='fs-icon-small' />
                                                </Link>
                                            </div>
                                            <Slider
                                                infinite={_.size(collectionItem.product_ids_array) > 1 ? true : false}
                                                speed={500}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                autoplay={_.size(collectionItem.product_ids_array) > 1 ? true : false}
                                                pauseOnHover={true}
                                                bullets={true}
                                                arrows={false}
                                                dots={_.size(collectionItem.product_ids_array) > 1 ? true : false}
                                                // responsive={[
                                                //     { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true } },
                                                //     { breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                //     { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                // ]}
                                                customPaging={(i) => (
                                                    <div className="custom-dot"></div> // You can use <CustomDot /> if you created the CustomDot component
                                                )}
                                                appendDots={(dots) => (
                                                    <div>{dots}</div>
                                                )}
                                                // nextArrow={<NextArrow />}
                                                // prevArrow={<PrevArrow />}
                                            >
                                                {
                                                    _.map(collectionItem?.product_ids_array, (product) => {
                                                        return (
                                                            <Grid item xs={12} key={product.id} className={styles.cardContentRootStyle}>
                                                                <CollectionItemCard product={product} currency={currency} currencyCode={currency_code} />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    )
                                // }
                            }
                        })
                    }
                </div>
            )
        }
        return null;
    }

    const productList = () => {
        return (
            <>
                <div className='w-full flex-sb-m'>
                    <p className='fs-title'>{t('home.dailyRecommend')}</p>
                    <Link underline='none' to={`/category/all`} component={RouterLink} className='translateX flex-m'>
                        <p className='fs-content p-r-5'>{t('shop.seeAll')}</p>
                        <FiChevronRight className='fs-icon-small' />
                    </Link>
                </div>

                <div>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="off"
                        label="scrollable prevent tabs example"
                        id="category"
                        // indicatorColor='transparent'
                        TabIndicatorProps={{
                            style: {
                                display: "none"
                            }
                        }}
                    >
                        <Tab  style={{minHeight:40}} label={t('title.recommend')} value={'all'} {...a11yProps(0)} />
                        {
                            _.map(categories, category => (
                                <Tab key={category?.id} style={{minHeight:40}} label={i18n.language === 'cn' ? category?.name_cn : category?.name_en} value={category?.id} {...a11yProps(category?.id)} />
                            ))
                        }
                    </Tabs>
                </div>

                {
                    _.size(recommend) > 0
                        ?
                        <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                            {
                                _.map(recommend, (product) => {
                                    return (
                                        <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                            <ProductItemCard product={product} currency={currency} currencyCode={currency_code} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        :
                        <p className='txt-center fs-title cl-text'>{t('general.noProduct')}</p>
                }
            </>
        )
    }

    const announcementList = () => {
        return (
            <div>
                {
                    _.size(announcements) ?
                        _.map(announcements, (row, index) => (
                            row.popup && new Date() >= new Date(row.popup_sdate) && new Date() <= new Date(row.popup_edate) ?
                                <Dialog
                                    open={viewAnnouncement[index] ? true : false}
                                    onClose={() => closeAnnouncement(index)}
                                    scroll="body"
                                    aria-labelledby="view Announcement"
                                    aria-describedby="view Announcement detail"
                                    key={index}
                                >
                                    <DialogTitle id={`scroll-dialog-title${index}`}>{row[`title_${i18n.language}`]}</DialogTitle>
                                    <DialogContent dividers={false}>
                                        <div
                                            id="viewAnnouncement"
                                            ref={descriptionElementRef}
                                            tabIndex={-1}

                                        >
                                            {row['image'] ?
                                                <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={row['image']['file_name']} style={{ width: '100%', padding: '0', display: 'block', borderRadius: 15, objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                                : null
                                            }
                                            <div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => closeAnnouncement(index)} color="primary">{t("button.close")}</Button>
                                    </DialogActions>
                                </Dialog>
                                : null
                        ))
                        : null
                }
            </div>
        )
    }

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        initialSlide: 0,
        swipe: true,
        touchMove: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ],
    };    

    return (
        <div>
            <TitleBar currencyButton displayCart logoIcon />
            <div>
                <Container>
                    <div className='p-tb-24'>
                        <div className='w-full box-textfield'>
                        <TextField
                            value={searchKeyword?.keyword}
                            fullWidth
                            placeholder={t('home.search')}
                            className={styles.textFieldRoot}
                            onChange={handleSearchChange}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <IconButton style={{ padding: 0, width: 30, height: 30, aspectRatio: 1 / 1 }} onClick={handleSearch}>
                                        <InputAdornment position="end" className='w-full'>
                                            <Search />
                                        </InputAdornment>
                                    </IconButton>
                                ),
                            }}
                        />
                        </div>
                    </div>
                    <div className='w-full flex-col' style={{ gap: 24 }}>
                        {bannerSlider()}
                        {_.size(sellers) > 0 &&
                            <div>
                                <div className='w-full fs-title flex-sb-m'>
                                    <p>{t('title.sellers')}</p>
                                    <Link underline='none' to={`/seller/all`} component={RouterLink} className='translateX flex-m'>
                                        <p className='fs-content p-r-5' style={{ fontWeight: 400 }}>{t('shop.seeAll')}</p>
                                        <FiChevronRight className='fs-icon-small' />
                                    </Link>
                                </div>
                                <div className='w-full p-tb-20'>
                                    <Slider {...settings}>
                                        {
                                            _.map(sellers, seller => (
                                                <Link key={seller?.id} underline='none' to={`/shop/${seller?.slug}`} component={RouterLink}>
                                                    <div className='bor15 shadow-merchant pointer translateY' 
                                                        style={{ width: 85, height: 85, lineHeight: 0 , aspectRatio: '1/1', objectFit: 'cover', marginRight: '20px', marginTop: 10 }}>
                                                        <WebpImg 
                                                            src={_.size(seller?.merchant_company_icon) > 0 ? seller?.merchant_company_icon?.file_name : '/images/empty/no-image-theme.png'} 
                                                            alt="merchant" 
                                                            className='bor15 pointer' 
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        />
                                                    </div>
                                                </Link>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>
                        }
                        {collectionList()}
                        {productList()}
                        {announcementList()}
                    </div>
                </Container>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        maxWidth: '100%'
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
    textFieldRoot: {
        '& .MuiFilledInput-root': {
            padding: '6px 12px',
            background: '#F6F7F9',
        },
        '& .MuiFilledInput-input': {
            padding: '6px 12px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px',
        }
    },
}));