import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Typography, Grid, Box, List, Container, Snackbar,Card ,CardContent } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '@helper/ApiAction';

import TitleBar from '@layouts/TitleBar';

import useNotificationLoading from '@helper/useNotificationLoading';

const INITIAL_PASSWORD_STATE = { old_password: '', password: '', password_confirmation: '' };

export default function ProfilePassword() {
    const { t } = useTranslation();

    const { setLoading } = useNotificationLoading();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [passwordFormState, setPasswordFormState] = useState(INITIAL_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    })
    
    const styles = useStyles();

    const handlePasswordChange = (event) => {
        const name = event.target.name;
        setPasswordFormState({ ...passwordFormState, [name]: event.target.value });
    }


    const updatePassword = () => {
        setLoading(true);
        setErrorFormState({});
        const { old_password, password, password_confirmation } = passwordFormState;
        if (!old_password || !password || !password_confirmation) {
            setLoading(false);
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/password', passwordFormState).then(result => {
                if (result.error) {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: result.message }));
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
                    setPasswordFormState(INITIAL_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'><b>{t('profile.changePassword')}</b></p>
                    <p className='fs-subheader cl-label'>{t('profile.passwordSubtitle')}</p>
                </div>
            </div>
            <Container>   
                <div>
                    <div className='p-t-25'>
                        <p className='fs-textfield'>{t('profile.currentPassword')}</p>
                        <TextField
                            error={errorFormState.old_password ? true : false}
                            helperText={errorFormState.old_password}
                            fullWidth={true}
                            variant="filled"
                            size="small"
                            inputProps={{ name: 'old_password' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.old_password}
                        // placeholder={t('profile.bankName')}
                        />
                    </div>

                    <div className='p-t-25'>
                        <p className='fs-textfield'>{t('profile.newPassword')}</p>
                        <TextField
                            error={errorFormState.password ? true : false}
                            helperText={errorFormState.password}
                            fullWidth={true}
                            variant="filled"
                            size="small"
                            type="password"
                            inputProps={{ name: 'password' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.password}
                        />
                    </div>
                    <div className='p-t-25'>
                        <p className='fs-textfield'>{t('profile.confirmPassword')}</p>
                        <TextField
                            error={errorFormState.password_confirmation ? true : false}
                            helperText={errorFormState.password_confirmation}
                            fullWidth={true}
                            variant="filled"
                            size="small"
                            type="password"
                            inputProps={{ name: 'password_confirmation' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.password_confirmation}
                        />
                    </div>

                    <div className='w-full p-t-50'>
                        <div className='btn-theme w-full translateY' onClick={updatePassword}>
                            <p className='fs-button'>{t('button.confirm')}</p>
                        </div>
                    </div>
                </div>

                <Snackbar
                    open={state.snackbarShow}
                    autoHideDuration={2000}
                    onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                        <CardContent style={{ padding: 10 }}>
                            <Typography style={{ color: 'white' }}>{state.message}</Typography>
                        </CardContent>
                    </Card>
                </Snackbar>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    textFieldTitle:{
        color:theme.palette.gray.dtext,
        fontSize:16,
        paddingBottom:5,
        paddingLeft:10
    }
}));