import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '../../helper/useNotificationLoading';
import ImageGallery from 'react-image-gallery';
import WebpImg from '@layouts/WebpImg';

import _ from 'lodash';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box, Avatar, Container } from '@material-ui/core';
import { Rating, Pagination, PaginationItem } from '@material-ui/lab';

//ICON
import { TiStarFullOutline } from "react-icons/ti";
import PersonIcon from '@material-ui/icons/Person';

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

export default function Review(productId) {
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [product, setProduct] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);

    let { id } = useParams();
    const { t , i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const galleryRef = useRef(null);

    const { addAlert, setLoading , loading } = useNotificationLoading();

    id = id ? id : productId;

    // first time loading only
    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}/summary`)
            .then(reviewSummary => {
                setProductReviewSummary(reviewSummary.data);
                setProduct(reviewSummary.product)
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, setLoading, addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}?sort=${reviewSort}&page=${page}`)
            .then(reviewData => {
                console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setLastPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, reviewSort, page, setLoading, addAlert])

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const renderVideo = (item) => {
        return (
            <div className='video-wrapper'>
                <div className="iframe-container" style={{ paddingTop: item.embedUrl.toLowerCase().endsWith('.mp4') ? 0 : '56.25%' }}>
                    {
                        item.embedUrl.toLowerCase().endsWith('.mp4')
                            ? <video className='w-full' controls>
                                <source src={item.embedUrl} type="video/mp4" />
                            </video>
                            : <iframe
                                className="responsive-iframe"
                                src={item.embedUrl}
                                frameBorder='0'
                                allowFullScreen
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            >
                            </iframe>
                    }
                </div>
            </div>
        );
    }

    const generateImageSlide = ({ images_array, video, embed_video }) => {
        let imgList = [];
        
        if(_.size(video) > 0 || _.size(embed_video) > 0) {
            if(_.size(embed_video) > 0) video = embed_video;
            const defaultImage = _.size(images_array) > 0 ? images_array[0] : "/images/empty/no-image.png";
            const videoProps = {
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
            }
            imgList.push({ 
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
                renderItem: () => renderVideo(videoProps),
            });
        }

        _.map(images_array, (url) => {
            imgList.push({ original: url, thumbnail: url });
        });
        console.log(imgList)
        return imgList;
    }

    const handleImageClick = (event) => {
        if (galleryRef.current) {
            if (isFullscreen) {
                galleryRef.current.exitFullScreen();
            } else {
                galleryRef.current.fullScreen();
            }
            setIsFullscreen(!isFullscreen);
        }
    };

    const productImage = () => {
        return (
            _.size(product?.images_array) > 0 && product?.images_array
                ? <ImageGallery
                    ref={galleryRef}
                    items={generateImageSlide(product)}
                    autoPlay={false}
                    onErrorImageURL="/images/empty/no-image.png"
                    renderItem={(item) => {
                        return <WebpImg className={`image-gallery-image`} src={item.original} />
                    }}
                    renderThumbInner={(item) => {
                        return <WebpImg className={`image-gallery-image`} src={item.original} />
                    }}
                    showBullets={_.size(product?.images_array) > 1 ? true : false}
                    showFullscreenButton={false}
                    showThumbnails={false}
                    showPlayButton={false}
                    onClick={handleImageClick}
                />
                : 
                <div style={{ width: 150, aspectRatio: 1 / 1, borderRadius: 15, margin: '0 auto' }}>
                    <img src='/images/empty/no-image.png' alt="product" className='w-full' />
                </div>
        )
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-header" />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                <div className='subtitle-box cl-text p-b-24'>
                    <p className='fs-header'><b>{t('product.review')}</b></p>
                    <p className='fs-subheader lh-11'>{t('product.reviewSubtitle')}</p>
                </div>
                <div className='w-full p-b-24'>
                    {/* <div > */}
                        <style>
                            {
                                `
                                .image-gallery-right-nav{
                                    right: 0px;
                                }
                                .image-gallery-left-nav {
                                    left: 0px;
                                }
                                .image-gallery-bullets .image-gallery-bullet.active, .image-gallery-bullets .image-gallery-bullet:hover  {
                                    background: ${product?.display_code?.default ? '#18267C' : product?.display_code?.background_color};
                                }
                                .image-gallery-bullets .image-gallery-bullet, .image-gallery-bullets .image-gallery-bullet:hover  {
                                    border: 2px solid ${product?.display_code?.default ? '#18267C' : product?.display_code?.background_color};
                                }
                                .image-gallery-slides {
                                    height: ${isFullscreen ? 500 : 300}px;
                                }
                                .image-gallery-content .image-gallery-slide .image-gallery-image {
                                    width: ${isFullscreen ? '100%' : '250px'};
                                    object-fit: ${isFullscreen ? 'contain' : 'cover'};
                                    border-radius:${isFullscreen ? '0' : '15px'}
                                }
                                `
                            }
                        </style>
                        { productImage() }
                    {/* </div> */}
                    <div className='flex-c-m w-full p-t-20'>
                        <div className='flex-m'>
                            <p className='fs-title p-r-8 cl-theme'>{productReviewSummary.average_rating}</p>
                            <Rating name="half-rating-read" style={{ fontSize: '1rem' }} value={productReviewSummary.average_rating} defaultValue={productReviewSummary.average_rating} precision={0.1} readOnly />
                        </div>

                        <p className='p-lr-15 fs-content cl-label'>|</p>
                        <p className='fs-content cl-text'>
                            <span className='fs-title cl-theme'>{productReviewSummary.all_count}</span> {t('item.rating')}
                        </p>
                    </div>
                    <p className='fs-title cl-theme txt-center p-t-8'>{i18n.language === 'cn' ? product?.title_cn : product?.title_en}</p>
                </div>

                {/* //Filter */}
                <div className='p-t-15'>
                    {/* // with or without Comment Filter */}
                    <div className='w-full flex-sb-m p-b-15'>
                        {
                            _.map(['all', 'comment'], (sortItem, key) => {
                                return (
                                    <div
                                        key={key}
                                        color={reviewSort === sortItem ? 'primary' : 'default'}
                                        onClick={() => {
                                            setPage(1)
                                            setReviewSort(sortItem)
                                        }}
                                        className="btn-outlined-theme bor10"
                                        style={{ borderColor: reviewSort === sortItem ? theme.palette.secondary.main : '#dadada', width: '47%' }}
                                    >
                                        <Box display="flex" justifyContent='center' flexDirection="column">
                                            <p className='fs-remark txt-upper'>{t(`item.${sortItem}`)}</p>
                                            {
                                                sortItem === 'all' ?
                                                    <p className='fs-remark txt-upper txt-center'>{`(${productReviewSummary.all_count})`}</p>
                                                    : null
                                            }
                                            {
                                                sortItem === 'comment' ?
                                                    <p className='fs-remark txt-upper txt-center'>{`(${productReviewSummary.comment_count})`}</p>
                                                    : null
                                            }

                                        </Box>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* // Star Button */}
                    <div className='flex-sb-m w-full'>
                        {
                            _.map([5, 4, 3, 2, 1], starSort => {
                                return (
                                    <div
                                        key={`${starSort}star`}
                                        color={reviewSort === `${starSort}star` ? 'primary' : 'default'}
                                        className="btn-outlined-theme bor10"
                                        style={{ borderColor: reviewSort === `${starSort}star` ? theme.palette.secondary.main : '#dadada', width: '19%', padding: 5 }}
                                        onClick={() => {
                                            setPage(1)
                                            setReviewSort(`${starSort}star`)
                                        }}
                                    >
                                        <div>
                                            <div className='w-full flex-c-m'>
                                                {_.map(_.range(starSort), starRangeItem => {
                                                    return (
                                                        <TiStarFullOutline key={`star_range_${starRangeItem}`} className='cl-theme fs-remark' style={{ margin: '0 0.5%' }} />
                                                    )
                                                })}
                                            </div>
                                            <Typography className={styles.sortingTitle}>{`(${productReviewSummary.star_count[starSort]})`}</Typography>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                {
                    _.size(review) > 0 ?
                        <div className='p-t-25'>
                            {
                                _.map(review, reviewItem => {
                                    return (
                                        <div key={reviewItem.id} className='p-b-20'>
                                            <div className='glass2 shadow-glass2 bor15 p-all-15'>
                                                <div className='flex-m'>
                                                    <Avatar style={{ height: 56, width: 56, marginRight: 10 }}>
                                                        <PersonIcon className='fs-icon' />
                                                    </Avatar>
                                                    <div>
                                                        <p className='fs-title'>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</p>
                                                        <Rating value={reviewItem.rating} defaultValue={reviewItem.rating} precision={0.1} readOnly />
                                                    </div>
                                                </div>
                                                <div className='p-t-20 p-b-10'>
                                                    <p className='fs-content cl-text'>
                                                        {reviewItem.comment}
                                                    </p>
                                                </div>
                                                {
                                                    _.size(reviewItem.response) > 0 ?
                                                        <Box padding={2} borderRadius={10} marginBottom={1} style={{ backgroundColor: '#f1f1f1' }}>
                                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                <Typography className={styles.merchantReplyTitle}>{t('item.merchantReply')}</Typography>
                                                                <Typography className={styles.merchantReplyTitle}>{reviewItem.updated_date_display}</Typography>
                                                            </Box>
                                                            <Typography style={{ fontSize: 13 }}>
                                                                {reviewItem.response}
                                                            </Typography>
                                                        </Box>
                                                        : null
                                                }
                                                <p className='fs-content cl-text'>{reviewItem.created_date_display}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <Box display="flex" justifyContent="center">
                                <Pagination count={lastPage} shape="rounded" page={page}
                                    onChange={handlePageChange}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                        />
                                    )}
                                />
                            </Box>
                        </div>
                        :
                        <div className='p-t-30'>
                            <div className='empty-img'>
                                <img src="/images/empty/announce.png" className='w-full' alt="empty" />
                            </div>
                            <p className='fs-empty'>{t('item.noRatingReview')}</p>
                        </div>

                }
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        color: '#525252',
        margin: 0,
        paddingRight: 7,
        fontSize: 14,
        lineHeight: 2,
        textTransform: 'uppercase'
    },
    subTitle: {
        margin: 0,
        paddingRight: 7,
        fontSize: 18,
        lineHeight: 1.5
    },
    seperator: {
        margin: '0 10px',
        fontSize: 18,
        lineHeight: 1.5,
        color: '#cecece'
    },
    sortingTitle: {
        margin: 0,
        textAlign: 'center',
        fontSize: 11,
        whiteSpace: 'nowrap'
    },
    buttonStyle: {
        width: '90%',
        backgroundColor: '#efefef',
        margin: '0px 10px',
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },
    merchantReplyTitle: {
        fontSize: 13,
        margin: 0,
        fontWeight: 'bold'
    }
}));