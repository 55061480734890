import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useQuery } from '@helper/Tools';
import { useDispatch } from 'react-redux';
import { authSuccess, authFail } from '@actions';
import { postUrl } from '@helper/ApiAction';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '@helper/useNotificationLoading';
import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import { Base64 } from 'js-base64';
import jwt_decode from "jwt-decode";
import _ from 'lodash';

import { Button, Box } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export default function Callback() {

    const query = useQuery();
    const dispatch = useDispatch();
    let history = useHistory();
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();

    const status = query.get("status");
    const loginMethod = query.get("lm");
    const source = query.get("src");
    const hashValue = query.get("hash");
    const path = query.get("p");
    const errorCode = query.get("error_code");
    const userId = query.get("uid");
    const userName = query.get("name");
    const userUsername = query.get("username");
    const userMobile = query.get("mobile");
    const userEmail = query.get("email");

    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        if(status === '1') {
            const data = {
                status: status,
                login_method: loginMethod,
                hash: hashValue,
            }
            postUrl(`login/${source}`, data).then(response => {
                loginUser(response.data.username, response.data.login_key);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    let errorResponse = JSON.parse(error.request.response);
                    addAlert(errorResponse.message);
                } else {
                    addAlert(JSON.stringify(error.message));
                }
                dispatch(authFail());
                history.push("/login");
            });
        } else {
            if(errorCode == 'L002'){
                if(userEmail){
                    // check if got mistore account with same email
                    let params = {
                        source: source,
                        source_user_id: userId,
                        source_username: userUsername,
                        email: userEmail,
                    }
                    postUrl('account-link/email-verify', params).then(response => {
                        if(response.status){
                            // already link to account with same email, auto login
                            loginUser(response.username, response.login_key);
                        }else{
                            if(response.message){
                                // link error
                                addAlert(JSON.stringify(response.message));
                            }else{
                                // no account can link, go to register page
                                history.push(`/register?src=${source}&uid=${userId}&name=${userName}&username=${userUsername}&mobile=${userMobile}&email=${userEmail}`);
                            }
                        }
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }else{
                    history.push(`/register?src=${source}&uid=${userId}&name=${userName}&username=${userUsername}&mobile=${userMobile}&email=${userEmail}`);
                }
            }else{
                setDialogOpen(true);
            }
            // dispatch(authFail());
            // history.push("/login");
        }
        return () => { isMountedRef.current = false };
    }, [status, loginMethod, source, hashValue, path, history, errorCode, userId, userName, userUsername, userMobile, userEmail, dispatch, t, addAlert]);

    const loginUser = (username, password) => {
        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: username,
            password: password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            if(response.status === 200) {
                const decoded_jwt = jwt_decode(response.data.access_token);
                const userRole = decoded_jwt ? decoded_jwt.role : [];
                if(_.size(_.intersection(userRole, ['member']))){
                    dispatch(authSuccess(response.data));
                    if(path){
                        let redirectPath = Base64.decode(path);
                        history.push(redirectPath);
                    }else{
                        history.push("/");
                    }
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                }
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
            }            
            dispatch(authFail());         
        }); 
    }

    return (
        <Box style={{padding:25}}>
            <div>
                Loading...
            </div>
            <Dialog open={dialogOpen} >
                <DialogTitle id="scroll-dialog-title">{t('general.loginError')}</DialogTitle>
                <DialogContent dividers={false}>{ errorCode === 'L002' ? t('accountLink.loginErrorL002', {source: source, app: process.env.REACT_APP_APP_NAME}) : t('accountLink.loginError', {error_code: errorCode})}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => history.push("/")} color="primary">{t("accountLink.goToHomePage")}</Button>
                    <Button variant="contained" onClick={() => history.push("/login")} color="primary">{t("accountLink.goToLoginPage")}</Button>
                    {/* <Button variant="contained" onClick={() => history.push("/register")} color="primary">{t("accountLink.goToRegisterPage")}</Button> */}
                </DialogActions>
            </Dialog>
        </Box>
    );
}