import axios from 'axios';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { authFail, authSuccess, changeLanguage as reduxChangeLanguage } from '@actions';
import { getUrl, logoutUser } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// ICON
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// COMPONENTS & LAYOUTS
import WebpImg from '@layouts/WebpImg';
import TitleBar from '@layouts/TitleBar';

export default function Login() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { username } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const styles = useStyles();
    const query = useQuery();
    const hash = query.get('H');
    const src = query.get('Src');
    const frm = query.get('Frm')??'';

    useEffect(() => {
        setLoading(true);
        if(hash){
            let params = {
                'src' : src,
                'hash' : hash
            }
            getUrl(`get-src-detail`,params).then(response =>{
                if(response.status == true){
                    setLoading(false);
                    if (!accessToken) {
                        loginUser(response.data, hash);
                    } else if (frm != '' && frm == 'redirect') {
                        loginUser(response.data, hash);
                    }
                    let redirect_path = query.get('P');
                    let paths = query.get('P') ? query.get('P').split("/") : [];
                    if (_.size(paths) > 0 && paths[0] === 'payment') {
                        redirect_path = paths[0] + '/' + encodeURIComponent(paths[1]);
                    }
                    history.replace(`/${redirect_path ? redirect_path : ''}`);
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                    history.replace('/');
                }
            }).catch((error) =>{
                addAlert(JSON.stringify(error.message));
            })
        }
        
    }, [hash, frm]);

    const loginUser = (username, password) =>{
        setLoading(true);
        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: username,
            password: password,
            scope: '*',
        }
        if (username && password){
            axios.defaults.headers.common['Accept'] = 'application/json';
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/oauth/token`, loginState)
                .then((response) => {
                    // setLoading(false);
                    setLoading(false);
                    if (response.status === 200) {
                        const decoded_jwt = jwt_decode(response.data.access_token);
                        const userRole = decoded_jwt ? decoded_jwt.role : [];
                        if (_.size(_.intersection(userRole, ['member']))) {
                            dispatch(authSuccess(response.data));
                        } else {
                            addAlert(t('general.incorrectUsernamePassword'));
                        }
                    }
                }).catch((error) => {
                    console.log("error", error);
                    setLoading(false);
                    if (error.response && error.response.status === 400) {
                        addAlert(t('general.incorrectUsernamePassword'));
                        // console.log('The username or password is incorrect');
                    } else if (error.response && error.response.status === 401) {
                        let errorResponse = JSON.parse(error.request.response);
                        addAlert(errorResponse.message);
                    } else {
                        addAlert(JSON.stringify(error.message));
                        // console.log('Please try again later or contact Technical Support if problem persist.');
                    }
                    dispatch(authFail());
                    // setLoading(false);            
                });
        }
        else
        {
            setLoading(false);
            addAlert(t('general.incorrectUsernamePassword'));
        }
    }


    return (
        <div className='min-h-100vh'>
        {/* <TitleBar back backgroundColor="bg-theme" />
            <div className='bg-theme p-b-30 header-radius'>
                <div className='p-t-30'>
                    <center><WebpImg src="/images/logo/logo-white.png" style={{ width: 100, maxWidth: '50%' }} alt="logo" /></center>
                    <p className='txt-center txt-upper fs-header clwhite p-t-15'>{t('title.login')}</p>
                    <p className='txt-center txt-upper fs-header clwhite p-t-15'>{hash}</p>
                </div>
            </div> */}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
}));