import { storeCodeState, resetCodeState, resetProfile } from '@actions';
import { generateRandomString } from './Tools';
import { API } from '@configs/AxiosConfig';
import { store } from '@configs/Store';

import sha256 from 'crypto-js/sha256';
import base64 from 'crypto-js/enc-base64';

export const loginUser = (dispatch) => {
    const generatedLoginState = generateRandomString(40);
    const generatedCodeVerifier = generateRandomString(128);

    dispatch(storeCodeState(generatedCodeVerifier, generatedLoginState));

    const a = sha256(generatedCodeVerifier).toString(base64);
    const b = a.replace(/=/g, '');
    let codeChallenge = b.replace(/\+/g, '-');
    codeChallenge = codeChallenge.replace(/\//g, '_');

    const params = {
        client_id: 3,
        redirect_uri: `${process.env.REACT_APP_LOCAL_URL}/Callback`,
        response_type: 'code',
        scope: 'profile get-orders place-orders',
        state: generatedLoginState,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256'
    }
    const paramString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth/authorize?${paramString}`;
}

export const removeLoginAccess = () => {
    store.dispatch(resetCodeState());
    store.dispatch(resetProfile());
}

export const logoutUser = () => {
    API.post(`logout`)
    .then((response) => {
        console.log(response);
        if(response.status === 200) {
            removeLoginAccess();
        }
    }).catch((error) => {
        console.log(error);
        throw new Error(error);
    });
}

export const getUrl = async (url, param) => {
    let result = [];
    await API.get(url, {params: param})
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log('error', error);
        if(error.request.status === 401){
            removeLoginAccess();
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const postUrl = async (url, postData) => {
    let result = [];
    await API.post(url, postData)
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        if(error.request.status === 401){
            removeLoginAccess();
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const putUrl = async (url, putData) => {
    let result = [];
    await API.put(url, putData)
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        if(error.request.status === 401){
            removeLoginAccess();
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const deleteUrl = async (url) => {
    let result = [];
    await API.delete(url)
    .then((response) => {
        console.log(response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        if(error.request.status === 401){
            removeLoginAccess();
        }else{
            throw new Error(error);
        }
    });
    return result;
}