import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Box, Typography, Container, Grid, Link, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Pagination, PaginationItem } from '@material-ui/lab';
import { getUrl } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';

export default function Sellers() {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    let query = useQuery();
    let key = useQuery();
    const pageNumber = query.get("page");
    const keyword = key.get("s");
    const history = useHistory();
    
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [sellers, setSellers] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);

    // ----------------- APIs ------------------------
    useEffect(() => {
        isMountedRef.current = true;

        getUrl('get_merchants').then(response => {
                if (isMountedRef.current) {
                    setSellers(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const loadProducts = () => {
        setPage(page + 1);
    }

    return (
        <div>
            <TitleBar backgroundColor="bg-header" back displayCart currencyButton />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                <div className='cl-text txt-center p-b-24'>
                    <p className='fs-header'>{t('title.sellers')} ({sellers.length})</p>
                    <p className='fs-subheader'>{t('item.sellersSubtitle')}</p>
                </div>
                    
                {
                    sellers.length > 0
                        ? <div>
                            <Grid container spacing={3}>
                                {
                                    _.map(sellers, seller => (
                                        <Grid 
                                            item 
                                            xs={4}
                                            // sm={4}
                                            // lg={4}
                                            key={seller.id} 
                                            // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            <Link key={seller?.id} underline='none' to={`/shop/${seller?.slug}`} component={RouterLink} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <div className='bor15 shadow-merchant pointer translateY' 
                                                    style={{ width: 85, height: 85, lineHeight: 0, aspectRatio: '1/1', objectFit: 'cover' }}>
                                                    <WebpImg 
                                                        src={_.size(seller.merchant_company_icon) > 0 ? seller.merchant_company_icon.file_name : '/images/empty/no-image-theme.png'} 
                                                        alt="merchant" 
                                                        className='bor15 pointer' 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                </div>
                                                <Typography variant='subtitle2' className={styles.sellerName}>
                                                    {seller?.['shop_name_'+i18n.language] ? seller?.['shop_name_'+i18n.language] : seller.shop_name_en}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    ))
                                }
                            </Grid>

                            {/* <div className='p-t-30'>
                                {
                                    page < total
                                        ? <Button onClick={loadProducts} fullWidth>{t('transaction.loadMore')}</Button>
                                        : <Typography variant='body2' className='txt-center'>{t('transaction.endOfList')}</Typography>
                                }
                            </div> */}
                        </div>
                        : <div className='txt-center'>
                            <img src='/images/empty/product.png' alt="noimage" style={{ width: 120 }} />
                            <p className='txt-center fs-title cl-text'>{t('general.noSeller')}</p>
                        </div>
                }
            </Container>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    sellerName: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        wordBreak: 'break-word',
        display: '-webkit-box',
        lineClamp: '2',
        boxOrient: 'vertical',
        overflow: 'hidden',
    },
    cardContentRootStyle: {
        padding: '10px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));
