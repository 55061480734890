import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Button } from '@material-ui/core';
// import { Pagination, PaginationItem } from '@material-ui/lab';

import { getUrl } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

import TitleBar from '@layouts/TitleBar';
import ProductItemCard from '@components/ProductItemCard';
import CollectionItemCard from '@components/CollectionItemCard';

export default function Categories() {
    const styles = useStyles();
    // const theme = useTheme();
    const history = useHistory();
    const isMountedRef = useRef(null);

    let { id } = useParams();
    let query = useQuery();
    const pageNumber = query.get("page");
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { currency, currencyDisplay } = useSelector(state => ({
        ...state.general,
        currency: state?.general?.currency ? state.general.currency : process.env.REACT_APP_DEFAULT_CURRENCY,
    }));

    const [collections, setCollections] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    // const [category, setCategory] = useState('all');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);

    // ---------------- APIs ---------------
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (Number(id)) {
            getUrl(`collections/${id}/products?cat=all&page=${pageNumber ? pageNumber : 1}`).then(response => {
                if (response.status) {
                    let { current_page, last_page } = response.data.meta;
                    setCategoryProducts(_.concat(categoryProducts, response.data.data));
                    setTotal(last_page);
                    setPage(current_page ? current_page : 1);
                    setCollections(response.collection);
                } else {
                    history.replace('/');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
            history.replace({ pathname: "/" });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, pageNumber, addAlert]);

    // ---------------- Functions ---------------
    // const handleChange = (event, value) => {
    //     setPage(value);
    //     getUrl(`collections/${id}/products?cat=${category}&page=${value}`)
    //         .then(response => {
    //             if (response) {
    //                 let { last_page } = response.data;
    //                 setCategoryProducts(response.data.data);
    //                 setTotal(last_page);
    //             }
    //         }).catch(error => {
    //             addAlert(JSON.stringify(error.message));
    //         });
    // };

    const loadProducts = () => {
        setPage(page + 1);
    }

    // ---------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay,(currency_detail)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
    });

    return (
        <div className='min-h-100vh'>
            <TitleBar backgroundColor="bg-header" back displayCart currencyButton />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                    <div className='txt-center cl-text'>
                        <p className='fs-header'>{collections[`title_${i18n.language}`]}</p>
                    </div>
                    <div>
                        {
                            categoryProducts.length > 0 ?
                                <div>
                                    <Grid container spacing={1}>
                                        {
                                            _.map(categoryProducts, (product) => {
                                                let showRetail = false;
                                                let discountPercent = 0;
                                                if (parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(product.sell_price)) {
                                                    showRetail = true;
                                                    discountPercent = ((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(product.retail_price)) * 100;
                                                    discountPercent = Math.round(discountPercent);
                                                }
                                                const title_translate = _.split(product.title, '|');
                                                return (
                                                    <Grid item xs={6} key={product.id} className={styles.cardContentRootStyle} style={{ height: '100%' }}>
                                                        <ProductItemCard product={product} currency={currency} currencyCode={currency_code} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>

                                    {/* <Grid container spacing={1} justify="center" >
                                        <Pagination count={total} shape="rounded" page={page}
                                            onChange={handleChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component={RouterLink}
                                                    to={`/collection/${id}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Grid> */}

                                    <div>
                                        {
                                            page < total ?
                                                <Button onClick={loadProducts} fullWidth>{t('transaction.loadMore')}</Button>
                                                : 
                                            <div className='p-tb-24 w-full flex-sb-m'>
                                                <div className='divider-white p-tb-2 w-full' />
                                                <p className='fs-content cl-text p-lr-15' style={{whiteSpace:'nowrap'}}>{t('transaction.endOfList')}</p>
                                                <div className='divider-white p-tb-2 w-full' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                : 
                                <div className='p-t-30'>
                                    <div style={{ margin: '0 auto', width: 150 }}>
                                        <img src='/images/empty/product.png' alt="empty" />
                                    </div>
                                    <p className='txt-center fs-title cl-text p-t-15'>{t('general.noProduct')}</p>
                                </div>
                        }
                        
                    </div>
                </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
        display: 'flex',
        alignItems: 'flex-end',
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    }
}));
