import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { postUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Link, Box, Container, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import TitleBar from '@layouts/TitleBar';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { process.env.REACT_APP_APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordForgot() {

    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [email, setEmail] = useState('');

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const forgotPassword = () => {
        setLoading(true);

        postUrl(`forgot_password`, { email: email }).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 1) {
                    addAlert(message, 'success', '', '');
                } else {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n" + value[0];
                        })
                        setInputErrors(error);
                    }
                    addAlert(message, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <div className='min-h-100vh'>
            <TitleBar back />
            <Container>
                <div className='p-t-40' style={{width:80, margin:'0 auto'}}>
                    <img src="/images/logo/logo.png" className='w-full' alt="logo" />
                </div>
                <div className='txt-center p-t-15'>
                    <p className='fs-header cl-theme'>{t('forgotPassword.title')}</p>
                    <p className='fs-subheader'>{t('forgotPassword.subtitle')}</p>
                </div>
                
                <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); forgotPassword() }}>
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('forgotPassword.email')}
                        name="email"
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                        autoComplete="email"
                        autoFocus
                        error={inputErrors.email ? true : false}
                    />
                    <p className='fs-content p-l-20'>
                        {t('forgotPassword.helperText')}
                    </p>
                    {inputErrors.email &&
                        <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.email}</Typography>
                    }
                    <div className='p-t-30'>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{borderRadius: 48 }}
                        >
                            {t('button.reset')}
                        </Button>
                    </div>
                    <div className='p-t-30'><Copyright /></div>
                </form>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));