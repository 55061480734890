import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getUrl, postUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';
import WebpImg from '@layouts/WebpImg';

import { Box, Button, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

import TitleBar from '@layouts/TitleBar';

const IPAY88_AVAILABLE = false;
const REVENUEMONSTER_AVAILABLE = false;
const BONUSCHAINS_AVAILABLE = true;

export default function Payment() {
    const { i18n, t } = useTranslation();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let history = useHistory();

    let { oid } = useParams();

    const [order, setOrder] = useState([]);
    const [totalPrice, setTotalPrice] = useState({}); // product fee
    const [currencyData, setCurrencyData] = useState(null);
    const [totalShippingPrice, setTotalShippingPrice] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const [wallets, setWallets] = useState([]);
    const [validBalance, setValidBalance] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState();
    const [totalPayment, setTotalPayment] = useState([]); // include shipping fee
    const [sourcePayment, setSourcePayment] = useState([]);
    const [sourceConnect, setSourceConnect] = useState([]);
    const [refetchData, setRefetchData] = useState(false);

    const ipay88PaymentForm = useRef();

    const [ipay88Url, setIpay88Url] = useState("");
    const [ipay88PaymentInput, setIpay88PaymentInput] = useState([]);

    const { currency, currencyRate, currencyDisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }

    });

    useEffect(() => {
        getUrl('wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, []);

    useEffect(() => {
        let tempWallet = {};
        let tempSourcePayment = {};
        _.map(totalPrice, (walletTotal, walletType) => {
            if (walletType !== 'cash') {
                let wallet = _.find(wallets, function (w) { return w.id === parseInt(walletType); });
                if (wallet?.source && (wallet?.source !== 'local')) {
                    if (_.isUndefined(tempSourcePayment[wallet.source])) {
                        tempSourcePayment[wallet.source] = {};
                    }
                    tempSourcePayment[wallet.source][walletType] = walletTotal;
                }
            }
            tempWallet[walletType] = walletTotal;
        });
        _.map(totalShippingPrice, (walletTotal, walletType) => {
            if (walletTotal > 0) {
                if (walletType !== 'cash') {
                    let wallet = _.find(wallets, function (w) { return w.id === parseInt(walletType); });
                    if (wallet?.source && (wallet?.source !== 'local')) {
                        if (_.isUndefined(tempSourcePayment[wallet.source])) {
                            tempSourcePayment[wallet.source] = {};
                        }
                        tempSourcePayment[wallet.source][walletType] = _.isUndefined(tempSourcePayment[wallet.source][walletType]) ? walletTotal : tempSourcePayment[wallet.source][walletType] + walletTotal;
                    }
                }
                if (_.isUndefined(tempWallet[walletType])) {
                    tempWallet[walletType] = 0;
                }
                tempWallet[walletType] += walletTotal;
            }
        });
        setTotalPayment(tempWallet);
        setSourcePayment(tempSourcePayment);
    }, [totalPrice, totalShippingPrice, wallets]);

    useEffect(() => {
        if (_.size(sourcePayment) > 0) {
            _.map(sourcePayment, (paymentArray, source) => {
                getUrl(`thirdParty/connection/${source}`).then(response => {
                    setSourceConnect({ ...sourceConnect, [source]: response.status });
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            })
        }
    }, [sourcePayment]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (oid) {
            getUrl(`payment/${oid}`)
                .then(orderList => {
                    // console.log("orderList", orderList);
                    if (isMountedRef.current) {
                        setLoading(false);
                        setCurrencyData(orderList.currency_data);
                        if (orderList.status === 1 && _.size(orderList.data) > 0) {
                            // setState({ customer_name: orderList.data[0]['buyer_name'] });
                            let txn_desc = 'Transaction for order ';
                            let allow_payment = true;
                            setOrder(orderList.data);
                            _.map(orderList.data, (orderItem, orderIndex) => { // loop purchases to get order
                                txn_desc += `${orderItem.order_no},`;
                                // console.log("asd", orderItem);
                                if (orderItem.status === 20) {
                                    _.map(orderItem.order_shippings, shippingItem => {
                                        let countShipping = false;
                                        _.map(shippingItem.order_shipping_details, shippingDetails => {
                                            _.map(orderItem.order_details, detailItem => {
                                                if (shippingDetails.order_detail_id === detailItem.id) {
                                                    if (detailItem.status === 1) {
                                                        countShipping = true;
                                                    }
                                                }
                                            })
                                        })
                                        if (countShipping) {
                                            let walletType = 'cash';
                                            if (shippingItem.wallet_id > 0) {
                                                walletType = shippingItem.wallet_id;
                                            }
                                            if (_.isUndefined(totalShippingPrice[walletType])) {
                                                totalShippingPrice[walletType] = 0;
                                                setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: 0 }));
                                            }
                                            setTotalShippingPrice(totalShippingPrice => ({ ...totalShippingPrice, [walletType]: totalShippingPrice[walletType] + parseFloat(shippingItem.fee) }));
                                            totalShippingPrice[walletType] += parseFloat(shippingItem.fee);
                                        }
                                    })
                                    // setTotalShippingPrice(totalShippingPrice => parseFloat(totalShippingPrice) + parseFloat(orderItem.shipping_fee));
                                }
                                // console.log("orderItem", orderItem);
                                _.map(orderItem.order_details, detailItem => { // loop order to get order details
                                    // console.log(detailItem)
                                    if (detailItem.status === 1) {
                                        _.map(detailItem.payment, (paymentItem, paymentIndex) => { // loop order details to get payment
                                            if (detailItem.error === false) {
                                                if (paymentIndex == "multi_wallet") {
                                                    let multiP = JSON.parse(detailItem.multi_pricing);
                                                    paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                }
                                                if (_.isUndefined(totalPrice[paymentIndex])) {
                                                    totalPrice[paymentIndex] = 0;
                                                    setTotalPrice(totalPrice => ({ ...totalPrice, [paymentIndex]: 0 }));
                                                }
                                                setTotalPrice(totalPrice => ({ ...totalPrice, [paymentIndex]: totalPrice[paymentIndex] + parseFloat(paymentItem) }));
                                                totalPrice[paymentIndex] += parseFloat(paymentItem);
                                            } else {
                                                allow_payment = false;
                                            }
                                        })
                                    }
                                })
                            });
                            if (allow_payment)
                                setValidBalance(orderList.valid_balance);
                            if (orderList.valid_balance === false) {
                                setInvalidMsg(t('payment.invalidWalletBalance'));
                            }
                        } else {
                            addAlert(orderList.message);
                            history.replace('/');
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
        } else {
            history.push('/cart');
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [oid, addAlert, history, refetchData]);

    const requestPayment = async (type) => {
        const data = { payType: type, purchaseNo: oid };

        return postUrl(`payment`, data)
            .then(result => {
                if (result.status == 0 && result.error && result.error.validate_error) {
                    window.location.reload();
                } else {
                    return result.status;
                }

            }).catch(error => {
                addAlert(JSON.stringify(error.message));
                return false;
            });
    }

    const makePayment = async type => {
        setLoading(true);
        const paymentStatus = await requestPayment(type);
        if (paymentStatus) {
            if (_.includes(['revenuemonster'], type)) {
                getUrl(`${type}/generate/${oid}`)
                    .then(async response => {
                        if (response.status === 1) {
                            setLoading(false);
                            if (type === 'revenuemonster') {
                                const { code, item } = response.data;
                                if (code === 'SUCCESS' && item.url) {
                                    window.location.href = item.url;
                                } else {
                                    addAlert(response.message);
                                }
                            }
                        } else {
                            setLoading(false);
                            addAlert(response.message);
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
            } else if (type === 'ipay88') {
                if (IPAY88_AVAILABLE) {
                    getUrl(`ipay88/generate/${oid}`)
                        .then(async (response) => {
                            // console.log("ipay88_response", response);
                            if (isMountedRef.current) {
                                if (response.status === 1) {
                                    setLoading(false);
                                    // console.log('response', response);
                                    await setIpay88Url(response.data.url);
                                    delete response.data.url;
                                    setIpay88PaymentInput(response.data);
                                    await ipay88PaymentForm.current.submit();
                                    await setLoading(true);
                                }
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            addAlert(JSON.stringify(error.message));
                        });
                }
            } else if (type === 'BonusChains') {
                getUrl(`${type}/generate/${oid}`)
                .then(async response => {
                    if (response.status === 1) {
                        setLoading(false);
                        if(response.data){
                            window.location.replace(response.data);
                        }
                    } else {
                        setLoading(false);
                        addAlert(response.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
            }
        } else {
            setRefetchData(!refetchData);
        }
    }

    const walletPayment = () => {
        setLoading(true);
        postUrl(`wallet/${oid}`).then((response) => {
            setLoading(false);
            if (response.status === 1) {
                history.replace(`/paymentresult/${oid}?source=wallet`);
            } else {
                addAlert(response.message);
            }
        })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const priceDisplay = (paymentItem, paymentIndex, orderDetails) => {
        let prefix = '';
        let decimal = 2;

        if (paymentIndex === 'multi_wallet') {
            let multiPricing = orderDetails ? JSON.parse(orderDetails.multi_pricing) : "";
            if (multiPricing) {
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];

            } else {
                let name = _.split(multiPricing.multi_wallet_detail.pricing_name, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                prefix = "Other wallets";
            }
        } else
            if (paymentIndex !== 'cash' && !isNaN(paymentIndex)) {
                _.map(wallets, wallet => {
                    if (parseInt(wallet.id) === parseInt(paymentIndex)) {
                        let name = _.split(wallet.name, '|');
                        prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                        // decimal = wallet.decimal;
                    }
                })
            } else if (paymentIndex === 'cash') {
                paymentItem = paymentItem * currencyRate[`${currency}`]
                prefix = i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]
            } else {
                let name = _.split(paymentIndex, '|');
                prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            }
        return (
            <div className='cl-label'>
                <NumberFormat className='txt-right' value={parseFloat(paymentItem).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={prefix ? prefix + ' ' : ''} />
            </div>
        )
    }

    const renderDisplay = () => {
        return (
            <div>
                {/* Buyer Info */}
                <Container>
                    <div className='glass2 shadow-glass2 bor15 p-all-15 m-b-20'>
                        <p className='fs-16 txt-upper'><b>{order[0]?.buyer_name}</b></p>
                        <p className='fs-content'>{order[0]?.buyer_mobile}</p>
                        <p className='fs-content'>{order[0]?.buyer_email}</p>
                    </div>
                    <div className='glass2 shadow-glass2 bor15 p-all-15'>
                        <div>
                            <p className='fs-content txt-upper'><b>{t('checkout.recipientUsername')}</b></p>
                            <p className='fs-title'>{_.isUndefined(order?.recipient_username) ? '-' : `${order?.recipient_username} `}</p>
                        </div>

                        {
                            order?.[0]?.recipient || order?.[0]?.mobile || order?.[0]?.address
                                ?
                                <div className='p-t-10'>
                                    <p className='fs-content txt-upper'><b>{t('checkout.shippingAddress')}</b></p>
                                    <div className='fs-content'>
                                        <div className='flex-m fs-content'>
                                            <p>{_.isUndefined(order?.[0]?.recipient) ? '-' : `${order?.[0]?.recipient} `} </p>
                                            {
                                                !_.isUndefined(order[0]) &&
                                                <p style={{ paddingLeft: 8 }}>{_.isUndefined(order?.[0]?.mobile) ? '-' : ` | ${order?.[0]?.mobile}`}</p>
                                            }
                                        </div>
                                        <p>{_.isUndefined(order?.[0]?.address) ? '-' : order?.[0]?.address}</p>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </Container>
                {
                    !validBalance ?
                        <Box className={styles.root} style={{ padding: 10, color: "red" }}>
                            {invalidMsg}
                        </Box>
                        : null
                }
                {/* Order Details */}
                <div className='p-t-20'>
                    <Container>
                        {_.map(order, (orderItem, key) => {
                            let orderPrice = {};
                            let showSummary = false;
                            let showOutlets = false;
                            let outletDetail = {};

                            return (
                                <div key={key} className='pos-relative'>
                                    {
                                        orderItem.status === 20 ?
                                            <div className='glass2 shadow-glass2 bor15 m-b-20'>
                                                <div className='txt-center p-tb-5 p-lr-15 clwhite bgblack' style={{ minWidth: 100, margin: '0 auto 20px', maxWidth: '85%', width: 'fit-content', borderRadius: '0 0 15px 15px' }}>
                                                    <p>{orderItem.order_no}</p>
                                                </div>
                                                {_.map(orderItem.order_details, detailItem => {
                                                    const split_title = detailItem['title'].split("|");
                                                    const title_translate = i18n.language === 'cn' ? (_.isUndefined(split_title[1]) ? split_title[0] : split_title[1]) : split_title[0];

                                                    if (detailItem.status) {
                                                        showSummary = true;
                                                        _.map(detailItem.payment, (paymentItem, paymentIndex) => {
                                                            if (paymentIndex === "multi_wallet") {
                                                                let multiP = JSON.parse(detailItem.multi_pricing);
                                                                paymentIndex = multiP.multi_wallet_detail.pricing_name;
                                                            }
                                                            if (_.isUndefined(orderPrice[paymentIndex])) {
                                                                orderPrice[paymentIndex] = 0;
                                                            }

                                                            if (detailItem.error === false) {
                                                                orderPrice[paymentIndex] += parseFloat(paymentItem);
                                                            } else {
                                                                orderPrice[paymentIndex] += 0;
                                                            }
                                                        })
                                                    }

                                                    if (_.size(detailItem.outlet) > 0) {
                                                        showOutlets = true;
                                                        outletDetail = detailItem.outlet;
                                                    }

                                                    return (
                                                        <div key={detailItem.id}>
                                                            {detailItem.status ?
                                                                <>
                                                                    <div className='p-lr-15 p-b-20'>
                                                                        <div className='flex-sb-m w-full'>
                                                                            <div className='bg-label bor10' style={{lineHeight:0}}>
                                                                                <WebpImg style={{ width: 90, height: 90, minWidth: 90, objectFit: 'cover' }} alt={detailItem.product_id} src={detailItem.image ? detailItem.image : '/images/empty/no-image.png'} />
                                                                            </div>
                                                                            <div className='w-full p-lr-12'>
                                                                                <p className=' fs-title'>{title_translate}</p>
                                                                                <div className='flex-sb-m w-full'>
                                                                                    <p>{`x ${detailItem.quantity}`}</p>
                                                                                    {
                                                                                        _.size(detailItem.payment) > 0
                                                                                            ?
                                                                                            _.map(detailItem.payment, (paymentItem, paymentIndex) => {
                                                                                                if (parseFloat(paymentItem) > 0) {
                                                                                                    return (
                                                                                                        <div key={paymentIndex}>
                                                                                                            <div>
                                                                                                                {priceDisplay(paymentItem, paymentIndex, detailItem)}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            })
                                                                                            :
                                                                                            <p>
                                                                                                <NumberFormat value={parseFloat(detailItem.price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                            </p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {detailItem.error ?
                                                                            <Grid item xs={12} style={{ padding: 10, textAlign: 'left', alignSelf: 'center' }}>
                                                                                <Typography variant="button" style={{ color: 'red' }}><ErrorIcon style={{ verticalAlign: 'middle' }} />{`${detailItem.message}`}</Typography>
                                                                            </Grid>
                                                                            : null}
                                                                    </div>
                                                                </>
                                                                : null}
                                                        </div>
                                                    )
                                                })}
                                                {
                                                    showOutlets ?
                                                        <Box style={{ backgroundColor: theme.palette.background.body, borderBottom: '1px dashed #c1c1c1', padding: 10 }}>
                                                            <Box>
                                                                <Typography style={{ color: theme.palette.gray.lightTitle, fontSize: 17 }}>{t('checkout.pickUpAt')}</Typography>
                                                                <div style={{ fontSize: 14, color: theme.palette.gray.text }}>
                                                                    <Typography>
                                                                        {outletDetail.pic_name} | {outletDetail.pic_mobile}
                                                                    </Typography>
                                                                    <Typography>
                                                                        <b>{outletDetail.outlet_name}</b>
                                                                    </Typography>
                                                                    <Typography>
                                                                        {outletDetail.address}
                                                                    </Typography>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                        : null
                                                }
                                                {showSummary ?
                                                    <div className='p-tb-10 p-lr-20'>
                                                        {orderItem.remark &&
                                                            <div className='fs-content cl-label'>
                                                                <p><b>{t('checkout.remark')}</b></p>
                                                                <p style={{wordBreak:'break-all'}}>
                                                                    {orderItem.remark}
                                                                </p>
                                                            </div>
                                                        }

                                                        <div className='flex-sb w-full p-b-5 p-t-10 fs-content cl-label'>
                                                            <p><b>{t('payment.subtotal')}:</b></p>
                                                            <div className='txt-right'>
                                                                {_.size(orderPrice) > 0 ?
                                                                    _.map(orderPrice, (priceItem, priceIndex) => {
                                                                        if (parseFloat(priceItem) > 0) {
                                                                            return (
                                                                                <div key={priceIndex}>
                                                                                    <b>{priceDisplay(priceItem, priceIndex)}</b>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                    :
                                                                    <p>
                                                                        {i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} 0.00
                                                                    </p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='flex-sb w-full fs-content cl-label'>
                                                            <p><b>{t('order.shippingFee')}:</b></p>
                                                            <div className='txt-right'>
                                                                {_.size(orderItem.order_shippings) > 0
                                                                    ?
                                                                    <>
                                                                        {_.map(orderItem.order_shippings, (shippingItem, shippingIndex) => {
                                                                            let countShipping = false;
                                                                            _.map(shippingItem.order_shipping_details, shippingDetails => {
                                                                                _.map(orderItem.order_details, detailItem => {
                                                                                    if (shippingDetails.order_detail_id === detailItem.id) {
                                                                                        if (detailItem.status === 1) {
                                                                                            countShipping = true;
                                                                                        }
                                                                                    }
                                                                                })
                                                                            });
                                                                            if (countShipping) {
                                                                                let prefix = i18n.language === 'cn' ? (currencyData?.currency_display_cn ? currencyData?.currency_display_cn : currencyData?.currency_display_en) : currencyData?.currency_display_en;
                                                                                let decimal = 2;
                                                                                if (shippingItem.wallet_id > 0) {
                                                                                    _.map(wallets, wallet => {
                                                                                        if (parseInt(wallet.id) === parseInt(shippingItem.wallet_id)) {
                                                                                            let name = _.split(wallet.name, '|');
                                                                                            prefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                                                                            // decimal = wallet.decimal;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                if (parseFloat(shippingItem.fee).toFixed(decimal) > 0) {
                                                                                    return (
                                                                                        <div key={shippingIndex}>
                                                                                            <b><NumberFormat value={parseFloat(shippingItem.fee).toFixed(decimal)} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={`${prefix} `} /></b>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                    :
                                                                    t('order.freeShipping')
                                                                }
                                                                {/* {
                                                                parseFloat(orderItem['shipping_fee']).toFixed(2) > 0
                                                                ?
                                                                <NumberFormat value={ parseFloat(orderItem['shipping_fee']).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${ shippingWallet.prefix } `} />
                                                                :
                                                                t('order.freeShipping')
                                                            } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            : null
                                    }
                                </div>
                            )
                        })}
                    </Container>
                </div>
                {/* Payment Breakdown */}
                <Container>
                    <div className='p-lr-15'>
                        <p className='fs-15 cl-label'>
                            <b>{t('payment.paymentBreakdown')}</b>
                        </p>
                        <div>
                            {totalPayment &&
                                _.map(totalPayment, (priceItem, priceIndex) => {
                                    if (parseFloat(priceItem) > 0) {
                                        return (
                                            <div className='fs-14' key={priceIndex}>
                                                {priceDisplay(priceItem, priceIndex)}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </Container>
            </div>
        )
    }

    const paymentButton = (onClickFunction, label) => {
        return (
            <div className={`${styles.stickyFooter} p-t-8`} style={{ zIndex: 1299, height: 68, boxShadow: 'none' }}>
                <div className='flex-sb-m p-lr-20 h-full w-full'>
                    <Button variant="contained" fullWidth size="large" style={{ margin: 10, backgroundColor: theme.palette.primary.main, color: theme.palette.white.main }} onClick={onClickFunction}>{label}</Button>
                </div>
            </div>
        )
    }

    const cashPayment = () => {
        return (
            <>
                {REVENUEMONSTER_AVAILABLE &&
                    paymentButton(() => makePayment('revenuemonster'), 'RevenueMonster ' + t('payment.systemWallet'))
                }
                {IPAY88_AVAILABLE ?
                    <Box style={{ alignItems: 'center', justifyContent: 'center', margin: "0px 30px" }}>
                        <Button fullWidth variant="contained" color="primary" style={{ padding: 10, height: 55, backgroundColor: '#f26722', border: '1px solid #d0d0d0' }} onClick={() => makePayment('ipay88')}>
                            <WebpImg containerStyle={{ display: 'flex', alignItems: 'center' }} style={{ height: 40, maxWidth: 'unset!important' }} alt="upay"
                                src="/images/payment_logo/ipay88.png" />
                        </Button>
                        <form ref={ipay88PaymentForm} action={ipay88Url} method="POST">
                            {ipay88PaymentInput ?
                                _.map(ipay88PaymentInput, (paymentValue, paymentIndex) => {
                                    return (
                                        <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                    )
                                })
                                : null}
                        </form>
                    </Box>
                    : null}
                {/* Sub Total */}
                <Container>
                    <div className='p-t-20 p-lr-15'>
                        <div className='flex-sb-m w-full fs-15 cl-label'>
                            <p><b>{t('payment.subtotal')} : </b></p>
                            <p className='txt-right p-l-15'>
                                <NumberFormat value={parseFloat(totalPrice['cash'] * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                            </p>
                        </div>
                        {
                            totalShippingPrice['cash'] > 0 &&
                            <div className='flex-sb-m w-full fs-15 cl-label'>
                                <p><b>{t('order.shippingFee')} : </b></p>
                                <p className='txt-right p-l-15'>
                                    {
                                        parseFloat(totalShippingPrice['cash']).toFixed(2) > 0 ?
                                            <NumberFormat value={parseFloat(totalShippingPrice['cash'] * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                            :
                                            <Typography>{t('order.freeShipping')}</Typography>
                                    }
                                </p>
                            </div>
                        }
                        <div className='flex-sb-m w-full fs-15 cl-label'>
                            <p><b>{t('checkout.totalPayment')} : </b></p>
                            <p className='txt-right p-l-15'>
                                <NumberFormat value={(parseFloat(totalPayment['cash']) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                            </p>
                        </div>
                    </div>
                </Container>
            </>
        )
    }

    const handleBCLogin = () => {
        window.location.href = `${process.env.REACT_APP_BC_URL}/login-redirect?C=${process.env.REACT_APP_LOCAL_URL}/login-callback&P=payment/${oid}`;
    }

    const sourceWalletPayment = () => {
        return (
            <>
                {BONUSCHAINS_AVAILABLE && sourcePayment['BonusChains'] &&
                    <>
                        {
                            !_.isUndefined(sourceConnect?.['BonusChains']) &&
                            <>
                                {
                                    sourceConnect?.['BonusChains']
                                        ?
                                        paymentButton(() => makePayment('BonusChains'), 'BonusChains ' + t('payment.systemWallet'))
                                        :
                                        paymentButton(() => handleBCLogin(), t('button.loginWith') + ' BonusChains')
                                }
                            </>
                        }
                    </>
                }
            </>
        )
    }

    const getPaymentButton = () => {
        let payCash = (parseFloat(totalPayment['cash'])).toFixed(2) > 0 ? true : false;
        let paySource = _.size(sourcePayment) > 0 ? true : false;
        return (
            <div>
                {payCash && cashPayment()}
                {paySource && sourceWalletPayment()}
                {!payCash && !paySource && paymentButton(() => walletPayment(), 'local ' + t('payment.systemWallet'))}
            </div>
        )
    }

    return (
        <div>
            <TitleBar btnRegister back backgroundColor="bg-header" />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>    
                <div className='subtitle-box p-b-24 clblack'>
                    <p className='fs-header'>{t('title.payment')}</p>
                    <p className='fs-subheader lh-11 '>{t('payment.paymentSubtitle')}</p>
                </div>
                </Container>
            <div>{renderDisplay()}</div>
            {validBalance && getPaymentButton()}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#f2f2f2',
        boxShadow: '0 -5px 11px 0 #0000001a',
        zIndex: 1,
        minHeight: 70,
    },
}));