import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { Link, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FaCirclePlus } from "react-icons/fa6";

import WebpImg from '@layouts/WebpImg';

import '@css/styles.css';

export default function ProductItemCard(props) {
    const { product, currency, currencyCode } = props;

    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { currencyRate } = useSelector(state => state.general);

    let bvDisplay = product.bv;
    let priceDisplay = product.sell_price;

    let showRetail = false;
    let discountPercent = 0;

    if (parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
        showRetail = true;
        discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
        discountPercent = Math.round(discountPercent);
    }

    return (
        <Link underline='none' to={`/product/${product.id}`} component={RouterLink} style={{ width: '100%' }}>
            <div className='product-hover bg-label bor15 pos-relative p-t-10 p-b-20 p-lr-20 flex-c-m m-t-80 flex-col'>
                <div style={{margin:'-75px auto 0' }}>
                    <div className='bg-base bor15  shadow-product' style={{lineHeight:0}}>
                        <WebpImg alt={product[`title_${i18n.language}`]} src={_.size(product.images_array) > 0 ? product.images_array[0] : '/images/empty/no-image-theme.png'} className='bor15' style={{ width: 120, aspectRatio: '1/1', objectFit: 'cover' }} />
                    </div>
                </div>

                {
                    discountPercent > 0 &&
                    <>
                        {
                            showRetail ?
                                <div className='ribbon-side flex-c-m pos-absolute' style={{right: 0, top: 40 }}>
                                    <p className='fs-remark lh-0'>{/*  style={{color: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }} */}
                                        <b>{`${discountPercent}% ${t('general.discount')}`}</b>
                                    </p>
                                </div>
                                : null
                        }
                    </>
                }

                <Tooltip title={product[`title_${i18n.language}`]}>
                    <div className='p-t-10 cl-text' style={{ width: '-webkit-fill-available' }}>
                        <div>
                            <p className='fs-title txt_truncate'>{product[`title_${i18n.language}`]}</p>
                        </div>
                        <div className='flex-sb flex-b p-t-10' style={{ height: 41 }}>
                            <div className='p-r-10'>
                                {
                                    showRetail &&
                                    <p className='fs-remark lh-10' style={{textDecoration: 'line-through' }}>
                                        <NumberFormat value={(product.retail_price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyCode[1] ? currencyCode[1] : currencyCode[0]) : currencyCode[0]} `} />
                                    </p>
                                }
                                <p className="fs-content p-r-3 lh-10" style={{fontWeight: 'bold' }}>
                                    <NumberFormat value={(priceDisplay * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyCode[1] ? currencyCode[1] : currencyCode[0]) : currencyCode[0]} `} />
                                </p>
                            </div>
                            <div style={{ minWidth: 30 }}>
                                <FaCirclePlus className='fs-icon cl-theme' />
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </div>
        </Link>
    )
}