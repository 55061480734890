import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Drawer, FormControl, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ArtTrackOutlined, AspectRatioOutlined } from '@material-ui/icons';
import { FiX } from "react-icons/fi";

export default function AddressDetails(props) {
    const { addressOpen, setAddressOpen, handleAddressChange, addressMobileCode, addressFormState, errorAddressState, state, countries, cities, district, addressGroup, addressState, shouldUploadIC, icFrontRef, icBackRef, submitAddress } = props;

    const styles = useStyles();
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleSubmit = () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        submitAddress();
        setTimeout(() => setIsSubmitting(false), 2000);
    };

    const selectOrTextField = (name, isArray, dataArray) => {
        return (
            <div className='p-b-24'>
                <p className='fs-textfield'>{t(`address.${name==='name'?'recipient':name}`)}</p>
                {isArray ?
                    <>
                        <div className='box-textfield w-full'>
                            <Select
                                native
                                fullWidth
                                inputProps={{ name: name }}
                                onChange={handleAddressChange}
                                value={addressFormState[name]}
                            >
                                <option value="">{t('address.pleaseSelect')}{t(`address.${name}`)}</option>
                                {
                                    _.map(dataArray, item => {
                                        return (
                                            <option key={name === 'country' ? item.code : item.id} value={name === 'country' ? item.code : item.id}>{item.name_display}</option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        {
                            errorAddressState?.[name] &&
                            <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState[name]}</p>
                        }
                    </>
                    :
                    <>
                        <div className='box-textfield w-full'>
                            <TextField
                                fullWidth
                                type="text"
                                inputProps={{ name: name }}
                                onChange={handleAddressChange}
                                value={addressFormState[name]}
                            />
                        </div>
                        {
                            errorAddressState?.[name] &&
                            <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState[name]}</p>
                        }
                    </>
                }
            </div>
        )
    }

    return (
        <Drawer
            anchor="bottom"
            open={addressOpen}
            onClose={() => setAddressOpen(false)}
        >
            <div className='p-lr-20 p-t-20 flex-col' style={{ width: 'auto', maxHeight: '85vh', overflowY: 'auto' }}>
                <div className='w-full flex-sb-m p-b-24' style={{ flex: '0 0 auto' }}>
                    <p className='fs-header clblack'>
                        {
                            state.mode === 'add' ?
                                t('address.addNewAddress')
                                : t('address.editAddress')
                        }
                    </p>
                    <FiX className='clblack fs-icon pointer' onClick={() => setAddressOpen(false)} />
                </div>
                <div className='p-r-14 p-b-20' style={{ flex: '1 1 auto', overflowY: 'auto' }}>
                    <div>
                        {selectOrTextField('name', false, [])}
                        {selectOrTextField('country', true, countries)}
                        {_.size(addressFormState.country) > 0 &&
                            <div className='p-b-24'>
                                <p className='fs-textfield'>{t('address.mobile')}</p>
                                <div className='flex-sb-m w-full' style={{ flexWrap: 'wrap' }}>
                                    <div className="box-textfield" style={{ width: 'calc(30% - 10px)', borderRadius: 12 }}>
                                        <Select
                                            native
                                            fullWidth
                                            inputProps={{ name: 'mobile_code' }}
                                            onChange={handleAddressChange}
                                            value={addressFormState.mobile_code}
                                            disabled={_.size(addressMobileCode) > 0 ? false : true}
                                        >
                                            {_.size(addressMobileCode) > 0 ?
                                                _.map(addressMobileCode, item => (
                                                    <option key={item} value={item}>{item}</option>
                                                )) :
                                                <option value=""></option>
                                                // {t('address.pleaseSelect')}{t('address.mobileCode')}
                                            }
                                        </Select>
                                    </div>
                                    <div className='txt-center' style={{ width: 20 }}>
                                        <p className='fs-21'>-</p>
                                    </div>
                                    <div className="box-textfield" style={{ width: 'calc(70% - 10px)' }}>
                                        <TextField
                                            fullWidth
                                            onChange={handleAddressChange}
                                            inputProps={{ name: 'mobile' }}
                                            value={addressFormState.mobile}
                                        />
                                    </div>
                                </div>
                                {
                                    (errorAddressState?.mobile_code || errorAddressState?.mobile) &&
                                    <p className='fs-remark cl-error p-l-10 p-t-5'>{_.join([errorAddressState?.mobile_code, errorAddressState?.mobile], '')}</p>
                                }
                            </div>
                        }
                        {selectOrTextField('email', false, [])}
                        {shouldUploadIC &&
                            <>
                                <div className='p-b-24'>
                                    <p className='fs-textfield'>{t('address.identity_no')}</p>
                                    <div className='box-textfield w-full'>
                                        <TextField
                                            type="text"
                                            inputProps={{ name: 'identity_no' }}
                                            onChange={handleAddressChange}
                                            value={addressFormState.identity_no}
                                        />
                                    </div>
                                    {
                                        errorAddressState?.identity_no &&
                                        <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState.identity_no}</p>
                                    }
                                </div>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography variant="body2">{t('checkout.chinaRecipientNeedIC')}</Typography>
                                    <Box display="flex" flexDirection="row">
                                        <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                        <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icFrontRef.current.click()}
                                            variant="outlined"
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                {addressFormState.icFront ?
                                                    <img src={addressFormState.icFront} alt="ic front" />
                                                    : <ArtTrackOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icFront')}</Typography>
                                            </Box>
                                        </Button>
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icBackRef.current.click()}
                                            variant="outlined"
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                {addressFormState.icBack ?
                                                    <img src={addressFormState.icBack} alt="ic back" />
                                                    : <AspectRatioOutlined fontSize="large" />}
                                                <Typography variant="body2">{t('checkout.icBack')}</Typography>
                                            </Box>
                                        </Button>
                                    </Box>
                                    {
                                        errorAddressState?.icFront &&
                                        <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState.icFront}</p>
                                    }
                                    {
                                        errorAddressState?.icBack &&
                                        <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState.icBack}</p>
                                    }
                                </FormControl>
                            </>
                        }
                        {selectOrTextField('zip', false, [])}
                        {selectOrTextField('state', _.includes(addressGroup.stateGroup, addressFormState.country), addressState)}
                        {selectOrTextField('city', _.size(cities) > 0, cities)}
                        {selectOrTextField('address2', _.size(district) > 0, district)}
                        <div className='p-b-24'>
                            <p className='fs-textfield'>{t('address.address')}</p>
                            <div className='w-full box-textfield'>
                                <TextField
                                    type="text"
                                    fullWidth
                                    inputProps={{ name: 'address' }}
                                    onChange={handleAddressChange}
                                    value={addressFormState.address}
                                    multiline
                                    rows={3}
                                />
                            </div>
                            {
                                errorAddressState?.address &&
                                <p className='fs-remark cl-error p-l-10 p-t-5'>{errorAddressState.address}</p>
                            }
                        </div>
                    </div>
                    <div className='btn-theme bor15 w-full' onClick={handleSubmit}>
                        <p className='fs-button'>{state.mode === 'add' ? t('button.add') : t('button.update')}</p>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: 5
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
}));