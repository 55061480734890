import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { putUrl } from '@helper/ApiAction';

//MUI
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { TextField, Typography, Container, Snackbar, Card, CardContent, FormHelperText } from '@material-ui/core';

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


const INITIAL_SECURITY_PASSWORD_STATE = { old_password: '', security_password: '', security_password_confirmation: '' };

export default function ProfileSecurityPassword() {
    // const styles = useStyles();
    const { t } = useTranslation();
    // const theme = useTheme();

    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        const { old_password, security_password, security_password_confirmation } = securityPasswordFormState;
        if (!old_password || !security_password || !security_password_confirmation) {
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/security_password', securityPasswordFormState).then(result => {
                if (result.error) {
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: result.message }));
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.message }));
                    setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <div>
            <TitleBar back btnRegister backgroundColor="bg-theme" />
            <div className='bg-theme p-b-30 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'><b>{t('profile.changeSecurityPassword')}</b></p>
                    <p className='fs-subheader cl-label'>{t('profile.securityPasswordSubtitle')}</p>
                </div>
            </div>
            <Container>
                {/* <div className='p-tb-15'>
                    <Typography variant="h6" className='cl-theme'>
                        {t('profile.changeSecurityPassword')}
                    </Typography>
                    <p className='cl-label fs-13'>
                        {t('profile.securityPasswordSubtitle')}
                    </p>
                </div>
                 <div className='divider-white p-tb-4' />
                 */}
               
                <div>
                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('profile.currentSecurityPassword')}</p>
                            <TextField
                                error={errorFormState.old_password ? true : false}
                                helperText={errorFormState.old_password}
                                fullWidth={true}
                                variant="filled"
                                size="small"
                                type="password"
                                inputProps={{ name: 'old_password' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.old_password}
                            />
                            <FormHelperText style={{ paddingLeft: 10, paddingTop: 5 }}>{t('profile.currentSecurityPasswordInfo')}</FormHelperText>
                        </div>

                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('profile.newSecurityPassword')}</p>
                            <TextField
                                error={errorFormState.security_password ? true : false}
                                helperText={errorFormState.security_password}
                                fullWidth={true}
                                variant="filled"
                                size="small"
                                type="password"
                                inputProps={{ name: 'security_password' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.security_password}
                            />
                        </div>

                        <div className='p-t-25'>
                            <p className='fs-textfield'>{t('profile.confirmSecurityPassword')}</p>
                            <TextField
                                error={errorFormState.security_password_confirmation ? true : false}
                                helperText={errorFormState.security_password_confirmation}
                                fullWidth={true}
                                variant="filled"
                                size="small"
                                type="password"
                                inputProps={{ name: 'security_password_confirmation' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.security_password_confirmation}
                            />
                        </div>

                        <div className='w-full p-t-50'>
                            <div className='btn-theme w-full translateY' onClick={updateSecurityPassword}>
                                <p className='fs-button'>{t('button.confirm')}</p>
                            </div>
                        </div>
                    </div>


                    <Snackbar
                        open={state.snackbarShow}
                        autoHideDuration={2000}
                        onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                            <CardContent style={{ padding: 10 }}>
                                <Typography style={{ color: 'white' }}>{state.message}</Typography>
                            </CardContent>
                        </Card>
                    </Snackbar>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    textFieldTitle: {
        color: theme.palette.gray.dtext,
        fontSize: 16,
        paddingBottom: 5,
        paddingLeft: 10
    }
}));