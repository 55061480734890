import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#D92E2E',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f6f7f9',
            review: '#efefef',
            body: '#f6f7fa'
        },
        white: {
            main: '#FFFFFF',
        },
        green: {
            main: '#4caf50',
        },
        text: {
            primary: '#333333',
            primaryLight: '#787878',
            lightBlue: '#fff',
        },
        gray: {
            main: '#b5b5b5',
            border: '#dee2e6',
            chip: '#b8b9b9',
            text: '#96A4C0',
            dtext: '#696969',
            textTitle: '#979797',
            ltext: '#d1d1d1',
            lightTitle: '#adacac',
        },
        silver: {
            bg: '#bebfc4',
            bgDarker: '#9d9ea2',
            text: '#939393',
            textDarker: '#6b6b6b',
        },
        price: {
            main: '#e53935',
        },
        button: {
            step: '#fdb93f',
        },
    },
    overrides: {
        MuiInputBase:{
            root:{
                padding: '10px 15px',
            },
            '&.MuiInputBase-marginDense':{
                paddingTop:5
            },
            multiline: {
                padding:'10px 16px !important'
            },
            inputMultiline: {
                padding:'6px 0'
            },
            inputMarginDense:{
                paddingTop:5,
                paddingBottom:5,
                padding:'5px 0'
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 10,
                background: '#fff',
                backgroundColor: '#fff',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                // boxShadow:'rgb(0 0 0 / 4%) 0px 6px 9px 0px',
                '&:hover': {
                    backgroundColor: '#fff',
                    boxShadow:'inset 0 2px 3px 0 #0005'
                },
                "&.Mui-focused": {
                     backgroundColor: '#fff',
                    boxShadow:'inset 0 2px 3px 0 #0005'
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //     border: `5px solid green`,
                // },
                // "&.Mui-focused": {
                //     "& .MuiOutlinedInput-notchedOutline": {
                //         border: `5px dotted red`,
                //     },
                // }

            },
            adornedStart:{
                paddingLeft:24,
                paddingRight:24
            },
            multiline: {
                borderRadius: 10,
                padding:'17px 27px 0',
            },
            input: {
                padding:'17px 27px 0',
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #e3e3e3 inset',
                    borderTopLeftRadius: 48,
                    borderTopRightRadius: 48,
                    borderRadius: 48,
                    WebkitTextFillColor: '#222',
                    padding:'17px 27px 0',
                },
            },
            inputMarginDense:{
                paddingTop:15,
                paddingBottom:15,
                padding:'15px 20px'
            },
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not($disabled):after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: 'none'
                },
                '&.Mui-disabled:before':{
                    borderBottom:'none'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 12,
                background: '#F6F7F9',
                backgroundColor: '#F6F7F9',
                boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.93), 2px 2px 4px 0px rgba(0, 0, 0, 0.09) inset',
            },
            multiline: {
                borderRadius: 10
            },
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #e3e3e3 inset',
                    borderTopLeftRadius: 48,
                    borderTopRightRadius: 48,
                    borderRadius: 48,
                    WebkitTextFillColor: '#222',
                    padding:'6px 12px',
                },
            },
            notchedOutline: {
                border: 'none'
            }
        },
        PrivateTabIndicator: {
            colorSecondary: {
                backgroundColor: '#000'
            }
        },
        MuiBackdrop: {
            root: {
                width: 420,
                margin: '0 auto',
                maxWidth: '100%',
                backgroundColor:'rgb(0 0 0 / 53%)',
                backdropFilter:'blur(2px)'
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                width: 420,
                margin: '0 auto',
                maxWidth: '100%',
                maxHeight:'100vh',
                borderRadius: '30px 30px 0px 0px',
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: 'none'
                },
                '&:after': {
                    borderBottom: 'none'
                },
                '&:hover:not($disabled):after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: 'none'
                }

            }
        },
        MuiInputLabel: {
            formControl:{
                left:16
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: '#F6F7F9'
            },
            rounded: {
                borderRadius:20
            }
        },
        MuiTab: {
            root:{
                minWidth:'30% !important'
            }
        },
        MuiButton: {
                contained: {
                    '&.Mui-disabled': {
                        color: '#fefefe',
                        backgroundColor: '#bcbcbc',
                    },
                    textTransform: 'capitalize',
                    padding:'10px 16px',
                    fontSize:17,
                    borderRadius:48,
                    backgroundColor:'#000000',
                    color:'#fff',
                    boxShadow: ' -3px -3px 8px 0px #0000002E inset',
                    '&:hover': {
                        backgroundColor:'#303030'
                    },
                    // boxShadow: '0px 8px 10px 0px rgba(117, 238, 255, 0.24), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.45) inset;'
                },
                containedPrimary:{
                    backgroundColor:'#D92E2E',
                    color:'#fff',
                    '&:hover': {
                        backgroundColor:'#000000'
                    },
                },
                containedSecondary: {
                    backgroundColor:'#000000',
                    color:'#fff',
                    '&:hover': {
                        backgroundColor:'#303030'
                    },
                }
        },
    },
    breakpoints: {
        values: {
            ss: 300,
            s: 400,
            xs: 500,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;