import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Avatar, BottomNavigation, BottomNavigationAction, Box, Typography } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { changeLanguage as reduxChangeLanguage } from '@actions';
import { API } from '@configs/AxiosConfig';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { AccountBalanceWalletOutlined, GroupAddOutlined, NotificationsNone } from '@material-ui/icons';
import { FiHome, FiUser, FiHeadphones } from 'react-icons/fi';
import { AiOutlineProduct } from "react-icons/ai";
import { IoCartOutline } from "react-icons/io5";

import Login from '../pages/Auth/Login';
import theme from '../Theme';

const SHOW_NAVIGATION_PAGE = ['', 'home', 'referral-qr',  'category', 'cart', 'bonus-home', 'notification-category', 'wallet-home', 'settings'];

export default function Footer() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const { accessToken } = useSelector(state => state.general);
	const { addAlert } = useNotificationLoading();

	const [drawerLogin, setDrawerLogin] = useState(false);

	const [value, setValue] = useState(0);
	const [unreadNotification, setunreadNotification] = useState();
	const [currentPath, setCurrentPath] = useState();

	useEffect(() => {
		if (_.includes(['en', 'cn'], i18n.language) === false) {
			changeLanguage('en');
		}
		API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
		// eslint-disable-next-line
	}, [i18n.language, t]);

	useEffect(() => {
		if (accessToken) {
			getUrl(`notifications`).then(response => {
				if (response.status) {
					setunreadNotification(response.new_count);
				}
			}).catch(error => {
				addAlert(JSON.stringify(error.message));
			});
		} else {
			setunreadNotification('');
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		dispatch(reduxChangeLanguage(lang));
	};

	useEffect(() => {
		let active = true;

		if (active) {
			const path = _.split(location.pathname, '/');
			if (path) {
				setCurrentPath(path[1].toLowerCase());
				if (path[1].toLowerCase() === "home" || path[1].toLowerCase() === "") {
					setValue(0);
				} else {
					switch(path[1].toLowerCase()) {
						case 'category':
							setValue(1);
							break;
						// case 'wallet-home':
						case 'cart':
							setValue(2);
							break;
						case 'notification-category':
							setValue(3);
							break;
						case 'settings':
							setValue(4);
							break;
						default:
							setValue(0);
							break;
					}
				}
			}
		}
		return () => { active = false };
	}, [location.pathname])

	return (
		<div>
			{
				_.includes(SHOW_NAVIGATION_PAGE, currentPath) ?
					<Box className='mobile-width-fixed flex-sa-m w-full cl-theme' style={{ bottom: 0, zIndex: 9 }} paddingBottom={0}>
						<ThemeProvider theme={bottomNavStyle}>
							<div style={{ position: "relative" }}>
								{
									unreadNotification > 0
										?
										<Avatar variant="rounded" className={classes.unreadiconColor} style={{ marginLeft: 5 }} >
											<Typography style={{ color: '#FFFFFF', fontWeight: 600, fontSize: 10 }}>{unreadNotification > 99 ? "99+" : unreadNotification}</Typography>
										</Avatar>
										:
										null
								}
								<BottomNavigation
									value={value}
									onChange={(event, newValue) => {
										setValue(newValue);
									}}
									className={classes.root}
									style={{ width: 420, maxWidth: '100%', paddingTop: 10, }}
								>
									<BottomNavigationAction
										to="/home"
										component={RouterLink}
										label={t('title.home')}
										style={{ fontSize: 26 }}
										icon={<FiHome />}
									/>
									<BottomNavigationAction
										to="/category/all"
										component={RouterLink}
										label={t('title.explore')}
										style={{ fontSize: 26 }}
										icon={<AiOutlineProduct />}
									/>
									{accessToken ?
										<BottomNavigationAction
											to="/cart"
											component={RouterLink}
											label={t('title.cart')}
											style={{ fontSize: 26 }}
											icon={<IoCartOutline />}
										/>
										:
										<BottomNavigationAction
											onClick={() => setDrawerLogin(true)}
											to={location.pathname}
											component={RouterLink}
											label={t('title.cart')}
											style={{ fontSize: 26 }}
											icon={<IoCartOutline />}
										/>}
									{/* <BottomNavigationAction
										to={(accessToken ? "/wallet-home" : "/login")}
										component={RouterLink}
										label={t('title.wallet')}
										style={{ fontSize: 26 }}
										icon={<AccountBalanceWalletOutlined />}
									/> */}
									{accessToken ?
										<BottomNavigationAction
											to="/notification-category"
											component={RouterLink}
											label={t('title.notification')}
											style={{ fontSize: 26 }}
											icon={<NotificationsNone />}
										/>
										:
										<BottomNavigationAction
											onClick={() => setDrawerLogin(true)}
											to={location.pathname}
											component={RouterLink}
											label={t('title.notification')}
											style={{ fontSize: 26 }}
											icon={<NotificationsNone />}
										/>
									}
									
									<BottomNavigationAction
										to={(accessToken ? "/settings" : "/help-center")}
										component={RouterLink}
										label={accessToken ? t('title.profile') : t('title.service') }
										style={{ fontSize: 26 }}
										icon={accessToken ? <FiUser /> : <FiHeadphones />}
									/>
								</BottomNavigation>
							</div>
						</ThemeProvider>
					</Box>
					: null
			}
			{accessToken?null:<Login drawerOpen={drawerLogin} setDrawerOpen={setDrawerLogin}/>}
		</div>
	)
}

const useStyles = makeStyles(theme => ({
	unreadiconColor: {
		position: "absolute",
		[theme.breakpoints.up('ss')]: {
			right: 66,
		},
		[theme.breakpoints.up('s')]: {
			right: 90,
		},
		[theme.breakpoints.up('xs')]: {
			right: 120,
		},
		[theme.breakpoints.up('sm')]: {
			right: 93,
		},
		right: 10,
		padding: 5,
		width: 25,
		height: 20,
		backgroundColor: '#feb940',
		color: "#fff",
		zIndex: 1,
	},
}));

const bottomNavStyle = createMuiTheme({
	overrides: {
		MuiBottomNavigation: {
			root: {
				backgroundColor: '#f6f7f9',
				backdropFilter: 'blur(12px)',
				zIndex: 3,
			},
		},
		MuiButtonBase: {
			root: {
				'&$disabled': {
					color:'#2B2B2B',
					opacity: 0.5
				},
			},
		},
		MuiBottomNavigationAction: {
			root: {
				minWidth: 65,
				color: '#a1a1a1',
				"&$selected": {
					color: '#D92E2E',
					fontSize: '0.75rem'
				},
			}
		}
	},
});