import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getUrl, postUrl, putUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Box, Button, CircularProgress, FormControl, FormHelperText, IconButton, Select, TextField, Typography, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// ICON
import { ArtTrackOutlined, AspectRatioOutlined } from '@material-ui/icons';
import { FiX, FiPlus, FiEdit, FiMapPin } from "react-icons/fi";

import AddressDetails from "@components/AddressDetails";

const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "", mobile_code: " " };

export default function CheckoutAddressCard(props) {
    const { state, setState, addresses, setAddress, fetchingMallShipping, mallShippingRefresh, setMallShippingRefresh } = props;
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();
    const { addAlert } = useNotificationLoading();

    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const [addressMobileCode, setAddressMobileCode] = useState({});
    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile_code: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [addressList, setAddressList] = useState(false);
    const [addressDetails, setAddressDetails] = useState(false);

    // const [open, setOpen] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    // GET ADDRESS LIST
    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('user/address')
                .then(addressList => {
                    if (isMountedRef.current) {
                        setAddress(addressList.data);
                        let defaultAddress = _.find(addressList.data, { 'default': true });
                        if (_.size(defaultAddress) > 0) {
                            setState(state => ({ ...state, addressId: defaultAddress.id }))
                        } else {
                            if (_.size(addressList.data)) {
                                const firstAddress = addressList.data[0];
                                setState(state => ({ ...state, addressId: firstAddress.id }))
                            }
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    // GET ADDRESS GROUP
    useEffect(() => {
        isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if (isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [addAlert, t]);

    // SET ADDRESS MOBILE CODE
    useEffect(() => {
        if (_.size(countries) > 0) {
            if (addressFormState?.country !== '') {
                let codeList = {};
                let countryCode = addressFormState?.mobile_code;
                let code = _.find(countries, { 'code': addressFormState?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (addressFormState?.mobile_code !== '' ? addressFormState?.mobile_code : '');
                    }
                }

                setAddressMobileCode(codeList);
                setAddressFormState(prevState => ({ ...prevState, mobile_code: countryCode }));
            } else if (addressFormState?.country === '' && addressFormState?.mobile_code !== '') {
                let tempArr = _.clone(countries);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, addressFormState?.mobile_code));
                if (_.size(tempArr) > 0) {
                    setAddressMobileCode(tempArr[0]['tel_code']);
                    setAddressFormState(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [countries, addressFormState?.country, addressFormState?.mobile_code]);

    // SET UPLOADIC BY COUNTRY
    useEffect(() => {
        if (addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    // GET COUNTRY
    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            if (isMountedRef.current) {
                setCountry(countryList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [i18n.language, addAlert, t]);

    // GET STATES BY COUNTRY
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.country) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if (isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    // GET CITIES BY STATE
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.state) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if (isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country, addressFormState.state]);

    // GET DISTRICTS BY CITY
    useEffect(() => {
        isMountedRef.current = true;
        if (addressFormState.city) {
            if (_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if (isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country, addressFormState.city]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'icFront') {
            if (icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if (name === 'icBack') {
            if (icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setAddressFormState({ ...addressFormState, [name]: value });
        }else{
            setAddressFormState({ ...addressFormState, [name]: value });
        }
        if (name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };

    const openAddAddress = () => {
        setAddressDetails(true)
        setErrorAddressState({});
        setState(state => ({ ...state, mode: 'add', modalShow: true }));
    };

    const openEditAddress = selectedAddressId => {
        setErrorAddressState({});
        setAddressDetails(true)
        const editedAddress = _.find(addresses, { 'id': selectedAddressId });
        if (editedAddress) {
            const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack, mobile_country_code } = editedAddress;
            let newAddressFormState = {
                name: name || "",
                mobile_code: mobile_country_code || "",
                mobile: mobile || "",
                email: email || "",
                identity_no: identity_no || "",
                address: address || "",
                address2: address2 || "",
                city,
                state,
                zip,
                country,
                icFront,
                icBack
            };
            if (_.size(icFront) > 0) {
                newAddressFormState['icFront'] = icFront.file_name;
            }
            if (_.size(icBack) > 0) {
                newAddressFormState['icBack'] = icBack.file_name;
            }

            setAddressFormState(newAddressFormState);
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: selectedAddressId }));
        }
    }

    const closeModal = () => {
        setAddressDetails(false); 
        setAddressList(true);
        setState(state => ({ ...state, modalShow: false, mode: null, 'editAddressId': null }));
        setAddressFormState(INITIAL_ADDRESS_STATE);
        setAddressMobileCode({});
    };

    const submitAddress = () => {
        if (state.mode === 'add') {
            postUrl('user/address', addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        addAlert(result.message, 'success');
                        setState(state => ({ ...state, modalShow: false }));
                        setAddressDetails(false);
                        setAddressList(true);
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                            let defaultAddress = _.find(addressList.data, { 'default': true });
                            if (_.size(defaultAddress) > 0) {
                                setState(state => ({ ...state, addressId: defaultAddress.id }))
                            } else {
                                if (_.size(addressList.data)) {
                                    const firstAddress = addressList.data[0];
                                    setState(state => ({ ...state, addressId: firstAddress.id }))
                                }
                            }
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        } else {
            putUrl(`user/address/${state.editAddressId}`, addressFormState)
                .then(result => {
                    if (result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        addAlert(t('profile.fillUpRequiredAddress'));
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        const refreshMall = (state.editAddressId === state.addressId);
                        setState(state => ({ ...state, modalShow: false, mode: null, editAddressId: null }));
                        addAlert(result.message, 'success');
                        setAddressDetails(false);
                        setAddressList(true);
                        getUrl('user/address').then(addressList => {
                            setAddress(addressList.data);
                            if (refreshMall) {
                                setMallShippingRefresh(!mallShippingRefresh);
                            }
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
    }

    const noAddress = () => {
        return (
            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                {
                    addresses === null ?
                        <>
                            <CircularProgress disableShrink />
                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                        </>
                        :
                        <Typography variant="caption">{t('checkout.noAddress')}</Typography>
                }
            </Box>
        )
    }

    const addressCard = (addressItem, list = true) => {
        let selected = addressItem.id === state.addressId ? true : false;
        let bgColor = (selected && list) ? theme.palette.secondary.main : 'white';
        let txColor = (selected && list) ? 'white' : theme.palette.secondary.main;
        let clClass = (selected && list) ? 'cl-white' : 'cl-text';

        return (
            <div className='glass2 bor15 shadow-glass2 p-all-15' key={addressItem.id} style={{ color: txColor, backgroundColor: bgColor, position: "relative" }}>
                {
                    list
                    &&
                    <IconButton style={{ zIndex: 1, position: "absolute", right: 0, top: 0 }} onClick={() => {openEditAddress(addressItem.id); setAddressList(false)}} disabled={fetchingMallShipping}>
                        <FiEdit className={`fs-icon ${clClass}`} />
                    </IconButton>
                }
                <div onClick={() => {setState({ ...state, addressId: addressItem.id }); setAddressList(false)}} disabled={fetchingMallShipping} >
                    <div className='flex-sb-m w-full'>
                        <div className={`p-r-10 ${clClass}`}>
                            <p className='fs-19' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }}><b>{`${addressItem.name || '-'}`}</b></p>
                            <p className='fs-14' style={{ whiteSpace: 'nowrap' }}><b>{`${addressItem.mobile_country_code !== null ? `${addressItem.mobile_country_code} - ` : ''} ${addressItem.mobile || '-'}`}</b></p>
                        </div>
                    </div>
                    <p className={`fs-14 p-t-10 ${clClass}`} style={{ textAlign: 'justify' }}>
                        {` ${addressItem.address}, ${addressItem.address2_display}, ${addressItem.city_display}, ${addressItem.state_display}, ${addressItem.zip}, ${addressItem.country_display}`}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='w-full'>
                <div className='flex-sb-m w-full cl-label'>
                    <div className='flex-m'>
                        <FiMapPin className="fs-icon-small" />
                        <p className='txt-center fs-title p-l-10'>{t('checkout.shippingAddress')}</p>
                    </div>
                    <div className='pointer' onClick={() => setAddressList(true)}><FiEdit className='fs-icon-small' /></div>
                    {/* <p className='fs-title pointer p-tb-5 p-lr-15'  style={{ whiteSpace: 'nowrap' }}>{t('button.changeAddress')}</p> */}
                    {/* onClick={handleClickOpen}  */}
                </div>
                <div className='w-full p-t-15'>
                    {
                        _.size(addresses) > 0 ?
                            _.map(addresses, (addressItem) => {
                                if (state.addressId == addressItem.id) {
                                    return addressCard(addressItem, false);
                                }
                            })
                            :
                            noAddress()
                    }
                </div>
            </div>
            <AddressDetails addressOpen={addressDetails} setAddressOpen={setAddressDetails} handleAddressChange={handleAddressChange} addressMobileCode={addressMobileCode} addressFormState={addressFormState} errorAddressState={errorAddressState} state={state} countries={countries} cities={cities} district={district} addressGroup={addressGroup} addressState={addressState} shouldUploadIC={shouldUploadIC} icFrontRef={icFrontRef} icBackRef={icBackRef} submitAddress={submitAddress} />
            <Drawer
                anchor="bottom"
                open={addressList}
                onClose={() => setAddressList(false)}>
                <div className='p-lr-20 p-t-20' style={{ width: 'auto', maxHeight: '85vh', overflowY: 'auto'}}>
                    <div className='w-full cl-text flex-sb-m p-b-24'>
                        <p className='fs-header'>{t('button.changeAddress')}</p>
                        <div className='translateX pointer' onClick={() => setAddressList(false)}>
                            <FiX className='clblack fs-icon'/>
                        </div>
                    </div>
                    <div className='p-lr-20 p-b-20'>
                    {
                        _.size(addresses) > 0 ?
                            _.map(addresses, (addressItem) => {
                                return (
                                    <div key={addressItem.id} style={{ marginBottom: 15 }}>
                                        {addressCard(addressItem)}
                                    </div>
                                )
                            })
                            :
                            noAddress()
                    }
                        <div className='pointer bor15 btn-outlined-black w-full' onClick={() => { openAddAddress(); setAddressList(false); }} disabled={fetchingMallShipping}>
                            <div className='translateY w-full flex-c-m p-all-10'>
                            <FiPlus className='fs-icon' />
                            <p className='p-l-15'>{t('button.addAddress')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <Button autoFocus onClick={() => {handleClose(); setAddressList(false) }} color="primary" variant="contained">
                        {t('button.close')}
                    </Button> */}
                </div>
            </Drawer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: 5
    },
    dialogContentRoot: {
        padding: theme.spacing(2),
    },
    dialogActionRoot: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
}));
