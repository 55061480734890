import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getUrl, postUrl, deleteUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';
import PropTypes from 'prop-types';

//MUI   
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container,  Link , Grid , Button, Tabs, Tab } from '@material-ui/core';

//ICON
// import { FiChevronRight, FiShoppingBag } from "react-icons/fi";
// import { LuGift } from "react-icons/lu";
import { BiSolidCategory } from "react-icons/bi";


//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
import ProductItemCard from '@components/ProductItemCard';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            <p>{children}</p>
          </div>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-prevent-tab-${index}`,
      'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
  }
  

export default function MyWishlists() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken , currencyDisplay , currency } = useSelector(state => ({
        ...state.general,
        currency: state?.general?.currency ? state.general.currency : process.env.REACT_APP_DEFAULT_CURRENCY,
    }));
    
    const styles = useStyles();
    const isMountedRef = useRef(null);

    const [recommend, setRecommend] = useState([]);
    const [categories, setCategories] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    // ------------- Tabs ---------------
    const [value, setValue] = useState('all');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ------------- APIs ---------------
    useEffect(() => {
        let apiData = {
            'cat': value === 0 ? 'all' : value,
            'page': page, 
            'per_page':  6, 
        };

        getUrl(`get_my_wishlists`, apiData).then(response => {
            if (response.status) {
                setTotalPage(response.products.last_page);
                setRecommend(response.data);
                // setRecommend(_.concat(recommend, response.data));
            }
        }).catch((error) => {
            addAlert(JSON.stringify(error.message));
        });

        // eslint-disable-next-line
    }, [page, value]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('categories?level=1').then(categoryList => {
            setCategories(categoryList.data);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    // ------------- Functions ---------------
    const loadTransaction = () => {
        setPage(page + 1);
    }

    // ------------- Components ---------------
    const productList = () => {
        return (
            <>
                {
                    _.size(recommend) > 0
                        ?
                        <>
                            <div>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="off"
                                    label="scrollable prevent tabs example"
                                    id="category"
                                    indicatorColor='transparent'
                                >
                                    <Tab label={t('title.recommend')} style={{minHeight:40}} value={'all'} {...a11yProps(0)} />{/* icon={<BiSolidCategory />} */}
                                    {
                                        _.map(categories, category => (
                                            <Tab key={category?.id} style={{minHeight:40}} label={i18n.language ? category?.name_cn : category?.name_en} value={category?.id} {...a11yProps(category?.id)} />
                                        ))
                                    }
                                </Tabs>
                            </div>
                            <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                                {
                                    _.map(recommend, product => {
                                        return (
                                            <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                                <ProductItemCard product={product} currency={currency} currencyCode={currency_code} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <div className='p-tb-15'>
                                {
                                    page < totalPage
                                        ? <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                                        : <p className='txt-center fs-content'>{t('transaction.endOfList')}</p>
                                
                                }
                            </div>
                        </>
                        : 
                        <div className='cl-text'>
                            <div className='max-w-full' style={{ width: 150, margin: '0 auto' }}>
                                <img src="/images/empty/favourite.png" alt="favourite" />
                            </div>
                            <p className='fs-title txt-center' style={{fontWeight:'normal'}}>{t('wishlist.empty')}</p>
                            <Link underline='none' to={`/`} className='flex-c-m p-t-10 translateY' component={RouterLink} style={{ width: 250, margin: '0 auto', maxWidth: '100%' }}>
                                <div className='btn-theme'>
                                    <p className='fs-button'>{t('wishlist.goShopping')}</p>
                                </div>
                            </Link>
                        </div>
                }
            </>
        )
    }

    return (

        <div>
            <TitleBar back displayCart backgroundColor="bg-header" />
            <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
                <div className='subtitle-box clblack p-b-24'>
                    <p className='txt-center fs-header'>{t('wishlist.title')}</p>
                    {_.size(recommend) > 0 ?
                        <p className='fs-subheader'>{t('wishlist.subtitle')}</p>
                        :
                        null
                    }
                </div>
                {productList()}
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        maxWidth: '100%'
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
}));