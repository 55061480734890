import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// import { loginUser } from '@helper/ApiAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { authSuccess, authFail, updateShareLinkRef, changeLanguage as reduxChangeLanguage } from '@actions';
import { postUrl, getUrl } from '@helper/ApiAction';
import useNotificationLoading from '../../helper/useNotificationLoading';
import { useQuery } from '@helper/Tools';

import axios from 'axios';
import jwt_decode from "jwt-decode";

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Button, Typography, Link } from '@material-ui/core';

//ICON
import { FaCheck } from "react-icons/fa6";


//COMPONENTS & LAYOUT
import TitleBar from '@layouts/TitleBar';
import RegisterForm from '../../layouts/RegisterForm';


export default function Register() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    const queryPlacement = query.get('placement') ? query.get('placement') : "";
    const queryPosition = query.get('position') ? query.get('position') : 0;
    const queryName = query.get("name") ? query.get("name") : "";
    const queryUsername = query.get("username") ? query.get("username") : "";
    const queryMobile = query.get("mobile") ? query.get("mobile") : "";
    const queryNric = query.get("nric") ? query.get("nric") : "";
    const queryEmail = query.get("email") ? query.get("email") : "";
    const source = query.get("src") ? query.get("src") : "";
    const sourceUserId = query.get("uid") ? query.get("uid") : "";

    const isMountedRef = useRef(null);
    const [data, setData] =useState({
        name: queryName,
        username: queryUsername,
        email: queryEmail,
        mobile: queryMobile,
        nric: queryNric,
        placement: queryPlacement,
        position: queryPosition,
        referral: '',
        autoPlacement: queryPlacement ? false : true,
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const { accessToken, linkReferralCode } = useSelector(state => state.general);
    const [refId, setRefId] = useState("");


    useEffect(() => {
        if (accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    useEffect(() => {
        isMountedRef.current = true;
        if (encoded_ref_id) {
            getUrl(`ref_username/${encoded_ref_id}`).then(response => {
                if (isMountedRef.current) {
                    if (response.data.ref_id) {
                        setRefId(response.data.ref_id);
                        setData({...data , referral:response.data.ref_username});
                    }
                }

            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
    }, [encoded_ref_id]);

    useEffect(() => {
        if (!encoded_ref_id && linkReferralCode) {
            history.replace(`register?r=${linkReferralCode}`);
        }
        if (encoded_ref_id == linkReferralCode) {
            dispatch(updateShareLinkRef(""));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, linkReferralCode]);

    const loginUser = () => {
        setLoading(true);
        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: data?.username,
            password: data?.password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/oauth/token`, loginState)
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    const decoded_jwt = jwt_decode(response.data.access_token);
                    const userRole = decoded_jwt ? decoded_jwt.role : [];
                    if (_.size(_.intersection(userRole, ['member']))) {
                        dispatch(authSuccess(response.data));
                    } else {
                        addAlert(t('general.incorrectUsernamePassword'));
                    }
                }
            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    addAlert(t('general.incorrectUsernamePassword'));
                } else if (error.response && error.response.status === 401) {
                    let errorResponse = JSON.parse(error.request.response);
                    addAlert(errorResponse.message);
                } else {
                    addAlert(JSON.stringify(error.message));
                }
                dispatch(authFail());
            });
    }

    const linkUser = () => {
        setLoading(true);
        let params = {
            username: data?.username,
            source: source,
            source_user_id: sourceUserId,
            source_username: queryUsername,
        }
        postUrl('account-link/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                loginUser();
            } else {
                if (response.error) {
                    addAlert(JSON.stringify(_.map(response.error).join(' ')));
                } else {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back />
            <div className='bg-theme p-b-30 header-radius txt-center'>
                <div style={{ width: 350, maxWidth: '80%', margin: '0 auto' }}>
                    <p className='txt-center fs-17 p-b-3 clwhite p-t-30'><b>{t('title.register')}</b></p>
                    <p className='cl-label fs-14 lh-11'>{t('register.subtitle')}</p>
                </div>
            </div>

            <Container>
                <div>
                    {
                        successMessage ?
                            <div className='glass2 shadow-glass2 bor15 p-all-15 m-t-25'>
                                <div className='bor50 p-all-5' style={{ width: 40, height: 40, background: '#169987a8', margin: '0 auto' }}>
                                    <div className='w-full h-full bor50 flex-c-m' style={{ background: '#169987a8' }}>
                                        <FaCheck className='fs-21 clwhite' />
                                    </div>
                                </div>
                                <div className='p-tb-25'>
                                    <p className='txt-center cl-label fs-15'>{successMessage}</p>
                                </div>
                                <div>
                                    <Link style={{ textDecoration: 'none' }} to="/login" component={RouterLink} >
                                        <Button /* onClick={() => loginUser(dispatch)} */ fullWidth variant="contained" color='primary' size="large">{t('button.login')}</Button>
                                    </Link>
                                </div>
                            </div>
                            :
                            <div className='p-t-25'>
                                <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                                <RegisterForm setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} data={data} refId={refId}  setData={setData} />
                                 {/* <Grid container spacing={3} justify="center" style={{ marginTop: 20, padding: '0 15px' }}>
                                        <Link underline='none' onClick={() => changeLanguage('en')} component={RouterLink} style={{ marginRight: 5 }}>
                                            <Typography>{t('Eng')}</Typography>
                                        </Link>
                                        <p>{t('|')}</p>
                                        <Link underline='none' onClick={() => changeLanguage('cn')} component={RouterLink} style={{ marginLeft: 5 }}>
                                            <Typography>{t('中文')}</Typography>
                                        </Link>
                                    </Grid> */}
                            </div>
                    }
                </div>
                
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    }
}));