import React, { useState, useRef, useEffect } from 'react';

import { CircularProgress, Grid, Typography, Box, Card, CardContent, Container, Paper, Tabs, Tab, Select, MenuItem, IconButton, Button, CardHeader, CardMedia, Tooltip, List, ListItemText, ListItemIcon, ListItem, Divider, Hidden, Link } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import NumberFormat from 'react-number-format';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getUrl } from '@helper/ApiAction';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import useNotificationLoading from '../../helper/useNotificationLoading';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import { List as ListIcon, ChevronRight, ChevronLeft } from 'react-feather';
import { BiSolidCategory } from "react-icons/bi";
import { RiHeartAddFill } from "react-icons/ri";
import { useQuery } from '@helper/Tools';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import WebpImg from '@layouts/WebpImg';
import TitleBar from '@layouts/TitleBar';
import ProductItemCard from '@components/ProductItemCard';

export default function Shop() {
    const styles = useStyles();
    const theme = useTheme();
    const categoryRefs = useRef([]);
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { currency, currencyDisplay } = useSelector(state => ({
        ...state.general,
        currency: state?.general?.currency ? state.general.currency : process.env.REACT_APP_DEFAULT_CURRENCY,
    }));

    let { shopSlug } = useParams();
    let query = useQuery();
    const history = useHistory();
    const pageNumber = query.get("page");
    const catId = query.get("cat") || 'all';
    const collectionId = query.get("collection");
    const sortBy = query.get("sort");

    const [state, setState] = useState({ tabIndex: 0 });

    const [shopInfo, setShopInfo] = useState({});
    const [shopCategory, setShopCategory] = useState({});
    const [shopCategoryProduct, setShopCategoryProduct] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    // const [currencyDisplay, setBaseCurrencyDisplay] = useState('');
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [reviewPage, setReviewPage] = useState(1);
    const [reviewTotalPage, setReviewTotalPage] = useState(1);

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/info`)
            .then(returnShopInfo => {
                setLoading(false);
                if (isMountedRef.current && returnShopInfo.status === 1) {
                    setShopInfo(returnShopInfo.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                    history.push(`/`);
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        if (collectionId && _.size(shopInfo.collections) > 0) {
            const selectedCollection = _.findKey(shopInfo.collections, { id: parseInt(collectionId) });
            if (selectedCollection) {
                setState({ ...state, tabIndex: parseInt(selectedCollection) + 2 });
            }
        }
        // eslint-disable-next-line
    }, [collectionId, shopInfo.collections])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/category`)
            .then(returnShopCategory => {
                setLoading(false);
                if (isMountedRef.current && returnShopCategory.status === 1) {
                    setShopCategory(returnShopCategory.data);
                } else {
                    addAlert(t('shop.invalidMerchant'));
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, setLoading, addAlert])

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`shops/${shopSlug}/products?cat=${catId ? catId : 'all'}&page=${pageNumber ? pageNumber : 1}&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`)
            .then(returnShopProduct => {
                setLoading(false);
                if (isMountedRef.current && returnShopProduct.status === 1) {
                    let { current_page, last_page } = returnShopProduct.data.meta;
                    setShopCategoryProduct(returnShopProduct.data.data);
                    setTotalPage(last_page);
                    setPage(current_page ? current_page : 1);

                    // if (_.size(returnShopProduct.currency_data) > 0) {
                    //     // console.log("returnShopProduct.currency_data", returnShopProduct.currency_data);
                    //     setBaseCurrencyDisplay(returnShopProduct.currency_data[`currency_display_${i18n.language}`]);
                    // }
                }
            })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [shopSlug, catId, pageNumber, addAlert, setLoading, collectionId, sortBy])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`shops/${shopSlug}/review?sort=${reviewSort}&page=${reviewPage}`)
            .then(reviewData => {
                console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setReviewTotalPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [shopSlug, reviewSort, reviewPage, setLoading, addAlert])

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });
        if (newValue === 0) {
            history.push(`/shop/${shopSlug}`)
        } else if (newValue === 1) {
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=&sort=${sortBy ? sortBy : 'popular'}`)
        } else if (newValue !== 999 && newValue > 1 && _.size(shopInfo.collections) > 0) {
            // collection page 
            const selectedCollection = shopInfo.collections[newValue - 2];
            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${selectedCollection.id}&sort=${sortBy ? sortBy : 'popular'}`)
        }
    };

    const handleCategoryChange = (value) => {
        if (categoryRefs.current[value]) {
            categoryRefs.current[value].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
        history.push(`/shop/${shopSlug}?cat=${value}&page=1&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`);
    };

    let currency_code = [];
    _.map(currencyDisplay, (currency_detail) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    const renderReview = () => {
        return (
            <div className='p-t-24'>
                <div className='p-t-24'>
                    <p className='fs-title p-b-10'>{t('shop.sellerRatingsReviews')}</p>
                    <div className='flex-sb-m w-full'>
                        <Button
                            color={reviewSort === `all` ? 'primary' : 'default'}
                            className={styles.buttonStyle}
                            style={{ borderColor: reviewSort === 'all' ? theme.palette.primary.main : '#dadada' }}
                            onClick={() => {
                                setReviewPage(1)
                                setReviewSort('all')
                            }}
                        >
                            <div className='w-full flex-c-m'>
                                <div className='flex-c-m w-full'>
                                    <p className='fs-remark'>{t('button.all')} {`(${shopInfo.shop_average_review.all_count})`}</p>
                                </div>
                            </div>
                        </Button>

                        {
                            _.map([3, 2, 1], satisfactionSort => {
                                return (
                                    <Button
                                        key={`${satisfactionSort}score`}
                                        color={reviewSort === `${satisfactionSort}score` ? 'primary' : 'default'}
                                        className={styles.buttonStyle}
                                        style={{ borderColor: reviewSort === satisfactionSort ? theme.palette.primary.main : '#dadada' }}
                                        onClick={() => {
                                            setReviewPage(1)
                                            setReviewSort(satisfactionSort)
                                        }}
                                    >
                                        <div className='w-full flex-c-m'>
                                            {
                                                satisfactionSort === 3 ?
                                                    <div className='flex-c-m w-full'>
                                                        <SentimentVerySatisfiedIcon style={{ color: reviewSort === 3 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                        <p className='fs-remark'>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[3]})`}</p>
                                                    </div>
                                                    : satisfactionSort === 2 ?
                                                        <div className='flex-c-m w-full'>
                                                            <SentimentSatisfiedIcon style={{ color: reviewSort === 2 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                            <p className='fs-remark'>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[2]})`}</p>
                                                        </div>
                                                        : satisfactionSort === 1 ?
                                                            <div className='flex-c-m w-full'>
                                                                <SentimentVeryDissatisfiedIcon style={{ color: reviewSort === 1 ? theme.palette.primary.main : theme.palette.gray.main }} />
                                                                <p className='fs-remark'>{`(${shopInfo?.shop_average_review?.satisfaction_count?.[1]})`}</p>
                                                            </div>
                                                            : null
                                            }
                                            {/* <Typography className={ styles.sortingTitle }>{`(${productReviewSummary.star_count[satisfactionSort]})`}</Typography> */}
                                        </div>
                                    </Button>
                                )
                            })
                        }
                    </div>
                    <div className='p-tb-24'>
                        {
                            _.size(review) > 0
                                ? _.map(review, reviewItem => {
                                    return (
                                        <div key={reviewItem?.id} className='bor15 m-b-20 bgwhite p-all-15'>
                                            <div className='flex-sb-m w-full'>
                                                <div className='flex-m'>
                                                    {
                                                        reviewItem?.rating === 3 ?
                                                            <SentimentVerySatisfiedIcon color="primary" fontSize="large" />
                                                            : reviewItem?.rating === 2 ?
                                                                <SentimentSatisfiedIcon color="primary" fontSize="large" />
                                                                : reviewItem?.rating === 1 ?
                                                                    <SentimentVeryDissatisfiedIcon color="primary" fontSize="large" />
                                                                    : null
                                                    }
                                                    <p className='p-l-10'>{reviewItem?.anonymous ? `${reviewItem?.user?.name?.[0]}*****` : reviewItem?.user?.name}</p>
                                                </div>
                                                <p className='fs-content'>{moment(reviewItem?.created_at).format('YYYY-MM-DD HH:mm A')}</p>
                                            </div>
                                            {
                                                _.size(reviewItem?.comment) > 0 &&
                                                <div className='p-t-5'>
                                                    <p className='fs-content'>
                                                        {reviewItem?.comment}
                                                    </p>
                                                </div>
                                            }
                                            {
                                                _.size(reviewItem?.response) > 0 ?
                                                    <div className='bg-label p-all-15 bor15'>
                                                        <div>
                                                            <p>{t('item.merchantReply')}</p>
                                                            <p>{moment(reviewItem?.updated_at).format('YYYY-MM-DD HH:mm A')}</p>
                                                        </div>
                                                        <p className='fs-content'>
                                                            {reviewItem?.response}
                                                        </p>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    )
                                })
                                : <div>
                                    <div className='empty-img'>
                                        <img src="/images/empty/data.png" className='w-full' alt="empty" />
                                    </div>
                                    <p className='txt-center fs-content'>{t('shop.noRatingReview')}</p>
                                </div>
                        }
                        
                        {
                            _.size(review) > 0 &&
                            <div className='flex-c'>
                                <Pagination count={reviewTotalPage} shape="rounded" page={page}
                                    onChange={(event, value) => setReviewPage(value)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                        />
                                    )}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderShopInfo = () => {
        return (
            <div>
                <div>
                    {
                        // _.size(shopInfo.merchant_company_icon) > 0 ?
                        //     <div className='flex-c-m p-all-20'>
                        //         {/* <WebpImg containerStyle={{ width: 120, AspectRatio:'1/1'}} src={shopInfo.merchant_company_icon.file_name} style={{objectFit: 'cover'}} alt={`${shopInfo[`shop_name_${i18n.language}`]}`} /> */}
                        //         <div className='bor15 p-all-5 bg-base shadow-merchant' style={{ width: 120, aspectRatio: '1/1', objectFit: 'cover' }}>
                        //             <img src={shopInfo.merchant_company_icon?.file_name} alt="merchant" className='w-full bor10' />
                        //         </div>
                        //     </div>
                        //     :
                        //     <div className='flex-c-m p-all-20'>
                        //         {/* <div className='flex-c-m'>
                        //             <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src="/images/empty/shop-nologo2.png" style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 120 }} alt="shoplogo-default" />
                        //         </div> */}
                        //         <div className='bor15 p-all-5 bg-base shadow-merchant' style={{ width: 120, margin: '0 auto', aspectRatio: '1/1', objectFit: 'cover', lineHeight: 0 }}>
                        //             <img src={_.size(shopInfo.merchant_company_icon) > 0 ? shopInfo.merchant_company_icon?.file_name : "/images/example/merchant/4.png"} alt="merchant" className='w-full bor10' />
                        //         </div>
                        //     </div>
                    }
                    <div className='flex-c-m p-all-20'>
                        {/* <div className='flex-c-m'>
                            <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src="/images/empty/shop-nologo2.png" style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 120 }} alt="shoplogo-default" />
                        </div> */}
                        <div className='bor15 p-all-5 bg-base shadow-merchant' style={{ width: 120, height: 120, margin: '0 auto', aspectRatio: '1/1', objectFit: 'cover', lineHeight: 0 }}>
                            <WebpImg src={_.size(shopInfo.merchant_company_icon) > 0 ? shopInfo.merchant_company_icon?.file_name : "/images/empty/no-image-theme.png"} alt="merchant" className='bor10' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='p-t-15 txt-center'>
                        <p className='fs-header' style={{wordBreak: "break-word"}}>{shopInfo[`shop_name_${i18n.language}`]}</p>
                        <p className='fs-content'>
                            {shopInfo[`about_${i18n.language}`]}
                        </p>
                    </div>
                    <div className='p-t-20 flex-sa-m w-full'>
                        <div className='txt-center'>
                            <p className='fs-digit clblack'>
                                <NumberFormat value={shopInfo?.total_product > 0 ? shopInfo?.total_product : 0} displayType={'text'} thousandSeparator={true} />
                            </p>
                            <p className='fs-content cl-label txt-center'>{t('shop.product')}</p>
                        </div>
                        <div className='txt-center'>
                            <p className='fs-digit clblack'>{_.size(shopInfo) > 0 ? shopInfo?.shop_average_review?.positive_score > 0 ? (shopInfo?.shop_average_review?.positive_score).toFixed(2) : 0 : 0}<span style={{ fontSize: 18, paddingLeft: 5 }}>%</span></p>
                            <p className='fs-content cl-label txt-center'>{t('shop.shopRating')}</p>
                        </div>
                        <div className='txt-center'>
                            <div className='flex-b'>
                                <p className='fs-digit clblack'>{shopInfo?.joined_day > 31 ? shopInfo?.joined_month : shopInfo?.joined_day}</p>
                                <p className='fs-content clblack lh-21 p-l-5'>{shopInfo?.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}</p>
                            </div>
                            <p className='fs-content cl-label txt-center'>{t('shop.joined')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
        <TitleBar currencyButton displayCart back backgroundColor="bg-theme" />
        <div className='bg-header p-b-50'></div>
            <Container className='bg-base subheader-radius'>
               
                {
                    _.size(shopInfo.merchant_company_banners) > 0 &&
                    <div className='p-b-24'>
                    <AutoplaySlider
                        play={true}
                        cancelOnInteraction={false}
                        interval={3000}
                        bullets={false}
                        className="shop"
                    >
                        {
                            _.map(shopInfo.merchant_company_banners, bannerItem => {
                                return (
                                    <div key={bannerItem.id}>
                                        <WebpImg className="bor15" src={bannerItem?.file_name} alt={bannerItem?.file_name} style={{ objectFit: 'cover', display: 'block', width: '100%' }} containerStyle={{ height: '100%', width: '100%' }} />
                                    </div>
                                )
                            })
                        }
                    </AutoplaySlider>
                    </div>
                }
            </Container>

            <div >
                <Tabs
                    value={state.tabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    className='bg-base p-lr-24'
                    style={{ position: 'sticky', top: 0, zIndex: 3, boxShadow: 'rgb(0 0 0 / 2%) -1px 12px 12px 0px' }}

                >
                    <Tab label={t('shop.home')} disableRipple />
                    <Tab label={t('shop.allProduct')} disableRipple />
                    {
                        _.size(shopInfo.collections) > 0 ?
                            _.map(shopInfo.collections, (collectionItem, collectionIndex) => {
                                return (
                                    <Tab key={collectionIndex} value={collectionIndex + 2} label={collectionItem[`title_${i18n.language}`]} disableRipple />
                                )
                            })
                            : null
                    }
                    <Tab label={t('shop.info')} value={999} disableRipple />
                </Tabs>
                <Container className='p-t-24'>
                {
                    state.tabIndex === 0 &&
                    <div>
                        {renderShopInfo()}
                    </div>
                }
                {
                    state.tabIndex === 0 &&
                    <div>
                        {
                            _.size(shopInfo.collections) > 0 ?
                                // only takes first 2
                                _.map(_.slice(shopInfo.collections, 0, 2), collectionItem => {
                                    // console.log("collectionItem", collectionItem)
                                    return (
                                        <div key={collectionItem.id}>
                                            <div className='flex-sb-m w-full'>
                                                <p className='fs-title'>{collectionItem[`title_${i18n.language}`]}</p>
                                                <Link underline='none' color="inherit" to={`/shop/${shopSlug}?cat=all&page=1&collection=${collectionItem.id}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                                    <p className='fs-content'>{`${t('shop.seeAll')} >`}</p>
                                                </Link>
                                            </div>
                                            <Grid container spacing={2}>
                                                {
                                                    _.size(collectionItem.product_ids_array) > 0 ?
                                                        _.map(_.slice(collectionItem.product_ids_array, 0, 6), productItem => {
                                                            let showRetail = false;
                                                            let discountPercent = 0;
                                                            if (parseFloat(productItem.retail_price) > 0 && parseFloat(productItem.retail_price) > parseFloat(productItem.sell_price)) {
                                                                showRetail = true;
                                                                discountPercent = ((parseFloat(productItem.retail_price) - parseFloat(productItem.sell_price)) / parseFloat(productItem.retail_price)) * 100;
                                                                discountPercent = Math.round(discountPercent);
                                                            }

                                                            return (
                                                                <Grid item xs={6} key={productItem.id}>
                                                                    {/* <Link underline='none' to={`/product/${productItem.id}`} component={RouterLink}>
                                                                        <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                                            <CardMedia style={{ padding: 10 }}>
                                                                                <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={productItem ? productItem.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight: 150 }} alt={productItem[`title_${i18n.language}`]} />
                                                                            </CardMedia>
                                                                            <CardContent style={{ padding: '0 10px 24px' }}>
                                                                                <Tooltip title={i18n.language === 'cn' ? productItem['title_cn'] : productItem['title_en']}>
                                                                                    <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                                                        {i18n.language === 'cn' ? productItem['title_cn'] : productItem['title_en']}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                                {
                                                                                    showRetail ?
                                                                                        <Typography align="center" variant="body2" style={{ fontSize: 10, height: 18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                                            <NumberFormat value={productItem.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                        </Typography>
                                                                                        : <Typography style={{ height: 18 }}></Typography>
                                                                                }
                                                                                <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                                    <NumberFormat value={productItem.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                </Typography>
                                                                            </CardContent>
                                                                            {
                                                                                showRetail ?
                                                                                    <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                                        <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                                    </Box>
                                                                                    : null
                                                                            }
                                                                            <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor: '#f75757' }}>
                                                                                {
                                                                                    _.map(productItem.ipoint, (idata, key) => {
                                                                                        if (idata.warehouse_id === 0) {
                                                                                            return (
                                                                                                <Typography key={key} style={{ color: '#fff', fontSize: 16, }} variant="body2"><NumberFormat value={parseFloat(idata.ipoint)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Box>
                                                                        </Card>
                                                                    </Link> */}
                                                                    <ProductItemCard product={productItem} currency={currency} currencyCode={currency_code} />
                                                                </Grid>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </Grid>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                }
                {
                    state.tabIndex === 999 ?
                        <div>
                            {renderShopInfo()}
                            {renderReview()}
                        </div>
                        :
                        <div>
                            {/* <div>
                                <List>
                                    <ListItem>
                                        <ListItemIcon style={{ minWidth: 20 }}>
                                            <ListIcon color="#000" size={14} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('shop.category')} />
                                    </ListItem>
                                    <Divider />
                                    <Link underline='none' color="inherit" to={`/shop/${shopSlug}?cat=all&page=1&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                        <ListItem style={{ padding: '5px 30px 5px 30px' }}>
                                            {
                                                !catId || catId === 'all' ?
                                                    <ListItemIcon style={{ minWidth: 20 }}>
                                                        <ChevronRight color={theme.palette.primary.main} size={14} />
                                                    </ListItemIcon>
                                                    : null
                                            }
                                            <Typography variant="body1" style={{ fontWeight: (!catId || catId === 'all') ? 'bold' : 'normal', fontSize: (!catId || catId === 'all') ? 14 : 12, color: (!catId || catId === 'all') ? theme.palette.primary.main : '#000' }}>{t('shop.allProduct')}</Typography>
                                        </ListItem>
                                    </Link>
                                    {
                                        _.size(shopCategory) > 0 ?
                                            _.map(shopCategory, (categoryItem, categoryIndex) => {
                                                return (
                                                    <Link key={categoryIndex} underline='none' color="inherit" to={`/shop/${shopSlug}?cat=${categoryIndex}&page=1&collection=${collectionId}&sort=${sortBy ? sortBy : 'popular'}`} component={RouterLink}>
                                                        <ListItem style={{ padding: '5px 30px 5px 30px' }}>
                                                            {
                                                                catId === categoryIndex ?
                                                                    <ListItemIcon style={{ minWidth: 20 }}>
                                                                        <ChevronRight color={theme.palette.primary.main} size={14} />
                                                                    </ListItemIcon>
                                                                    : null
                                                            }
                                                            <Typography variant="body1" style={{ fontWeight: catId === categoryIndex ? 'bold' : 'normal', fontSize: catId === categoryIndex ? 14 : 12, color: catId === categoryIndex ? theme.palette.primary.main : '#000' }}>{i18n.language === 'cn' ? categoryItem.name_cn : categoryItem.name_en}</Typography>
                                                        </ListItem>
                                                    </Link>
                                                )
                                            })
                                            : null
                                    }
                                </List>
                            </div> */}
                            {/* <div className='flex-row w-full p-t-24'>
                                <Button
                                    size="medium"
                                    style={{ fontSize: 12, flex: 1 }}
                                    disableElevation
                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=popular`)}
                                >
                                    <Typography color={!sortBy || sortBy === 'popular' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.popular')}</Typography>
                                </Button>
                                <Button
                                    size="medium"
                                    style={{ fontSize: 12, flex: 1 }}
                                    disableElevation
                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=latest`)}
                                >
                                    <Typography color={sortBy === 'latest' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.latest')}</Typography>
                                </Button>
                                <Button
                                    size="medium"
                                    style={{ fontSize: 12, flex: 1 }}
                                    disableElevation
                                    onClick={() => history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=topSales`)}
                                >
                                    <Typography color={sortBy === 'topSales' ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.topSales')}</Typography>
                                </Button>
                                <Button
                                    size="medium"
                                    style={{ fontSize: 12, flex: 1 }}
                                    disableElevation
                                    onClick={() => {
                                        const priceSort = sortBy === 'priceDesc' ? 'priceAsc' : 'priceDesc';
                                        history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=${priceSort}`)
                                    }}
                                    endIcon={
                                        sortBy === 'priceDesc' ?
                                            <KeyboardArrowDownIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                            : <KeyboardArrowUpIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                    }
                                >
                                    <Typography color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.price')}</Typography>
                                </Button>
                            </div> */}
                            <div className='flex-l-m p-tb-24 scroll-container' style={{ overflowX: 'scroll' }}>
                                        <div ref={(e) => (categoryRefs.current['all'] = e)} onClick={() => handleCategoryChange('all')} className={`${catId === 'all' ? styles.categorySelectedButtonDesign : styles.categoryButtonDesign} flex-c-m translateY p-tb-4 p-lr-10 m-r-10`}>
                                            <BiSolidCategory className='fs-16' />
                                            <p className='p-tb-4 p-lr-6 fs-14 txt-upper' style={{ fontWeight: 'bold' }}>{t('shop.allProduct')}</p>
                                        </div>

                                        {
                                            _.size(shopCategory) > 0 &&
                                            _.map(shopCategory, (detail, idx) => (
                                                <div ref={(e) => (categoryRefs.current[detail?.id] = e)} key={idx} onClick={() => handleCategoryChange(detail?.id)} className={`${detail?.id === parseInt(catId) ? styles.categorySelectedButtonDesign : styles.categoryButtonDesign} flex-c-m p-tb-4 p-lr-10 m-r-10 translateY`}>
                                                    <RiHeartAddFill className='fs-16' />
                                                    <p className='p-tb-4 p-lr-6 fs-14 txt-upper' style={{ fontWeight: 'bold' }}>{i18n.language === 'cn' ? detail?.name_cn : detail?.name_en}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div style={{ textAlign: 'end' }}>
                                    <Button
                                        size="medium"
                                        style={{ fontSize: 12 }}
                                        disableElevation
                                        onClick={() => {
                                            const priceSort = sortBy === 'priceDesc' ? 'priceAsc' : 'priceDesc';
                                            history.push(`/shop/${shopSlug}?cat=${catId ? catId : 'all'}&page=1&collection=${collectionId}&sort=${priceSort}`)
                                        }}
                                        endIcon={
                                            sortBy === 'priceDesc' ?
                                                <KeyboardArrowDownIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                                : <KeyboardArrowUpIcon color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} size={12} />
                                        }
                                    >
                                        <Typography color={_.includes(['priceDesc', 'priceAsc'], sortBy) ? 'primary' : 'inherit'} style={{ fontSize: 12 }} variant="button">{t('shop.price')}</Typography>
                                    </Button>
                                </div>
                            <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                                {
                                    _.size(shopCategoryProduct) > 0 ?
                                        _.map(shopCategoryProduct, categoryProduct => {
                                            let showRetail = false;
                                            let discountPercent = 0;
                                            if (parseFloat(categoryProduct.retail_price) > 0 && parseFloat(categoryProduct.retail_price) > parseFloat(categoryProduct.sell_price)) {
                                                showRetail = true;
                                                discountPercent = ((parseFloat(categoryProduct.retail_price) - parseFloat(categoryProduct.sell_price)) / parseFloat(categoryProduct.retail_price)) * 100;
                                                discountPercent = Math.round(discountPercent);
                                            }
                                            return (
                                                <Grid item xs={6} key={categoryProduct.id} className={styles.cardContentRootStyle}>
                                                    {/* <Link underline='none' to={`/product/${categoryProduct.id}`} component={RouterLink}> */}
                                                    {/* <Card variant="outlined" style={{ borderRadius: '15px', position: 'relative' }}>
                                                                    <CardMedia style={{ padding: 10 }}>
                                                                        <WebpImg containerStyle={{ height: 120, maxHeight: 120, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={categoryProduct ? categoryProduct.images_array[0] : ''} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 120, maxHeight: 120 }} alt={categoryProduct[`title_${i18n.language}`]} />
                                                                    </CardMedia>
                                                                    <CardContent style={{ padding: '0px 10px 24px' }}>
                                                                        <Tooltip title={i18n.language === 'cn' ? categoryProduct['title_cn'] : categoryProduct['title_en']}>
                                                                            <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                                                {i18n.language === 'cn' ? categoryProduct['title_cn'] : categoryProduct['title_en']}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                        {
                                                                            showRetail ?
                                                                                <Typography align="center" variant="body2" style={{ fontSize: 10, height: 18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                                    <NumberFormat value={categoryProduct.retail_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                                </Typography>
                                                                                : <Typography style={{ height: 18 }}></Typography>
                                                                        }
                                                                        <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                            <NumberFormat value={categoryProduct.sell_price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyDisplay} />
                                                                        </Typography>
                                                                    </CardContent>
                                                                    {
                                                                        showRetail ?
                                                                            <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                                <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                            </Box>
                                                                            : null
                                                                    }
                                                                    <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor: '#f75757' }}>
                                                                        {
                                                                            _.map(categoryProduct.ipoint, (idata, key) => {
                                                                                if (idata.warehouse_id === 0) {
                                                                                    return (
                                                                                        <Typography key={key} style={{ color: '#fff', fontSize: 16, }} variant="body2"><NumberFormat value={parseFloat(idata.ipoint)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Box>
                                                                </Card> */}
                                                    <ProductItemCard product={categoryProduct} currency={currency} currencyCode={currency_code} />
                                                    {/* </Link> */}
                                                </Grid>
                                            )
                                        })
                                        :
                                        <Box minHeight={200} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                            {
                                                shopCategoryProduct === null ?
                                                    <CircularProgress disableShrink />
                                                    :
                                                    <p className='txt-center fs-title cl-text'>{t('general.noProduct')}</p>
                                            }
                                        </Box>
                                }
                            </Grid>
                            <div className='flex-c-m w-full p-t-24'>
                                <Pagination count={totalPage} shape="rounded" page={page}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            component={RouterLink}
                                            to={`/shop/${shopSlug}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                            {...item}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                }
                </Container>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'light',
    },
    paperRoot: {
        flexGrow: 1,
        borderWidth: 1,
        backgroundColor: 'transparent'
    },
    root: {
        padding: '10px 0 10px 0',
        // backgroundColor: 'white',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
    collectionList: {
        padding: '0px 10px 0px 10px',
    },
    collectionListFirst: {
        padding: '0px 10px 0px 0px',
    },
    collectionListLast: {
        padding: '0px 0px 0px 10px',
    },
    categoryButtonDesign: {
        cursor: 'pointer',
        padding:'4px 15px',
        background: '#E9E9E9', 
        // boxShadow: '3px 3px 4px 0px #00000012', 
        borderRadius: 38, 
        width: '100%',
        color: '#a9a9a9',
    },
    categorySelectedButtonDesign: {
        color: '#F6F7F9',
        padding:'4px 15px',
        background: '#0D0D0D',
        // boxShadow: '3px 3px 4px 0px #00000012', 
        borderRadius: 38, 
        width: '100%',
    },
    iconTitleStyle: {
        fontWeight: 'bold',
        fontSize: 12
    },
    iconDescStyle: {
        fontWeight: 'bold',
        fontSize: 12,
        marginLeft: 5
    },
    shopIcon: {
        objectFit: 'fill',
        height: 56,
        maxWidth: 102
    },
    buttonStyle: {
        width: '20%',
        backgroundColor: '#efefef',
        margin: 0,
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },

}));