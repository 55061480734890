import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { currencyFormat } from '@helper/Tools';

import { Box, Button, Container, Dialog, DialogContent, Link, List, ListItem, Slide, Typography } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';

import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import TitleBar from '@layouts/TitleBar';

export default function Setting() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [dialogInfoDetail, setDialogInfoDetail] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [maxCap, setMaxcap] = useState(0);
    const [listing, setListing] = useState([]);
    const [totalProfitLimit, setTotalProfitLimit] = useState({ profit_limit: 0, profit_limit_balance: 0 });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();

    const isMountedRef = useRef(null);

    const getApi = (page = 1) => {
        getUrl(`user/get_order_with_profit?page=${page}&per_page=10`).then(response => {
            if (response.status && isMountedRef.current) {
                const { last_page, data } = response.data;
                setTotalPage(last_page);
                if (page === 1) {
                    setListing(data);
                } else {
                    setListing(_.concat(listing, data));
                }
                setTotalProfitLimit(response.total_profit_limit || 0);
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        getUrl(`user/total/profit_limit`).then(response => {
            if (response.status && isMountedRef.current) {
                if (response.status === 1) {
                    setMaxcap(response.data)
                }

            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        getApi(page);
        return () => isMountedRef.current = false;
    }, [page]);

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogInfoDetail(dialogInfo.order)
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back displayUsername />
            <div className='bg-theme p-b-120 header-radius'>
                <div className='subtitle-box'>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('title.myPackage')}</p>
                </div>
            </div>
            <Container>
                <div>
                    <div className='glass2 shadow-out bor15 p-all-15 w-full flex-c-m' style={{ marginTop: -100, minHeight: 140 }}>
                        <div className='txt-center txt-upper'>
                            <p className='fs-digit p-b-5 cl-theme'><b>{currencyFormat(Number(maxCap).toFixed(2))}</b></p>
                            <p className='fs-content cl-text'>{t('package.availablePoint')}</p>
                            {/* {
                                (maxCap > 0) ?
                                    <Link underline='none' to={"/cap-convert"} component={RouterLink} >
                                        <Button variant="contained" className='button-theme' style={{ width: 350, maxWidth: '50%', boxShadow: 'rgb(255 255 255 / 56%) -3px -3px 3px 0px, rgba(0, 0, 0, 0.19) 3px 3px 4px 0px', borderRadius: 48, marginTop: 10 }} >
                                            {t('wallet.convert')}
                                        </Button>
                                    </Link> : null
                            } */}
                        </div>
                    </div>

                    <Box className='p-tb-16'>
                        <p className='fs-title p-b-5 m-all-0'>{t('title.transaction')}</p>
                        <List className={classes.listRoot}>
                            {_.map(listing, transactionItem => (
                                <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                            ))}
                        </List>
                        {page < totalPage ?
                            <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                            : <p className='fs-content cl-text txt-center'>{t('transaction.endOfList')}</p>
                        }
                    </Box>

                    <MuiThemeProvider theme={DialogStyle}>
                        <Dialog
                            open={dialogOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            fullWidth
                            onClose={closeDialog}
                            style={{ padding: '0 15px' }}
                        >
                            <div className='bg-dialog'>
                                <DialogContent className='w-full max-w-full p-t-20 dialog-text' style={{ width: 350, maxWidth: '100%', paddingBottom: 0, }}>

                                    <center><img src="/images/logo/logo.png" alt="logo-icon" style={{ width: 60 }} /></center>
                                    <p className='fs-header cl-theme txt-center p-b-15'>{t('package.package')} {dialogInfo.title_display}</p>

                                    <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />

                                    <div className='p-tb-15 cl-text fs-content'>
                                        <div className='flex-sb-m w-full p-b-15'>
                                            <p><b>{t('package.subcriptonId')}</b></p>
                                            <p>{dialogInfoDetail.order_no}</p>
                                        </div>
                                        <div className='flex-sb-m w-full p-b-15'>
                                            <p><b>{t('package.username')}</b></p>
                                            <p style={{ textTransform: "uppercase" }}>{dialogInfo?.order?.recipient?.name}</p>
                                        </div>
                                        <div className='flex-sb-m w-full p-b-15'>
                                            <p><b>{t('package.createDate')}</b></p>
                                            <p>{dialogInfo.created_at}</p>
                                        </div>
                                        <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />
                                        <div className='flex-sb-m w-full p-tb-15'>
                                            <p><b>{t('package.reentryCap')}</b></p>
                                            <p>+ {currencyFormat(dialogInfo.profit_limit)}</p>
                                        </div>
                                        <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />
                                        <div className='flex-sb-m w-full p-b-15 p-t-5'>
                                            <p><b>{t('package.total')}</b></p>
                                            <p>{currencyFormat(Number(dialogInfo.price * dialogInfo.quantity).toFixed(2))}</p>
                                        </div>

                                        {/* <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                    <b>{t('package.reentryCap')}</b><br />
                                    {currencyFormat(dialogInfo.profit_limit-dialogInfo.profit_limit_balance)}/{currencyFormat(dialogInfo.profit_limit)}
                                </p> */}
                                    </div>
                                </DialogContent>
                                <div className='p-b-20 flex-c-m'>
                                    <Button className='button-theme' style={{ width: 350, maxWidth: '50%', boxShadow: 'rgb(255 255 255 / 56%) -3px -3px 3px 0px, rgba(0, 0, 0, 0.19) 3px 3px 4px 0px', borderRadius: 48 }} onClick={closeDialog}>
                                        {t('transaction.done')}
                                    </Button>
                                </div>
                            </div>
                            <div style={{ marginTop: -2 }}>
                                <img src="/images/general/receipt/receipt.png" alt="receipt-texture" className='w-full' />
                            </div>
                        </Dialog>
                    </MuiThemeProvider>
                </div>
            </Container>
        </div>
    );
}

const TransactionItem = ({ data, openDialog }) => {
    const { title_display, profit_limit, profit_limit_total, created_at, order } = data;
    // const classes = useStyles();
    // const theme = useTheme();
    const { t } = useTranslation();
    return (
        <div onClick={() => openDialog(data)} className='m-b-15 glass2 shadow-glass2 pointer bor10 p-all-15'>
            <p className='p-b-8 fs-title'>{title_display}</p>
            <div className='flex-sb-m fs-content cl-text w-full'>
                <p>{t('package.reentryCap')}</p>
                <p className='txt-right'>{currencyFormat(profit_limit)} / {currencyFormat(profit_limit_total)}</p>
            </div>
            <div className='flex-sb-m fs-content w-full'>
                <p className='txt-upper'>{order['recipient'] ? order['recipient']['username'] : null}</p>
                <p className='txt-right'>{created_at}</p>
            </div>
        </div>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    listRoot: {
        width: '100%',
    },
}));

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                width: 420,
                maxWidth: '100%',
                margin: '0 auto',
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
            },

        },
        MuiDialog: {
            paper: {
                width: 320,
                maxWidth: '90%',
                minHeight: '24vh',
                padding: '0px 0 0',
                borderRadius: '15px 15px 0 0',
                background: 'transparent'
            },
            paperFullWidth: {
                width: 320,
                maxWidth: '85%',
                margin: '0 auto'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '8px 24px 24px'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        },
        MuiPaper: {
            elevation24: {
                boxShadow: 'none',
                filter: 'drop-shadow(7px 5px 5px #0005)'
            }
        }

    },
});

