import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from "@helper/useNotificationLoading";
import { storeProfile } from "@actions";
import { putUrl } from "@helper/ApiAction";

//MUI
// import { makeStyles } from '@material-ui/core/styles';
import { Container, Link, Drawer, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

//ICON
// import { LuScrollText } from "react-icons/lu";
// import { IoChatbubblesOutline } from "react-icons/io5";
import { FiChevronRight, FiSettings } from "react-icons/fi";
import { TbBinaryTree } from "react-icons/tb";
import { FiUserPlus } from "react-icons/fi";



//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


export default function Community() {
    const { t } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    // const theme = useTheme();
    // const styles = useStyles();
    const { additional_info } = useSelector((state) => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const [additionalInfoFormState, setAdditionalInfoFormState] = useState({
        ...additional_info,
    });

    const handleAutoPlacementChange = (event) => {
        setLoading(true);

        if (
            event.target.name == "auto_placement_account" &&
            event.target.value == "0"
        ) {
            setAdditionalInfoFormState({
                ...additionalInfoFormState,
                [event.target.name]: event.target.value,
                auto_placement_position: "0",
            });
        } else {
            setAdditionalInfoFormState({
                ...additionalInfoFormState,
                [event.target.name]: event.target.value,
            });
        }
        putUrl("user/additional_info", {
            [event.target.name]: event.target.value,
        }).then((result) => {
            setLoading(false);
            addAlert(result.message, result.status ? "success" : "error");
            if (result.status) {
                dispatch(storeProfile(result.data));
            }
        });
    };

    //selected text
    const autoplacementText = (value) => {
        switch (value) {
            case '0': return t('profile.auto');
            case '1': return t('profile.left');
            case '2': return t('profile.right');
            default: return null;
        }
    }

    //Drawer
    const [state, setState] = React.useState({
        communitySetting: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    return (

        <div>
            <TitleBar back btnRegister backUrl="/settings"/>

            <Container>
                <div className='p-b-20'>
                    <p className='fs-title cl-theme'><b>{t('profile.genealogy')}</b></p>
                </div>

                <div>
                    <Link underline='none' to="/community/genealogySponsor" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <TbBinaryTree className='fs-icon-small' />
                                <p className='cl-theme p-l-15 fs-content'>{t('profile.genealogy')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/recruit" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiUserPlus className='fs-icon-small' />
                                <p className='cl-theme p-l-15 fs-content'>{t('profile.recruit')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <div className='flex-sb-m w-full p-all-15 pointer' onClick={toggleDrawer('communitySetting', true)}>
                        <div className='flex-m cl-theme'>
                            <FiSettings className='fs-icon-small' />
                            <p className='p-l-15 fs-content'>{t('profile.placementSetting')}</p>
                        </div>
                        <div className="flex-m">
                            <p className="p-r-15 cl-theme fs-content">{autoplacementText(additionalInfoFormState.auto_placement_position)}
                            </p>
                            <FiChevronRight />
                        </div>
                    </div>
                </div>

            </Container>

            <Drawer anchor="bottom" open={state.communitySetting} onClose={toggleDrawer('communitySetting', false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer('communitySetting', false)}
                    onKeyDown={toggleDrawer('communitySetting', false)}
                    className='p-lr-30 p-tb-30'
                >
                    <p className='p-b-15 cl-label'>{t('profile.autoPlacementPosition')} </p>
                    <div>
                        <RadioGroup
                            aria-label="auto_placement_position"
                            column="true"
                            name="auto_placement_position"
                            value={
                                additionalInfoFormState.auto_placement_position
                            }
                            onChange={
                                handleAutoPlacementChange
                            }
                        >
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={t("profile.auto")}
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={t("profile.left")}
                            /* disabled={
                                additionalInfoFormState.auto_placement_account ==
                                "0"
                            } */
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={t("profile.right")}
                            /* disabled={
                                additionalInfoFormState.auto_placement_account ==
                                "0"
                            } */
                            />
                        </RadioGroup>
                    </div>
                </div>
            </Drawer>

        </div>
    );
}


// const useStyles = makeStyles(theme => ({



// }));


