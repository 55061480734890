import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useHistory  } from 'react-router-dom';
import _ from 'lodash';

//MUI
import { Box, AppBar, Tabs, Tab, Grid, Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { currencyFormat } from '@helper/Tools';
import { getUrl } from '@helper/ApiAction';
import { storeWalletBalance } from '@actions';
import useNotificationLoading from '@helper/useNotificationLoading';
// import AwesomeSlider from 'react-awesome-slider';
import PropTypes from 'prop-types';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function Wallet() {
    const isMountedRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { walletBalance, walletList, accessToken } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken
    }));

    const [commissionSummary, setCommissionSummary] = useState([]);
    // const [currentpage, setCurrentPages] = useState(1);
    // const [type, setType] = useState();
    // const [transactionList, setTransactionList] = useState({});
    const [profitLimit, setProfitLimit] = useState({});
    const [value, setValue] = useState(0);

    const bgColors = [
        'gradient-purple',
        'gradient-blue',
        'gradient-orange',
    ];

    const setUrl = (tabValue) => {
        switch (tabValue) {
            case 1:
                history.push(`/bonus-home`);
                break;
            default:
                history.push(`/wallet-home`);
        }
        
    }

    // ----------- APIs ------------
    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('commissions/summary').then(response => {
                if (response.status && isMountedRef.current) {
                    setCommissionSummary(response.data);
                    setProfitLimit(response.data.profit_limit)
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        if(location.pathname == '/bonus-home'){
            setValue(1);
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    // useEffect(() => {
    //     setType(walletBalance[currentpage] ? walletBalance[currentpage].code != 'MP' ? walletBalance[currentpage].code : 'bonues' : 'bonues');
    // }, [currentpage])

    // useEffect(() => {
    //     let params = { 'code': type }
    //     getUrl('transaction/list', params).then(response => {
    //         setTransactionList(response.data);
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     })
    // }, [type])

    // ---------- Function ---------
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setUrl(newValue);
    };

    return (
        <div>
            <TitleBar backgroundColor="bg-theme"  displayUsername currencyButton />
            
            <div className='bg-theme p-b-120 header-radius'>
                <Container className='p-t-20'>
                    <p className='txt-center fs-21 p-b-15 clwhite'><b>{t('wallet.myWallet')}</b></p>
                    
                    <AppBar position="static" className='clwhite' style={{ background: 'transparent', boxShadow: 'none' }}  >
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                            <Tab label={t('title.wallet')} value={0} />
                            <Tab label={t('title.bonus')} value={1} />
                            {/* <Tab label={t('title.bonus') + ' ' + t('general.comingSoon')} disabled /> */}
                        </Tabs>
                    </AppBar>
                </Container>
            </div>

            <Container style={{ marginTop: -100 }}>
                <TabPanel value={value} index={0}>
                    {_.map(walletBalance, (walletItem, key) => {
                        let color_index = key % bgColors.length;
                            return (
                                <Link underline='none' to={`/wallet/${walletItem.code}`} component={RouterLink} key={key}>
                                    <div className={`${bgColors[color_index]} gradient-purple p-lr-25 clwhite m-b-25`} style={{ borderRadius: 21, boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.40) inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.09)' }}>
                                        <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} />
                                    </div>
                                </Link>
                            )
                    })}
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <div className='glass2 p-all-20' style={{ borderRadius: 21, boxShadow: '0 3px 6px 0 #0001' }}>
                        <div className='p-b-15'>
                            <p className='fs-digit cl-black-gradient'><b>{profitLimit.total_profit_limit?currencyFormat(profitLimit.total_profit_limit):0}</b></p>
                            <p className='fs-remark'>{t('commission.maxProfitLimit')}</p>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <div className='w-full' style={{ background: 'rgba(0, 0, 0, 0.06)', borderRadius: 48 }}>
                                <div className='gradient-blue' style={{ width: `${profitLimit.total_profit_limit > 0 && profitLimit.profit_limit_balance > 0 ? Number((profitLimit.profit_limit_balance/profitLimit.total_profit_limit)*100).toFixed(0):0}%`, height: 20, borderRadius: 48, boxShadow:'3px 3px 3px 0 #fff, inset 3px 3px 3px 0 #03030324' }}></div>
                            </div>
                            <p className='cl-theme p-l-10' style={{ width: 'fit-content' }}>{profitLimit.total_profit_limit > 0 && profitLimit.profit_limit_balance > 0 ? Number((profitLimit.profit_limit_balance/profitLimit.total_profit_limit)*100).toFixed(0):0}%</p>
                        </div>
                        <p className='p-t-8 fs-content'>{t('commission.balance',{'balance' : profitLimit.profit_limit_balance?currencyFormat(profitLimit.profit_limit_balance):0})}</p>
                    </div>

                    {_.size(commissionSummary) ?
                        <Grid container spacing={2} style={{ justifyContent: 'space-between', paddingTop: 40 }} >
                            {_.map(commissionSummary.bonus, (bonusItem, key) => {
                                let bonusName = _.split(bonusItem.name, '|');
                                bonusName = i18n.language === "cn" && bonusName[1] ? bonusName[1] : bonusName[0];
                                console.log(commissionSummary);
                                let summary = _.find(commissionSummary.summary, { bonus_id: bonusItem.id });
                                console.log(summary);
                                let amount = "0.00";
                                let color_index = key % bgColors.length;
                                let iconSrc = '';
                                let link = `/commission/${btoa(bonusItem.id)}`;
                                switch(bonusItem.bonus_type){
                                    case  "matching-bonus" :
                                    iconSrc = '/images/bonus/matching.png';
                                    break;
                                    default :
                                    iconSrc = '/images/bonus/roi.png';
                                    break;
                                }

                                if (summary) amount = Number(summary.amount).toFixed(2);

                                return (
                                    <Link to={link} underline='none' component={RouterLink} style={{ width: '45%' }} key={bonusItem.id}>
                                        <div className={`${bgColors[color_index]} clwhite txt-upper p-lr-15 p-b-15`} style={{ borderRadius: 21, boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.40) inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.09)', marginBottom: 20, }}>
                                            <div className='w-full flex-r-m p-b-20'>
                                                <div className='flex-c-m pos-relative' >
                                                    <div className='ribbon-small'></div>
                                                    <div className='pos-absolute p-lr-2 p-t-7' style={{ width: 40, maxWidth: '80%', margin: '0 auto' }}>
                                                        <img src= {iconSrc} alt="commision" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='fs-digit'><b>{amount}</b></p>
                                            <p className='fs-remark'>{bonusName}</p>
                                        </div>
                                    </Link>
                                )
                            })
                            }
                        </Grid>
                        : null
                    }
                </TabPanel>
            </Container>
        </div>
    );
}

const WalletComponent = (props) => {
    const { data, walletList } = props;
    const { t } = useTranslation();

    //wallet and bonus background color

    const balance = currencyFormat((data.balance * 100) / 100);
    return (
        <>
            <div className='pos-relative flex-sb w-full p-t-30' style={{ alignItems: 'flex-start' }}>
                <p className='txt-upper'>{data.wallet_name} {t('title.wallet')}</p>
                <div className='flex-c-m pos-relative' style={{ top: 0, right: 0, width: 60 }}>
                    <div className='ribbon pos-absolute'></div>
                    <div className='pos-absolute' style={{ width: 40, maxWidth: '80%', margin: '0 auto' }}>
                        <img src={`/images/wallet/${(data.code).toLowerCase()}.png`} alt="wallet" />
                    </div>
                </div>
            </div>
            <div className='p-t-30 p-b-25'>
                <p className='fs-digit'><b>{balance}</b></p>
                <p className='txt-upper fs-12'>{t('wallet.totalBalance')}</p>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    bonusCard: {
        position: 'absolute',
        top: 0,
        padding: 25,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('sm')]: {
            top: '-1vw'
        },
        [theme.breakpoints.down('xs')]: {
            top: '-1vw'
        },

    },
    walletButtonStyle: {
        border: '1px solid #696969',
        borderRadius: 48,
        padding: '5px 10px',
        color: theme.palette.silver.text,
        fontSize: 14,
        minWidth: 75,
        textAlign: 'center'
    },
}));
