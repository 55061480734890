import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

// import Autocomplete from '@material-ui/lab/Autocomplete';

import { getUrl, postUrl } from '@helper/ApiAction';
import useNotificationLoading from '../../helper/useNotificationLoading';
import { currencyFormat } from '@helper/Tools';
import { storeWalletBalance } from '@actions';

//MUI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, InputAdornment, TextField, MenuItem } from '@material-ui/core';

//ICON
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";

//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';

export default function Transfer() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", recipient: "", remark: "", security_password: "", dateTime: "", id: "", transferWallet: ""  });
    const [errorField, setErrorField] = useState([]);
    // const [recipientList, setRecipientList] = useState([]);
    const [transferStatus, setTransferStatus] = useState("insert"); // insert -> review -> result
    // const [toWalletId, setToWalletId] = useState(0);
    const [convertWalletList, setConvertWalletList] = useState([]);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet || existWallet.allowTransfer === false) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            getUrl('wallets/rules').then(response => {
                if(response.data && isMountedRef.current) {
                    let selectedWalletRule = [];
                    _.map(response.data.other, (o, i) => {
                        if(i === String(walletInfo.id)) {
                            selectedWalletRule = o;
                        }
                    });
                    let selectedWalletCovertList = [];
                    _.map(selectedWalletRule, walletId => {
                        const selectedWalletInfo = _.find(walletList, { id: walletId });
                        selectedWalletCovertList.push(selectedWalletInfo);
                    })
                    setConvertWalletList(selectedWalletCovertList);
                    if(selectedWalletCovertList.length===1){
                        setState({ ...state, transferWallet: selectedWalletCovertList[0].id });
                    //     setToWalletId(selectedWalletCovertList[0].id);
                    // }else{
                    //     setToWalletId(walletInfo.id);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, walletList, addAlert]);

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     getUrl('bind_account_accross').then(response => {
    //         if(response.data && isMountedRef.current) {
    //             setRecipientList(response.data);
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     })

    //     return () => isMountedRef.current = false;
    // }, [addAlert]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    
    const [recipientname,setRecipientName] = useState();
    const [validUsername,setValidUsername] = useState('');
    const filterrecipiant=()=>{
        setValidUsername(state.recipient);
        getUrl(`recipient/name?recipientName=${state.recipient}`).then(response => {
            if(response.status=='1') {
                setRecipientName(response.data);
            }else{
                setRecipientName('');
            }
        });
    }

    const reviewTransfer = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "recipient"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('transfer.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        })
        if(!error) {
            transferUser(true);
        }else{
            setErrorField(errorField => ({ ...errorField, newErrorField }));
        }
    }

    const transferUser = (validateOnly) => {
        setErrorField([]);
        const { recipient, amount, remark, security_password } = state;
        const transferData = {
            source: 'local',
            login_method: recipient,
            to_wallet_id: state.transferWallet,
            amount,
            remark,
            // password,
            security_password,
            validate_only: validateOnly,
        };

        postUrl(`wallets/${walletInfo.id}/transfer`, transferData).then(response => {
            if(response.status) {
                if(validateOnly){
                    setTransferStatus('review');
                }else{
                    const { created_at, transaction_id, data } = response;
                    setTransferStatus('result');
                    setState({ ...state, id: transaction_id, dateTime: created_at });
                    refetchWallet();
                }
            } else {
                if(response.errors) {
                    let newErrorField = [];
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.data) {
                    addAlert(response.data);
                }
                setTransferStatus('insert')
            }
        }).catch(error => {
            setTransferStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }
    
    const refetchWallet = () => {
        getUrl('wallets/balance').then(response => {
            if(response.status) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    console.log(errorField);

    return (
        <div className='min-h-100vh'>
            <TitleBar backgroundColor="bg-theme" back  currencyButton />
            <div className={`bg-theme ${transferStatus === 'insert' ? 'p-b-30' : 'p-b-300'} header-radius`}>
                {/* <Box className={classes.rankWrapperStyle}>
                        <Typography className={ classes.rankTitleStyle }>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name[0] : '-' }</Typography>
                    </Box> */}
                {transferStatus === 'insert' ?
                    <div className='glass3 p-all-20 flex-c-m bor20' style={{ width: 350, height: 150, maxWidth: '85%', margin: '0 auto' }}>
                        <div className='flex-sb-m w-full'>
                            <div className='clwhite p-r-10' style={{ textShadow: '2px 2px 3px #0003' }}>
                                <p className='fs-remark txt-upper'>{t('transfer.transferableBalance')}</p>
                                <p className='fs-digit'><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                            </div>
                            <div className='clwhite' style={{ textShadow: '2px 2px 3px #0003' }}>
                                <p className='fs-30 txt-upper'><b>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'}</b></p>
                            </div>
                        </div>
                    </div>
                    :
                    <Container>
                        {transferStatus === 'result' &&
                            <div>
                                <p className='txt-center fs-header clwhite p-t-30'>{t('general.successfully')}</p>
                                <div className='subtitle-box'>
                                    <p className='cl-label fs-subheader lh-11 txt-center'>{t('transfer.successfullyTransferSubtitle')}</p>
                                </div>
                            </div>
                        }
                        {transferStatus === 'review' &&
                            <div>
                                <p className='txt-center fs-header clwhite p-t-30'>{t('transfer.review')}</p>
                                <div className='subtitle-box'>
                                    <p className='cl-label fs-subheader lh-11 txt-center'>{t('transfer.reviewSubtitle')}</p>
                                </div>
                            </div>
                        }
                    </Container>
                }
            </div>

            <Container>
            {transferStatus === 'insert' ?
                    <div>
                        <p className='cl-theme fs-header p-t-25'><b>{t('title.walletTransfer', { wallet: walletInfo.wallet_name })}</b></p>
                        <div className='p-b-25'>
                            <TextField
                                label={t('transfer.transferTo')}
                                variant="filled"
                                type="text"
                                name="recipient"
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                style={{ marginBottom: 0 }}
                                error={_.size(errorField.recipient) ? true : false}
                                helperText={errorField.recipient}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                                value={state.recipient}
                                onChange={handleChange}
                                onBlur={filterrecipiant}
                                InputProps={{ 
                                    readOnly: transferStatus === 'review' ? true : false,
                                    endAdornment: <InputAdornment position="end" className='p-r-10 fs-icon'>
                                        {(state.recipient != '' && state.recipient == validUsername) &&
                                            <>
                                                {
                                                    recipientname ?
                                                        <FaCheckCircle className='cl-theme' />
                                                        :
                                                        <MdError className='cl-error' />
                                                }
                                            </>
                                        }
                                      
                                    </InputAdornment>
                                    }}
                            />


                            <div className='p-b-15'>
                                {(state.recipient != '' && state.recipient == validUsername) &&
                                    <div>
                                        {
                                            recipientname?
                                                <p className='fs-remark p-t-3 p-l-15 '>{recipientname}</p>
                                                :
                                                <p className='fs-remark p-t-3 p-l-15 cl-error'>{t('general.invalidUsername')} </p>
                                        }

                                    </div>
                                }
                            </div>

                            <TextField
                                select
                                label={t('transfer.transferWallet')}
                                name="transferWallet"
                                value={state.transferWallet}
                                onChange={handleChange}
                                variant="filled"
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                fullWidth
                                error={_.size(errorField.to_wallet_id) ? true : false}
                                helperText={errorField.to_wallet_id}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            >
                                {convertWalletList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.wallet_name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                type="text"
                                variant="filled"
                                value={state.amount}
                                name="amount"
                                onChange={handleChange}
                                classes={{ root: classes.textFieldRoot }}
                                InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                                label={t('transfer.amount')}
                                fullWidth
                                error={_.size(errorField.amount) ? true : false}
                                helperText={errorField.amount}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            />

                            <TextField
                                type="text"
                                variant="filled"
                                value={state.remark}
                                name="remark"
                                onChange={handleChange}
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                style={{ marginBottom: 0 }}
                                InputProps={{
                                    readOnly: transferStatus === 'review' ? true : false,
                                }}
                                label={t('transfer.remark')}
                                error={_.size(errorField.remark) ? true : false}
                                // helperText={ errorField.remark }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                            />
                            {_.size(state.remark) > 100 && _.size(errorField.remark) ?
                                <p className="p-b-15 txt-right p-t-3 cl-error">{errorField.remark}</p>
                                :
                                <p className={`p-b-15 txt-right p-t-3 ${_.size(state.remark) > 100 ? 'cl-error' : ''}`}>{t('general.maxCharacter')}: <b>{_.size(state.remark)}</b> / 100</p>
                            }


                            <TextField
                                type="password"
                                variant="filled"
                                value={state.security_password}
                                name="security_password"
                                onChange={handleChange}
                                classes={{
                                    root: classes.textFieldRoot,
                                }}
                                InputProps={{
                                    readOnly: transferStatus === 'review' ? true : false,
                                }}
                                label={t('transfer.security_password')}
                                error={_.size(errorField.security_password) ? true : false}
                                helperText={errorField.security_password}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                            />
                        </div>
                        <div className='btn-theme w-full' onClick={reviewTransfer}>
                            <p className='fs-button'>{t('button.next')}</p>
                        </div>
                    </div>
                : 
                    <div className='bg-base bor10 p-tb-20' style={{ marginTop: -270, boxShadow: '0px 4px 6px 0px rgba(112, 143, 223, 0.25)' }}>
                        <div className='txt-center p-t-20 p-b-20 p-lr-20'>
                            <p className='fs-content cl-label'>{t('transfer.transferAmount')}</p>
                            <p className='fs-35 cl-theme'>
                                <b>{`${currencyFormat(state.amount)} ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}</b>
                            </p>
                        </div>
                        <div className='w-full flex-sb-m'>
                            <div className='bg-theme bor50' style={{ width: 30, height: 30, marginLeft: -15 }} />
                            <div className='w-full' style={{ backgroundImage: 'url(/images/general/receipt/dash-theme.png)', backgroundRepeat: 'repeat-x', height: 4, backgroundPosition:'center' }}></div>
                            <div className='bg-theme bor50' style={{ width: 30, height: 30, marginRight: -15 }} />
                        </div>
                        <div className='p-lr-20 p-t-20'>
                            <div>
                                <p className='fs-content'>{t('transfer.transferTo')}</p>
                                <p className='fs-title'>{state.recipient}</p>
                            </div>
                            <div className='fs-content p-tb-10'>
                                <p>{t('transfer.remark')}</p>
                                <p style={{ wordWrap: "break-word" }}>{state.remark}</p>
                            </div>
                            {
                                transferStatus === 'result' &&
                                <>
                                    <div className='fs-content p-tb-10'>
                                        <p>{t('transfer.idNo')}</p>
                                        <p>{state.id}</p>
                                    </div>
                                    <div className='fs-content p-tb-10'>
                                        <p>{t('transfer.dateTime')}</p>
                                        <p>{state.dateTime}</p>
                                    </div>
                                </>
                            }

                            <div className='p-t-25'>
                                {
                                    transferStatus === 'review' &&
                                    <div className='w-full flex-sb-m'>
                                        <div className='btn-outlined-theme' onClick={() => setTransferStatus('insert')} style={{ width: '47%' }}>
                                            <p className='fs-button'>{t('button.edit')}</p>
                                        </div>
                                        <div className='btn-theme' onClick={() => transferUser(false)} style={{ width: '47%' }}>
                                            <p className='fs-button'>{t('transfer.transferNow')}</p>
                                        </div>
                                    </div>
                                }
                                {
                                transferStatus === 'result' &&
                                <div className='btn-theme w-full' onClick={() => history.push(`/wallet/${wallet}`)}>
                                    <p className='fs-button'>{t('transfer.complete')}</p>
                                </div>
                            }
                            </div>

                            
                        </div>
                    </div>
                }
            </Container>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "#fff",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: '#fff', 
        fontSize: 11, 
        letterSpacing: 1 
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}