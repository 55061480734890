import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { Grid, Link, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { MdOutlineShare } from "react-icons/md";

import useNotificationLoading from '@helper/useNotificationLoading';
import WebpImg from '@layouts/WebpImg';

import '@css/styles.css';

export default function CollectionItemCard(props) {
    const { product, currency, currencyCode } = props;

    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { accessToken, currencyRate } = useSelector(state => state.general);
    const { id:user_id } = useSelector(state => state.user);

    const [shareLink, setShareLink] = useState(window.location.href.split(window.location.pathname)[0]);
    let bvDisplay = product.bv;
    let priceDisplay = product.sell_price;

    let showRetail = false;
    let discountPercent = 0;

    if (parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(priceDisplay)) {
        showRetail = true;
        discountPercent = ((parseFloat(product.retail_price) - parseFloat(priceDisplay)) / parseFloat(product.retail_price)) * 100;
        discountPercent = Math.round(discountPercent);
    }

    // ------------ APIs ---------------
    useEffect(() => {
        if(user_id){
            setShareLink(`${window.location.href.split(window.location.pathname !== '/' ? window.location.pathname : '?')[0]}${window.location.pathname !== '/' ? '/' : ''}product/${product?.id}?r=${btoa(user_id)}`);
        }
        // eslint-disable-next-line
    }, [user_id, product?.id]);

    return (
        <div className='product-hover p-tb-20 p-r-20 flex-c-sb m-t-20 pointer w-full bor20 bg-theme pos-relative'>
         {/* style={{ background: product?.display_code?.default ? theme.palette.primary.main : product?.display_code?.background_color }} */}
            <Tooltip title={product[`title_${i18n.language}`]}>
                <Grid container>
                    <Grid item xs={5} className='p-lr-10 flex-c-m'>
                        <div className='bg-base bor15 p-all-5 flex-c-m' style={{marginTop:-50, width: 100, aspectRatio:'1/1', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 4px 0px' }}>
                            <WebpImg alt={product[`title_${i18n.language}`]} src={_.size(product.images_array) > 0 ? product.images_array[0] : '/images/empty/no-image-theme.png'} className='bor10' style={{width:90, aspectRatio:'1/1', objectFit: 'cover',}} />
                            {/* <img src="/images/example/merchant/4.png" alt="product" className='bor10' style={{width:90, aspectRatio:'1/1', objectFit: 'cover',}}/> */}
                        </div>
                    </Grid>

                    <Grid item xs={7} className='flex-col-sb' style={{ width: '-webkit-fill-available' }}>
                        <div className='w-full flex-sb-m'>
                            <p className='fs-title clwhite lh-11 txt_truncate p-r-15'><b>{product[`title_${i18n.language}`]}</b></p>
                            {/* <Typography variant="caption" style={{ color: '#f0f0f0', lineHeight: 1.1 }}>anti aging, anti oxidant</Typography> */}
                            {
                                accessToken &&
                                <div className='pointer' onClick={() => { navigator.clipboard.writeText(shareLink); addAlert(t('profile.copySuccess'), "success"); }}>
                                    <MdOutlineShare className='fs-icon-small clwhite'/>
                                </div>
                            }
                        </div>

                        <div className='flex-sb flex-b p-t-10' style={{ height: 41 }}>
                            {
                                discountPercent > 0 &&
                                <>
                                    {
                                        showRetail ?
                                            <div className='ribbon-side-left pos-absolute flex-c-m' style={{ left: 0, bottom: 18 }}>
                                                <p className='fs-remark lh-0'>
                                                    <b>{`${discountPercent}% ${t('general.discount')}`}</b>
                                                </p>
                                            </div>
                                            : null
                                    }
                                </>
                            }

                            {/* {
                                accessToken &&
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, zIndex: 5 }} onClick={() => { navigator.clipboard.writeText(shareLink); addAlert(t('profile.copySuccess'), "success"); }}>
                                    <MdOutlineShare style={{ fontSize: 20, color: '#fff' }} />
                                </IconButton>
                            } */}

                            <div>
                                {
                                    showRetail &&
                                    <p className='fs-remark lh-10 clwhite p-b-2' style={{ fontWeight: 'bold', textDecoration: 'line-through', opacity:0.5 }}>
                                        <NumberFormat value={(product.retail_price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyCode[1] ? currencyCode[1] : currencyCode[0]) : currencyCode[0]} `} />
                                    </p>
                                }
                                <p className="fs-content p-r-3 lh-10 clwhite">
                                    <b><NumberFormat value={(priceDisplay * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currencyCode[1] ? currencyCode[1] : currencyCode[0]) : currencyCode[0]} `} /></b>
                                </p>
                            </div>

                            <Link underline='none' to={`/product/${product.id}`} component={RouterLink}>
                                <div className='bor50 bg-base p-tb-3 p-lr-15'>
                                    <p className='fs-content cl-theme'>{t('button.buy')}</p>
                                </div>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Tooltip>
        </div>
    )
}